import request from '@/utils/requestHelper';
import { genWebApiToken } from '@/utils/tokenGenerator';

export default {
  methods: {
    getAppId(newsMainBannerId) {
      let appId = '';
      switch (parseInt(newsMainBannerId, 10)) {
        case 1:
          appId = 'news-game';
          break;
        case 2:
          appId = 'news-relax';
          break;
        case 3:
          appId = 'news-times';
          break;
        default:
          appId = '';
          break;
      }
      return appId;
    },
    saveSort({ sort }) {
      this.$store.dispatch('SAVE_SORT', {
        sort,
      });
    },
    deleteCommentList() {
      this.$store.dispatch('DELETE_COMMENT_LIST');
    },
    deleteCommentInList({ commentId }) {
      this.$store.dispatch('DELETE_COMMENT_IN_LIST', {
        commentId,
      });
    },
    addReplyToComment({ commentId }) {
      this.$store.dispatch('ADD_REPLY_TO_COMMENT', {
        commentId,
      });
    },
    deleteReplyInComment({ commentId, replyId }) {
      this.$store.dispatch('DELETE_REPLY_IN_COMMENT', {
        commentId,
        replyId,
      });
    },
    changeCommentMyReaction({ commentId, type, isCancel }) {
      this.$store.dispatch('CHANGE_COMMENT_MY_REACTION', {
        commentId,
        type,
        isCancel,
      });
    },
    requestGetCommentCount({
      id, entityType, entityId, onSuccess, onFailed,
    }) {
      this.$store.dispatch('GET_COMMENT_COUNT', {
        id,
        entityType,
        entityId,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestGetLikeCount({
      entityType,
      aliasId,
      entityIds,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('GET_LIKE_COUNT', {
        entityType,
        aliasId,
        entityIds,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestGetCommentsByPopular({
      id,
      entityId,
      entityType,
      aliasId,
      show,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('GET_COMMENTS_BY_POPULAR', {
        id,
        entityId,
        entityType,
        aliasId,
        show,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestGetCommentsByNewest({
      id,
      entityType,
      entityId,
      aliasId,
      prev,
      sort,
      show,
      next,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('GET_COMMENTS_BY_NEWEST', {
        id,
        entityType,
        entityId,
        aliasId,
        prev,
        sort,
        show,
        next,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestGetCommentsByIds({
      newsMainBannerId = 0,
      commentIds,
      aliasId,
      entityId,
      onSuccess,
      onFailed,
    }) {
      const appId = this.getAppId(newsMainBannerId);
      this.$store.dispatch('GET_COMMENTS_BY_IDS', {
        appId,
        commentIds,
        aliasId,
        entityId,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestGetComment({
      newsMainBannerId = 0,
      commentIds,
      aliasId,
      entityId,
      onSuccess,
      onFailed,
    }) {
      const appId = this.getAppId(newsMainBannerId);
      this.$store.dispatch('GET_COMMENT', {
        appId,
        commentIds,
        aliasId,
        entityId,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestCreateComment({
      aliasId,
      entityId,
      content,
      userMentions,
      onSuccess,
      onFailed,
    }) {
      const appId = 'vote';
      this.$store.dispatch('CREATE_COMMENT', {
        appId,
        aliasId,
        entityId,
        content,
        userMentions,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestDeleteComment({
      aliasId,
      entityId,
      commentId,
      onSuccess,
      onFailed,
    }) {
      const appId = 'vote';
      this.$store.dispatch('DELETE_COMMENT', {
        appId,
        aliasId,
        entityId,
        commentId,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestGetReplies({
      id,
      entityType,
      commentId,
      aliasId,
      prev,
      next,
      show,
      sort,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('GET_REPLIES', {
        id,
        entityType,
        commentId,
        aliasId,
        prev,
        next,
        show,
        sort,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestCreateRelpy({
      aliasId,
      commentId,
      content,
      userMentions,
      onSuccess,
      onFailed,
    }) {
      const appId = 'vote';
      this.$store.dispatch('CREATE_REPLY', {
        appId,
        aliasId,
        commentId,
        content,
        userMentions,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestDeleteRelpy({
      aliasId,
      replyId,
      onSuccess,
      onFailed,
    }) {
      const appId = 'vote';
      this.$store.dispatch('DELETE_REPLY', {
        appId,
        aliasId,
        replyId,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestGetReactions({
      id,
      lotterId,
      lotterType,
      aliasId,
      prev,
      next,
      sort,
      show,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('GET_REACTIONS', {
        id,
        lotterId,
        lotterType,
        aliasId,
        prev,
        next,
        sort,
        show,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestCreateOrUpdateOrDeleteReaction({
      newsMainBannerId,
      aliasId,
      lotterId,
      lotterType,
      type,
      onSuccess,
      onFailed,
    }) {
      const appId = this.getAppId(newsMainBannerId);
      this.$store.dispatch('CREATE_OR_UPDATE_OR_DELETE_REACTION', {
        appId,
        aliasId,
        lotterId,
        lotterType,
        type,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestCreateReportDetail({
      newsMainBannerId,
      reporterId,
      lotterId,
      lotterType,
      type,
      reason,
      onSuccess,
      onFailed,
    }) {
      const appId = this.getAppId(newsMainBannerId);
      this.$store.dispatch('CREATE_REPORT_DETAIL', {
        appId,
        reporterId,
        lotterId,
        lotterType,
        type,
        reason,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestVoteClickReaction({
      entityId, body, onSuccess, onFailed,
    }) {
      this.$store.dispatch('CLICK_VOTE_REACTION', {
        entityId,
        body,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestClickCommentReaction({
      id, body, onSuccess, onFailed,
    }) {
      this.$store.dispatch('CLICK_COMMENT_REACTION', {
        id,
        body,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    comparisonVersion(currentDeviceVersion, updateVersion) {
      const stgVersion = '1.0.0'; // 只有 ios stg 會定版在 1.0.0

      if (stgVersion === currentDeviceVersion) {
        return false;
      }

      const currentDeviceVersionSplit = currentDeviceVersion.split('.');
      const updateVersionSplit = updateVersion.split('.');

      for (let index = 0; index < 3; index += 1) {
        const nCurrentDeviceVersion = Number(currentDeviceVersionSplit[index]);
        const nUpdateVersion = Number(updateVersionSplit[index]);

        if (nCurrentDeviceVersion < nUpdateVersion) {
          return true;
        }
        if (nCurrentDeviceVersion > nUpdateVersion) {
          return false;
        }
      }
      return false;
    },
    textReplaceToATag(content) {
      if (/(www.|tw.|(http(s?)):\/\/)/i.test(content)) {
        const regex = /(www.|tw.|(http(s?)):\/\/)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]?/gi;
        const replaceUrlToATag = content.replace(
          regex,
          urlString => `<a href="${
            urlString.match(/^(www.|tw.)/)
              ? `https://${urlString}`
              : urlString
          }" id="comment-context-link">${urlString}</a>`,
        );
        return replaceUrlToATag;
      }
      return content;
    },
    async requestOgData(urlString) {
      const oaId = process.env.VUE_APP_OA_ID;
      const oaToken = process.env.VUE_APP_OA_TOKEN;
      const token = genWebApiToken(null, oaId, oaToken);
      const headers = {
        Authorization: `bearer ${token}`,
      };
      const resp = await request({
        headers,
        uri: `${process.env.VUE_APP_WEB_API_HOST}/v1/previewInfo?url=${urlString}`,
        method: 'get',
      });
      return resp.data;
    },
  },
};

<template lang="html">
  <img class="originl_loader" src="@/assets/images/image_loading.gif" alt="">
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="sass" scoped>
.originl_loader
  margin: 0 auto
  z-index: 100
  width: 64px
  height: 64px
  //transform: rotate(360deg)
  //transition: 1s
</style>

import request from '@/utils/requestHelper';

// 內部轉傳
// 參考文件: https://docs.google.com/document/d/1yvoGz-210rtcikTrl_UowDPEElqNV-Bmc0C4Cd80SGg/edit#
export const generateInsideSharingParams = ({
  pageTitle,
  title,
  imageUri,
  uri,
}) => {
  const universalLinksDomain = process.env.VUE_APP_UNIVERSAL_LINKS_DOMAIN;
  const officialAccountId = process.env.VUE_APP_OFFICAIL_ACCOUNT_ID;
  const encodedLink = encodeURIComponent(uri);
  const encodedTitle = encodeURIComponent(pageTitle);
  const universalLink = `${universalLinksDomain}/h5page/${officialAccountId}`
                      + `?url=${encodedLink}&theme=1&title=${encodedTitle}`;
  const messageBody = {
    messages: [
      {
        promote_notification: '推播訊息',
        raw_message: {
          mime_type: 81,
          content: title,
          metadata: {
            application_x_beanfun_interactive_metadata: {
              service_id: process.env.VUE_APP_NEWS_WIDGET_ID,
              image_info: {
                mime_type: 10,
                uri: imageUri,
                thumbnail_uri: imageUri,
              },
              once: true,
              forwardable: false,
              expired_hint: '投票已刪除',
              options: [
                {
                  opt_key: 'app_link_option_key',
                  opt_type: 2,
                  description: '點我去投票',
                  uri: universalLink,
                },
              ],
              interactive_key: 'vote_messageKey',
            },
          },
          expired_time: -1,
        },
      },
    ],
  };

  const selectOption = {
    title: '好友群',
    target: {
      friend: 1,
      group: 1,
      fun_chat: 1,
      game: 1,
    },
    max_selected_count: -1,
  };

  return {
    messageBody,
    selectOption,
  };
};

// 外部分享
export const generateOutsideSharingParamsPromise = async ({
  pageTitle,
  title,
  description,
  imageUri,
  uri,
}) => {
  const officialAccountId = process.env.VUE_APP_OFFICAIL_ACCOUNT_ID;
  const api = `${process.env.VUE_APP_SHORT_LINK_API}/shortlink`;
  const encodedLink = encodeURIComponent(uri);
  const encodedTitle = encodeURIComponent(pageTitle);
  const universalLink = `${process.env.VUE_APP_UNIVERSAL_LINKS_DOMAIN}/h5page/${officialAccountId}?url=${encodedLink}&theme=1&title=${encodedTitle}`;
  const body = {
    seo: {
      title,
      description,
      image: imageUri,
    },
    defaultLink: universalLink,
  };
  const res = await request({
    uri: api,
    method: 'post',
    data: JSON.stringify(body),
  });
  return res.data;
};

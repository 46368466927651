<template lang="html">

  <div class="VotedButton">
    已投票
  </div>

</template>

<script>
export default {
  name: 'VotedButton',
};
</script>

<style lang="sass" scoped>
.VotedButton
  width: 70px
  height: 32px
  border-radius: 12px
  border: #26d07c 1px solid
  color: #26d07c
  line-height: 32px
  text-align: center
  margin: 5px
</style>

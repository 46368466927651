export const timeSince = (timestamp) => {
  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const MONTH = 30 * DAY;
  const YEAR = 365 * DAY;
  const units = [
    { max: MINUTE, divisor: SECOND, text: ' 秒' },
    { max: HOUR, divisor: MINUTE, text: ' 分鐘' },
    { max: DAY, divisor: HOUR, text: ' 小時' },
    { max: MONTH, divisor: DAY, text: ' 天' },
    { max: YEAR, divisor: MONTH, text: ' 個月' },
    { max: Infinity, divisor: YEAR, text: ' 年' },
  ];
  let diff = Date.now() - timestamp;
  if (diff < 0) diff = 0;
  let text = '';
  units.forEach((unit) => {
    if (diff < unit.max && text === '') {
      const x = Math.round(diff / unit.divisor);
      text = x + unit.text;
    }
  });
  return text;
};

export default timeSince;

<template lang="html">

  <div class="VoteResultButton">
    看結果
  </div>

</template>

<script>
export default {
  name: 'VoteResultButton',
};
</script>

<style lang="sass" scoped>
.VoteResultButton
  width: 70px
  height: 32px
  border-radius: 12px
  border: #CCCCCC 1px solid
  color: #666666
  line-height: 32px
  text-align: center
  margin: 5px
</style>

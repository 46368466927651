<template lang="html">
  <label class="switch">
    <input type="checkbox" v-model="isOn" @change="switched($event)">
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  name: 'SwitchButton',
  props: ['isOpen'],
  watch: {
    isOpen: function(newVal, oldVal) {
      this.isOn =  this.isOpen;
    }
  },
  data() {
    return {
      isOn: this.isOpen,
    }
  },
  methods: {
    switched() {
      this.$emit('switched', this.isOn);
    },
  },
};
</script>

<style lang="sass" scoped>
// The switch - the box around the slider
.switch
  position: relative
  display: inline-block
  width: 52px
  height: 32px
  input //Hide default HTML checkbox
    opacity: 0
    width: 0
    height: 0
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) // 解決 switchBtn 在 chrome 小螢幕閃爍情況的關鍵
  -webkit-user-select: none
  user-select: none
  &::selection
    background: none
    color: none

// The slider
// 底橢圓 未亮起狀態
.slider
  position: absolute
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgb(255, 255, 255)
  border: solid 2px rgb(221, 221, 221)
  -webkit-transition: .4s
  transition: .4s
  &:before
    position: absolute
    content: ""
    height: 26px
    width: 26px
    background-color: white
    border: solid 1px rgb(229, 229, 234)
    -webkit-transition: .4s
    transition: .4s
    box-shadow: 0px 1px rgba(0, 0, 0, 0.15)
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) // 解決 switchBtn 在 chrome 小螢幕閃爍情況的關鍵
  -webkit-user-select: none
  user-select: none
  &::selection
    background: none
    color: none

// 滑動的圓
// 亮起狀態
input:checked + .slider
  background-color: rgb(38, 208, 124)
  border: solid 2px rgb(38, 208, 124)
  &:before
    -webkit-transform: translateX(20px)
    -ms-transform: translateX(20px)
    transform: translateX(20px)

//input:focus + .slider
//  box-shadow: 0 0 1px rgb(38,208,124)

// Rounded sliders
.slider.round
  -moz-border-radius: $border-radius
  -webkit-border-radius: $border-radius
  border-radius: $border-radius
  &:before
    border-radius: 50%
</style>

class VoteQuestion {
  constructor({
    questionID,
    optionIDCheckList,
  }) {
    this.questionID = questionID;
    this.optionIDCheckList = optionIDCheckList;
  }

  serialize() {
    return {
      questionID: this.questionID,
      optionIDCheckList: this.optionIDCheckList,
    };
  }
}

export default VoteQuestion;

<!-- 留言功能列 -->
<template lang="html">
  <div class="flex align-items toolbar">
    <div 
      class="flex comment-icon-block"
      @touchstart="(e) => touchStartLogic(e)"
      @touchend="touchEndLogic(isClickedReaction ? 0 : 1)"
      @touchmove="(e) => touchMoveLogic(e)"
    >
      <!-- #region 按讚 -->
      <div>
        <img class="comment-img" :src="require(`@/assets/images/${imagePath}.png`)">
      </div>
      <div class="count">{{ reactionCount }}</div>
    </div>
    <!-- #region 往留言列表頁 -->
    <div @click="goCommentList" class="beanfun flex align-items justify_content_center comment-icon-block" >
      <div>
        <img class="comment-img" src="@/assets/images/ic_message_point_m_bluedark.png">
      </div>
      <div class="count">{{ count }}</div>
    </div>
    <!-- #region 分享 -->
    <div class="share comment-icon-block" @click="sendDataToApps">
      <img class="comment-img" src="@/assets/images/ic_share_m_bluedark.png">
    </div>
  </div>

</template>

<script>
import { comment } from '@/mixins';
import { passEvent } from '@/utils/trackingHelper';

export default {
  name: 'CommentToolbar',
  props: ['id', 'newsMainBannerId', 'subjectID', 'count', 'reactionCount',
  'aliasId', 'isClickedReaction', 'longPressEmojiBlockName', 'pageInfoData', 'saveMyReactionType'],
  mixins: [comment],
  data() {
    return {
      timing: 0,
      intervalIds: [],
      elem: '',
      emojiBlock: '',

      // 表情符號框彈出後，滑動到表情符號要放大縮小的參數
      saveClientX: 0,
      saveClientY: 0,
      prevTouchEmojiNumber: '', // NOTE: 會判斷是否為空值
      inEmojiTouching: false,
      isSentSdk: false, // 開啟或關閉阻擋一頁的手勢
    }
  },
  mounted() {
    this.emojiBlock = document.getElementById(this.longPressEmojiBlockName)
    document.addEventListener('click', () => this.closeLongPress('isClick') ,true);
    document.addEventListener('scroll', () => this.closeLongPress('isScroll') ,true);
    document.addEventListener('touchmove', (e) => {
      this.touchMoveLogic(e)
    });
    document.addEventListener('touchend', async () => {
      this.intervalIds.forEach((item,index)=>{
        clearInterval(item)
      })
      this.intervalIds = []
      this.timing = 0

      if(this.inEmojiTouching && this.prevTouchEmojiNumber !== '') {
        const compose = [
          { id: 1, imageName: 'imgImEmojiLike', text: '喜歡' },
          { id: 3, imageName: 'imgImEmojiHeart', text: '愛心' },
          { id: 4, imageName: 'imgImEmojiLaugh', text: '大笑' },
          { id: 5, imageName: 'imgImEmojiAngry', text: '生氣' },
          { id: 6, imageName: 'imgImEmojiWow', text: '驚訝' },
        ];
        this.$emit('showDialogEmoji', {
          isDisplay: true,
          image: compose[this.prevTouchEmojiNumber].imageName,
          text: compose[this.prevTouchEmojiNumber].text,
        })
        this.clickReaction(compose[this.prevTouchEmojiNumber].id)
        this.$emit('updateDisplayMyReaction', compose[this.prevTouchEmojiNumber].id);
        if(this.prevTouchEmojiNumber !== '') {
          this.emojiBlock.childNodes[0].childNodes[this.prevTouchEmojiNumber]
            .classList.add('a-emoji-zoom-out')
        }
        this.closeLongPress()
        this.inEmojiTouching = false
        this.prevTouchEmojiNumber = ''
        if(this.isSentSdk) {
          // 關閉阻擋上一頁的手勢
          BGO.set_back_gesture(true)
          this.isSentSdk = false
        }
      }
    })
  },
  computed: {
    imagePath() {
      const imageNameList = [
        'ic_like_stroked_m_bluedark',
        'emoji/imgImEmojiLike',
        'emoji/imgImEmojiHeart',
        'emoji/imgImEmojiLaugh',
        'emoji/imgImEmojiAngry',
        'emoji/imgImEmojiWow'
      ];
      const numberList = [0, 1, 3, 4, 5, 6];
      return imageNameList[numberList.indexOf(this.saveMyReactionType)];
    },
    cat0() {
      let value = '';
      const search = new URLSearchParams(window.location.search);
      switch (parseInt(search.get('newsMainBannerId'), 10)) {
        case 1:
          value = '電玩星';
          break;
        case 2:
          value = '放鬆星';
          break;
        case 3:
          value = '時事星';
          break;
      }
      return value;
    },
  },
  methods: {
    closeLongPress(form) {
      const emo = this.emojiBlock
      if(form === 'isClick' || form === 'isScroll') {
        this.inEmojiTouching = false
      }
      if(emo && emo.style.display !== 'none') {
        emo.classList.add('fade-out')
        setTimeout(() => {
          emo.classList.remove('fade-out')
          emo.style.display = 'none'
          emo.style.zIndex = 3
        }, 100)
      }
      if(this.isSentSdk) {
        // 關閉阻擋上一頁的手勢
        BGO.set_back_gesture(true)
        this.isSentSdk = false
      }

      // 移除放大
      if(this.prevTouchEmojiNumber !== '') {
        const prevEmojiText =
            document.querySelector(`.${this.id}_a-emoji-id_${this.prevTouchEmojiNumber+1}`);
        prevEmojiText.classList.remove('showTextUp'); // 移除放大顯示的文字 (偽元素)
        this.emojiBlock.childNodes[0].childNodes[this.prevTouchEmojiNumber]
          .classList.remove('a-emoji-zoom-out')
        this.emojiBlock.childNodes[0].childNodes[this.prevTouchEmojiNumber]
          .classList.remove('a-emoji-zoom-in-up')
      }

    },
    touchStartLogic(e) {
      e.preventDefault()
      if(!this.inEmojiTouching) {
        this.elem = e
        const pageX = this.elem.targetTouches[0].pageX
        const pageY = this.elem.targetTouches[0].pageY
        let id = 0
        const emo = this.emojiBlock
        emo.style.display = ''
        id = setInterval(() => {
          this.timing += 100
          if(this.timing > 500) {
            if(!this.isSentSdk) {
              // 開啟阻擋上一頁的手勢
              BGO.set_back_gesture(false)
              this.isSentSdk = true
            }
            emo.style.left = `${pageX - 70}px`
            emo.style.top = `${pageY - 80}px`
            emo.style.display = 'block'
            emo.style.zIndex = 3
            this.inEmojiTouching = true
          }
        }, 50);
        this.intervalIds.push(id)
      }
    },
    touchEndLogic(reactionType) {
      if(this.timing > 100) {
        setTimeout(() => {
          this.intervalIds.forEach((item,index)=>{
            clearInterval(item)
          })
          this.intervalIds = []
          this.timing = 0
          this.inEmojiTouching = false
        }, 100)
      }
      if(this.inEmojiTouching && this.timing <= 100) {
        this.inEmojiTouching = false
        this.closeLongPress()
      } else if (this.timing <= 100 && !this.inEmojiTouching) {
        this.clickReaction(reactionType)
        if(reactionType > 0) {
          this.$emit('showDialogEmoji', {
            isDisplay: true,
            image: 'imgImEmojiLike',
            text: '喜歡',
          })
        }
      }
    },
    touchMoveLogic(e) {
      const getOffsetLeft = this.emojiBlock.offsetLeft // 表情符號框離螢幕左邊的距離
      const leftLimit = getOffsetLeft + 12 // 12 為表情符號框的邊框半圓寬度
      const rightLimit = getOffsetLeft + 264 - 12 // 264 表情符號框的寬度, 12 為表情符號框的邊框半圓寬度
      const topLimit = this.emojiBlock.offsetTop
      const bottomLimit = this.emojiBlock.offsetTop + 48 // 48 為表情符號框的高度
      const touchX = e.touches[0].clientX
      const touchY = e.touches[0].pageY
      const isXLimited = touchX > leftLimit && touchX < rightLimit
      const isYLimited = touchY > topLimit  && touchY < bottomLimit
      if(isXLimited && isYLimited) {
        this.inEmojiTouching = true
        this.$emit('inEmojiTouching', true)
        const currentTouchEmojiNumber = Math.trunc((touchX-getOffsetLeft-12)/48)
        // 12 為表情符號框的邊框半圓寬度, 48 為每個表情符號框的寬度
        if(this.prevTouchEmojiNumber === '') {
          const currentEmojiText =
            document.querySelector(`.${this.id}_a-emoji-id_${currentTouchEmojiNumber+1}`);
          currentEmojiText.classList.add('showTextUp'); // 放大顯示的文字 (偽元素)
          this.emojiBlock.childNodes[0].childNodes[currentTouchEmojiNumber]
            .classList.add('a-emoji-zoom-in-up')
          this.prevTouchEmojiNumber = currentTouchEmojiNumber
          return
        }
        if(currentTouchEmojiNumber !== this.prevTouchEmojiNumber) {
          // 前一個放大的表情符號
          const prevEmojiText =
            document.querySelector(`.${this.id}_a-emoji-id_${this.prevTouchEmojiNumber+1}`);
          prevEmojiText.classList.remove('showTextUp'); // 移除放大顯示的文字 (偽元素)
          this.emojiBlock.childNodes[0].childNodes[this.prevTouchEmojiNumber]
            .classList.remove('a-emoji-zoom-in-up')
          this.emojiBlock.childNodes[0].childNodes[this.prevTouchEmojiNumber]
            .classList.add('a-emoji-zoom-out')

          // 當前要放大的表情符號
          this.emojiBlock.childNodes[0].childNodes[currentTouchEmojiNumber]
            .classList.add('a-emoji-zoom-in-up')
          this.emojiBlock.childNodes[0].childNodes[currentTouchEmojiNumber]
            .classList.remove('a-emoji-zoom-out')
          const currentEmojiText =
            document.querySelector(`.${this.id}_a-emoji-id_${currentTouchEmojiNumber+1}`);
          currentEmojiText.classList.add('showTextUp'); // 放大顯示的文字 (偽元素)
          this.prevTouchEmojiNumber = currentTouchEmojiNumber
        }
        return
      }

      if(!(isXLimited && isYLimited) && this.prevTouchEmojiNumber !== '') {
        this.inEmojiTouching = false
        this.$emit('inEmojiTouching', false)
        const prevEmojiText =
          document.querySelector(`.${this.id}_a-emoji-id_${this.prevTouchEmojiNumber+1}`);
        prevEmojiText.classList.remove('showTextUp'); // 移除放大顯示的文字 (偽元素)
        this.emojiBlock.childNodes[0].childNodes[this.prevTouchEmojiNumber]
          .classList.remove('a-emoji-zoom-out')
        this.emojiBlock.childNodes[0].childNodes[this.prevTouchEmojiNumber]
          .classList.remove('a-emoji-zoom-in-up')
        this.prevTouchEmojiNumber = ''
      }
    },
    goCommentList() {
    this.pageInfoData['cat0'] = this.cat0
    const eventItems = [
      {
        type: 'clickEvent',
        eventId: '1046',
        event: 'poll_item_click',
        pageInfo: this.pageInfoData,
        clickInfo: {
          type: 'function_bar',
          name: 'comment'
        }
        },
      ];
      passEvent(eventItems);
      const subjectid = this.subjectID;
      const newsMainBannerId = this.newsMainBannerId;
      location.href = `commentList?subjectid=${subjectid}&newsMainBannerId=${newsMainBannerId}`;
    },
    sendMessage() {
      this.$emit('sendMessage');
    },
    sendDataToApps() {
      const eventItems = [
      {
        type: 'clickEvent',
        eventId: '1046',
        event: 'poll_item_click',
        pageInfo: this.pageInfoData,
        clickInfo: {
          type: 'function_bar',
          name: 'share'
        }
        },
      ];
      passEvent(eventItems);
      this.$emit('sendDataToApps');
    },
    clickReaction(reactionType) {
      this.$emit('clickReaction', reactionType);

      const eventItems = [
      {
        type: 'clickEvent',
        eventId: '1046',
        event: 'poll_item_click',
        pageInfo: this.pageInfoData,
        clickInfo: {
          type: 'function_bar',
          name: 'emoji'
        }
        },
      ];
      passEvent(eventItems);
    },
  }
};
</script>

<style lang="sass" scoped>
.toolbar
  height: 68px
  width: 100%
  background: #FAFAFA
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1)
  justify-content: center

.count
  position: absolute
  bottom: 16px
  left: 25px
  text-align: left
  line-height: 30px
  margin: 12px 0px 12px 5.5px
  font-size: 15px
  color: rgb(51, 51, 51)
  display: inline-flex
  border: solid 1px rgba(0, 0, 0, 0.06)
  background-color: #fff
  border-radius: 18px
  height: 13px
  padding: 3px
  min-width: 13px
  align-items: center
  justify-content: center

.beanfun
  width: 30px
  height: 30px
  margin-right: 16px
  margin: 12px 16px 12px 0px
  border-radius: 50%

.share
  width: 30px
  height: 30px
  margin: 12px 22px 12px 0px

.comment-icon-block
  position: relative
  display: flex
  flex-direction: row
  justify-content: center
  width: 44px
  height: 44px
  border-radius: 44px
  border: solid 1px #f4f4f4
  background-color: #f3f6f8
  align-items: center
  margin-right: 16px
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  -webkit-touch-callout: none
  -webkit-user-select: none
  -webkit-user-drag: none

.comment-img
  width: 28px
  height: 28px
</style>

class CreateVote {
  constructor({
    subjectID,
    title,
    imageUri,
    description,
    type,
    isActive,
    isPinned,
    startTime,
    endTime,
    chatID,
    version,
    createdUser,
    questionList,
  }) {
    this.subjectID = subjectID;
    this.title = title;
    this.imageUri = imageUri;
    this.description = description;
    this.type = type;
    this.isActive = isActive;
    this.isPinned = isPinned;
    this.startTime = startTime;
    this.endTime = endTime;
    this.chatID = chatID;
    this.version = version;
    this.createdUser = createdUser;
    this.questionList = questionList;
  }

  serialize() {
    return {
      subjectID: this.subjectID,
      title: this.title,
      imageUri: this.imageUri,
      description: this.description,
      type: this.type,
      isActive: this.isActive,
      isPinned: this.isPinned,
      startTime: this.startTime,
      endTime: this.endTime,
      chatID: this.chatID,
      version: this.version,
      createdUser: this.createdUser,
      questionList: this.questionList,
    };
  }
}

export default CreateVote;

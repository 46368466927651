<template lang="html">
  <!-- 投票結果-其他 -->
  <div class="answerRow">
    <div class="txtCheckboxArea" v-if="questionType == 1">
      <!-- 綠色勾 -->
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="option.isVoted">
        <circle cx="15" cy="15" r="11" fill="white"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="22">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15 26C21.0751 26 26 21.0751 26 15C26 8.92487 21.0751 4 15 4C8.92487 4 4 8.92487 4 15C4 21.0751 8.92487 26 15 26ZM19.7828 13.1314C20.0171 12.8971 20.0171 12.5172 19.7828 12.2829L19.2171 11.7172C18.9828 11.4829 18.6029 11.4829 18.3686 11.7172L14 16.0858L11.6313 13.7172C11.397 13.4829 11.0171 13.4829 10.7828 13.7172L10.2171 14.2829C9.9828 14.5172 9.9828 14.8971 10.2171 15.1314L13.1514 18.0657C13.6201 18.5343 14.3799 18.5343 14.8485 18.0657L19.7828 13.1314Z" fill="#26D07C"/>
        </mask>
        <g mask="url(#mask0)">
          <rect width="30" height="30" fill="#26D07C"/>
        </g>
      </svg>
    </div>
    <div class="picCheckboxArea" v-else :style="{ 'background-image': 'url(\'' + option.imageUri + '\')' }">
      <!-- 皇冠圖 -->
      <svg v-show="maxVotingRate != 0 && option.votingRate == maxVotingRate" class="crownImg" width="30" height="30" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.9675 37.199L37.199 61.9675C35.0596 64.1069 31.737 64.4585 29.2861 62.8048L7.15177 47.8708C3.74595 45.5728 5.56435 40.1779 9.71513 40.2656L25.2382 40.5938L19.4188 24.8338C18.1893 21.5042 21.5042 18.1893 24.8338 19.4188L40.5938 25.2382L40.2656 9.71511C40.1779 5.56433 45.5728 3.74594 47.8708 7.15177L62.8048 29.286C64.4585 31.737 64.1069 35.0596 61.9675 37.199Z" fill="#FFB54C"/>
        <path d="M35.7071 34.293C35.3166 33.9025 34.6834 33.9025 34.2929 34.293C33.9024 34.6835 33.9024 35.3167 34.2929 35.7072C34.9089 36.3232 35.658 37.4536 35.8724 38.7702C36.0766 40.0247 35.8124 41.5235 34.2929 43.043C33.9024 43.4335 33.9024 44.0667 34.2929 44.4572C34.6834 44.8477 35.3166 44.8477 35.7071 44.4572C36.3231 43.8412 37.4535 43.0921 38.77 42.8778C40.0246 42.6735 41.5233 42.9377 43.0429 44.4572C43.4334 44.8477 44.0666 44.8477 44.4571 44.4572C44.8476 44.0667 44.8476 43.4335 44.4571 43.043C43.8411 42.427 43.0919 41.2966 42.8776 39.9801C42.6734 38.7255 42.9375 37.2268 44.4571 35.7072C44.8476 35.3167 44.8476 34.6835 44.4571 34.293C44.0666 33.9025 43.4334 33.9025 43.0429 34.293C42.4269 34.909 41.2965 35.6582 39.9799 35.8725C38.7254 36.0767 37.2266 35.8126 35.7071 34.293Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <!-- 綠色勾 -->
      <svg class="checkIcon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="option.isVoted">
        <circle cx="15" cy="15" r="11" fill="white"/>
        <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="22">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15 26C21.0751 26 26 21.0751 26 15C26 8.92487 21.0751 4 15 4C8.92487 4 4 8.92487 4 15C4 21.0751 8.92487 26 15 26ZM19.7828 13.1314C20.0171 12.8971 20.0171 12.5172 19.7828 12.2829L19.2171 11.7172C18.9828 11.4829 18.6029 11.4829 18.3686 11.7172L14 16.0858L11.6313 13.7172C11.397 13.4829 11.0171 13.4829 10.7828 13.7172L10.2171 14.2829C9.9828 14.5172 9.9828 14.8971 10.2171 15.1314L13.1514 18.0657C13.6201 18.5343 14.3799 18.5343 14.8485 18.0657L19.7828 13.1314Z" fill="#26D07C"/>
        </mask>
        <g mask="url(#mask2)">
          <rect width="30" height="30" fill="#26D07C"/>
        </g>
      </svg>
    </div>
    <div class="ResulTextBarArea" :style="questionType == 1 ? '' : 'margin-left:10px'">
      <div class="textBarArea">
        <div>
          <div class="resultTextArea">{{ option.title }}</div>
          <div class="percentNumArea">{{ option.votingRate }}<span class="percentMark">%</span></div>
        </div>
        <progress-bar ref="progressBar" :options="options" :value="option.votingRate" :style="questionType == 1 ? 'width:100%' : 'width:calc(100% - 60px)'"/>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchButton from '../../components/SwitchButton.vue';
import ProgressBar from 'vuejs-progress-bar'

export default {
  name: 'ResultAnswerCell',
  components: {
    SwitchButton,
    ProgressBar,
  },
  props: ['option', 'questionType', 'maxVotingRate'],
  watch: {
    option: function(newVal, oldVal) {
    }
  },
  data() {
    return {
      // ProgressBar 使用-樣式
      options: {
        text:{
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: true
        },
        progress: {
          color: 'rgb(204,204,204)',
          backgroundColor: 'rgb(238,238,238)',
          inverted: false,
        },
        layout: {
          height: 8,
          //width: 220 ,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 25,
          progressPadding: 0,
          type: 'line',
        }
      },
    }
  },
  mounted: function() {
    var svg = this.$el.querySelectorAll('.vuejs-progress-bar svg');
    for (var i = 0; i <= svg.length - 1; i++) {
      svg[i].style['width'] = '100%';
      svg[i].style['border-radius'] = '2px';
    }

    // 進度 Bar 動畫 (svg 的 line animation)
    var line = this.$el.querySelectorAll('.vuejs-progress-bar line');
    //line[1].style['animation'] = 'offset 2s linear forwards';
    line[1].innerHTML = '<animate attributeName="x2" \
                         from="0" to="' + this.option.votingRate + '%" dur="1s"></animate>';
  },
};
</script>

<style lang="sass" scoped>
  .txtCheckboxArea
    display: flex
    margin-right: 5px
    width: 48px
    height: 48px
    align-items: center
    justify-content: center

  .ResulTextBarArea
    display: inline-block
    width: calc(100% - 72px)

  .picCheckboxArea
    width: 50px
    height: 50px
    border-radius: 18px
    background-color: #ddd
    margin-right: 8px
    position: relative
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    //background-image: 串資料撈圖片當背景

  .answerRow
    display: flex
    align-items: center
    margin-bottom: 30px

  .crownImg
    position: absolute
    top: -10px
    left: -10px

  .checkIcon
    position: absolute
    bottom: -10px
    right: -10px

  .textBarArea
    height: 50px
    width: 100%
    color: #999999
    font-size: 15px
    //display: flex

  .percentNumArea
    width: 43px
    height: 20px
    display: inline-block
    margin-left: 20px
    text-align: center
    line-height: 20px
    font-size: 15px
    color: #999999

  .percentMark
    font-size: 15px

  .resultTextArea
    width: calc(100% - 68px)
    display: inline-block
    margin-top: 8px
</style>

<!-- 一則留言 -->
<template lang="html">
  <div class="flex align-center">
    <!-- 左邊頭像區 -->
    <div class="headshot" :style="{ 'background-image': comment.comment.author.avatar ? 'url(\'' + comment.comment.author.avatar + '\')' : '', 'background-size': 'cover' }"></div>
    <!-- #region 右邊文字區 -->
    <div class="right">
      <div class="name">{{ comment.comment.author.name }}</div>
      <div class="context" ref="content" v-html="textReplaceToATag(comment.comment.content)"></div>
      <div v-if="isOverflowing" class="read-more" @click="readMore">......顯示更多</div>
      <div class="clear"></div>
      <!-- link 顯示 og -->
      <LinkOgBlock :comment="comment" :type="'comment'" />
      <!-- #region 留言功能互動列 -->
      <div class="flex space-between">
        <!-- #region 文字互動 -->
        <div class="flex justify-start">
          <div class="time">{{ toTimeSinceString(comment.comment.createdTime) }}</div>
          <div 
            class="like"
            :style="myReactionTextStyle"
            @touchstart="(e) => touchStartLogic(e)"
            @touchend="touchEndLogic(saveMyReactionType === 0 ? 1 : 0)"
            @touchmove="(e) => touchMoveLogic(e)"
          >
            {{ displayMyReaction }}
          </div>
          <div class="reply" @click="sendReply(null)">回覆</div>
          <div v-if="comment.canDelete" class="reply" @click="deleteComment">刪除</div>
          <div v-if="comment.canReport" @click="showReportComment(comment.comment.commentId, 1)" class="report">檢舉</div>
        </div>
        <!-- #region 最右邊，顯示 emoji + 數量 ( emoji 最多顯示三個 ) -->
        <div v-if="reactionCount > 0" class="flex litter-emoji-block">
          <div class="litter-emoji-container flex align-center">
            <div class="a-litter-emoji" v-for="(imageName, index) in imageDataLogic" :key="index">
              <img class="emoji-icon" :src="require(`@/assets/images/emoji/${imageName}.png`)">
            </div>
          </div>
          <div class="thumb-counting">
            {{ reactionCount }}
          </div>
        </div>
      </div>
      <!-- #endregion 留言功能互動列 -->
      <!-- #region 查看回覆 -->
      <div v-if="comment.replyCount > 0" class="flex align-center align-items check-comment" @click="toggleReplies">
        <div class="horizontal-line"></div>
        <div class="view-pre-comment">{{ !isShowReplies ? '查看' : '隱藏' }}之前的 {{ comment.replyCount }} 則回覆</div>
      </div>
      <!-- #endregion 查看回覆 -->
      <!-- 回覆 -->
      <div v-if="isShowReplies && !isLoading">
        <Reply
          class="reply-comment"
          :fromPage="fromPage"
          :newsMainBannerId="newsMainBannerId"
          :subjectID="subjectID"
          :aliasID="aliasID"
          :commentId="comment.comment.commentId"
          :pageInfoData="pageInfoData"
          v-for="reply in replyList"
          :key="reply.replyId"
          :reply="reply"
          @sendReply="sendReply"
          @showReportComment="showReportComment"
          @handleGetReplies="handleGetReplies"
        />
      </div>
    </div>
    <!-- #endregion 右邊文字區 -->

    <!-- 長按五種表情後的彈窗 -->
    <CustomDialog
      :isDisplayDialog.sync="isDisplayDialog"
      :text="displayTouchMoveText"
      :imageSrc="require(`@/assets/images/emoji/${displayTouchMoveImage}.png`)"
    />
    <!-- 五種表情符號 -->
    <LongPressEmojiBlock 
      :id='`commentEmojiBlock-${comment.comment.commentId}`'
      class="emoji-blocks"
      :commentId="comment.comment.commentId"
      :pageInfoData="pageInfoData"
      :saveMyReactionType="saveMyReactionType"
      :zoomInDirection="zoomInDirection"
      :inEmojiTouching="inEmojiTouching"
      @clickReaction="clickReaction"
      @updateDisplayMyReaction="updateDisplayMyReaction"
    />
    <iOSAlert ref="alert" :message="message" />
  </div>
</template>

<script>
import { passEvent } from '@/utils/trackingHelper';
import { timeSince } from '@/utils/timeHelper';
import { comment } from '@/mixins';

import Reply from '../components/Reply.vue';
import iOSAlert from '../../components/iOSAlert.vue';
import LongPressEmojiBlock from '../../components/LongPressEmojiBlock.vue';
import LinkOgBlock from '../../components/LinkOgBlock.vue';
import CustomDialog from '../../components/shared/CustomDialog.vue';

export default {
  name: 'Comment',
  mixins: [comment],
  props: ['newsMainBannerId', 'subjectID', 'aliasID', 'comment', 'fromPage',
    'myReaction', 'pageInfoData'],
  components: {
    Reply,
    iOSAlert,
    LongPressEmojiBlock,
    LinkOgBlock,
    CustomDialog
  },
  watch: {
    stateComment: function(newVal, oldVal) {
    },
    deep: true,
  },
  data() {
    return {
      isLoading: true,
      isOverflowing: false,
      isShowReplies: false,
      message: {
        title: '',
        subtitle: [],
        callbackOk: () => {},
        callbackCancel: () => {},
      },
      timing: 0,
      intervalIds: [],
      elem: '',
      displayMyReaction: '',
      myReactionTextStyle: '',
      saveMyReactionType: '',
      commentLongPressEmojiBlockName: '',
      commentListReactions: [],

      // 表情符號框彈出後，滑動到表情符號要放大縮小的參數
      saveClientX: 0,
      saveClientY: 0,
      prevTouchEmojiNumber: '', // NOTE: 會判斷是否為空值
      inEmojiTouching: false,
      isDisplayDialog: false,
      displayTouchMoveImage: 'imgImEmojiLike',
      displayTouchMoveText: '',
      zoomInDirection: 'up',
      isSentSdk: false, // 開啟或關閉阻擋一頁的手勢
      composeEmoji: [
        { id: 1, imageName: 'imgImEmojiLike', text: '讚' },
        { id: 3, imageName: 'imgImEmojiHeart', text: '愛心' },
        { id: 4, imageName: 'imgImEmojiLaugh', text: '大笑' },
        { id: 5, imageName: 'imgImEmojiAngry', text: '生氣' },
        { id: 6, imageName: 'imgImEmojiWow', text: '驚訝' },
      ],
    }
  },
  mounted() {
    this.handleGetReactions();
    this.saveMyReactionType = this.myReaction
    this.commentLongPressEmojiBlockName =
      document.getElementById(`commentEmojiBlock-${this.comment.comment.commentId}`)
    this.displayMyReaction = this.myReactionText(this.comment.myReaction)
    if(this.comment.myReaction !== 0) {
      this.myReactionTextStyle = 'color: #000000'
    }
    document.addEventListener('click', () => this.closeLongPress('isClick') ,true);
    document.addEventListener('scroll', () => this.closeLongPress('isScroll') ,true);
    document.addEventListener('touchmove', (e) => {
      this.touchMoveLogic(e)
    });
    document.addEventListener('touchend', async () => {
      this.intervalIds.forEach((item,index)=>{
        clearInterval(item)
      })
      this.intervalIds = []
      this.timing = 0

      if (this.inEmojiTouching && this.prevTouchEmojiNumber !== '') {
        this.clickReaction(this.composeEmoji[this.prevTouchEmojiNumber].id)
        this.updateDisplayMyReaction(this.composeEmoji[this.prevTouchEmojiNumber].id)
        if(this.prevTouchEmojiNumber !== '') {
          this.commentLongPressEmojiBlockName.childNodes[0]
            .childNodes[this.prevTouchEmojiNumber].classList.add('a-emoji-zoom-out')
        }
        this.inEmojiTouching = false
        this.closeLongPress()
        this.prevTouchEmojiNumber = ''
        if(this.isSentSdk) {
          // 關閉阻擋上一頁的手勢
          BGO.set_back_gesture(true)
          this.isSentSdk = false
        }
      }
    })
  },
  computed: {
    stateComment() {
      return this.$store.state.Comment.list
            .filter(x => x.comment.commentId == this.comment.comment.commentId)[0];
    },
    reactionDetails() {
      return this.stateComment.reactionDetails;
    },
    replyList() {
      const comment = this.$store.state.Comment.list
                      .filter(x => x.comment.commentId == this.comment.comment.commentId)[0];
      if (comment.replies) {
        return comment.replies;
      } else {
        return [];
      }
    },
    reactionCount() {
      return this.commentListReactions.length
    },
    imageDataLogic() {
      if(this.commentListReactions && this.commentListReactions.length === 0) {
        return []
      }
      const map = new Map()
      this.commentListReactions.forEach(e => {
        if (map.get(e.type)) {
          let getNum = map.get(e.type);
          map.set(e.type, getNum+=1);
        } else {
          map.set(e.type, 1);
        }
      });
      const mapConvertArr = Array.from(map, ([type, count]) => ({ type, count }));;
      mapConvertArr.sort(function(a, b) {
        return a.count - b.count;
      });
      const composeReaction = {
        1: "imgImEmojiLike",
        3: "imgImEmojiHeart",
        4: "imgImEmojiLaugh",
        5: "imgImEmojiAngry",
        6: "imgImEmojiWow"
      };
      let arr = [];
      mapConvertArr.slice(0, 3).forEach(e => {
        arr.push(composeReaction[e.type]);
      });
      return arr
    },
    cat0() {
      let value = '';
      const search = new URLSearchParams(window.location.search);
      switch (parseInt(search.get('newsMainBannerId'), 10)) {
        case 1:
          value = '電玩星';
          break;
        case 2:
          value = '放鬆星';
          break;
        case 3:
          value = '時事星';
          break;
      }
      return value;
    },
  },
  methods: {
    closeLongPress(form) {
      const emos = document.getElementsByClassName('emoji-blocks')
      if(form === 'isClick' || form === 'isScroll') {
        this.inEmojiTouching = false
      }
      emos.forEach(e => {
        if(e.style.display !== 'none') {
          e.classList.add('fade-out')
          setTimeout(() => {
            e.classList.remove('fade-out')
            e.style.display = 'none'
            e.style.zIndex = 0
            }, 100)
        }
      })
      if(this.prevTouchEmojiNumber) {
        const zoomInClass = this.zoomInDirection === 'up' ?
          'a-emoji-zoom-in-up' : 'a-emoji-zoom-in-down'
        this.commentLongPressEmojiBlockName.childNodes[0]
            .childNodes[this.prevTouchEmojiNumber].classList.remove(zoomInClass)
      }
      if(this.isSentSdk) {
        this.isSentSdk = false
        // 關閉阻擋上一頁的手勢
        BGO.set_back_gesture(true)
      }
    },
    touchStartLogic(e) {
      e.preventDefault()
      this.closeLongPress()
      if(!this.inEmojiTouching) {
        this.elem = e
        const pageX = this.elem.targetTouches[0].pageX
        const pageY = this.elem.targetTouches[0].pageY
        // 小於 100 時，表情符號框往下長
        if(e.touches[0].clientY < 100) {
          this.zoomInDirection = 'down'
        } else {
          this.zoomInDirection = 'up'
        }
        let id = 0
        id = setInterval(() => {
          this.timing += 100
          if(this.timing > 500) {
            const emo = this.commentLongPressEmojiBlockName
            if(!this.isSentSdk) {
              // 開啟阻擋上一頁的手勢
              BGO.set_back_gesture(false)
              this.isSentSdk = true
            }
            if(this.fromPage === 'CommentList') {
              if(this.zoomInDirection === 'down') {
                emo.style.top = `${pageY + 20}px`
              } else {
                emo.style.top = `${pageY - 70}px`
              }
            } else {
              if(this.zoomInDirection === 'down') {
                emo.style.top = `${pageY - 150}px`
              } else {
                emo.style.top = `${pageY - 235}px`
              }
            }
            emo.style.left = `${pageX - 70}px`
            emo.style.display = 'block'
            this.inEmojiTouching = true
          }
        }, 100);
        this.intervalIds.push(id)
      }
    },
    touchEndLogic(saveMyReactionType) {
      if(this.timing > 100) {
        setTimeout(() => {
          this.intervalIds.forEach((item,index)=>{
            clearInterval(item)
          })
          this.intervalIds = []
          this.timing = 0
          this.inEmojiTouching = false
        }, 100)
      }
      if(this.inEmojiTouching && this.timing <= 100) {
        this.inEmojiTouching = false
        this.closeLongPress()
      } else if (this.timing <= 100 && !this.inEmojiTouching) {
        this.clickReaction(saveMyReactionType)
        this.updateDisplayMyReaction(saveMyReactionType)
      }
    },
    touchMoveLogic(e) {
      const getOffsetLeft = this.commentLongPressEmojiBlockName.offsetLeft // 表情符號框離螢幕左邊的距離
      const leftLimit = getOffsetLeft + 12 // 12 為表情符號框的邊框半圓寬度
      const rightLimit = getOffsetLeft + 264 - 12 // 264 表情符號框的寬度, 12 為表情符號框的邊框半圓寬度
      const topLimit = this.commentLongPressEmojiBlockName.offsetTop
      const bottomLimit = this.commentLongPressEmojiBlockName.offsetTop + 48 // 48 為表情符號框的高度
      const touchX = e.touches[0].clientX
      let touchY = ''
      if(this.fromPage === 'CommentList') {
        touchY = e.touches[0].pageY
      } else {
        // 這個情境在投票頁底下的留言，因畫面有下推 top 150 + margin 20 的關係，故需要扣 170
        touchY = e.touches[0].pageY - 170
      }
      const isXLimited = touchX > leftLimit && touchX < rightLimit
      const isYLimited = touchY > topLimit && touchY < bottomLimit
      const zoomInClass =
        this.zoomInDirection === 'up' ? 'a-emoji-zoom-in-up' : 'a-emoji-zoom-in-down'
      const showTextClass = this.zoomInDirection === 'up' ? 'showTextUp' : 'showTextDown'
      const id = `.commentEmojiBlock-${this.comment.comment.commentId}_a-emoji-id`
      if(isXLimited && isYLimited &&
        this.commentLongPressEmojiBlockName.style.display === 'block') {
        this.inEmojiTouching = true
        // 12 為表情符號框的邊框半圓寬度, 48 為每個表情符號框的寬度
        const currentTouchEmojiNumber = Math.trunc((touchX-getOffsetLeft-12)/48)
        if(this.prevTouchEmojiNumber === '') {
          const currentEmojiText = document.querySelector(`${id}_${currentTouchEmojiNumber+1}`);
          currentEmojiText.classList.add(showTextClass); // 放大顯示的文字 (偽元素)
          this.commentLongPressEmojiBlockName.childNodes[0]
            .childNodes[currentTouchEmojiNumber].classList.add(zoomInClass)
          this.prevTouchEmojiNumber = currentTouchEmojiNumber
          return
        }
        if(currentTouchEmojiNumber !== this.prevTouchEmojiNumber) {
          // 前一個放大的表情符號
          const prevEmojiText = document.querySelector(`${id}_${this.prevTouchEmojiNumber+1}`);
          prevEmojiText.classList.remove(showTextClass); // 移除放大顯示的文字 (偽元素)
          this.commentLongPressEmojiBlockName.childNodes[0]
            .childNodes[this.prevTouchEmojiNumber].classList.remove(zoomInClass) // 移除放大
          this.commentLongPressEmojiBlockName.childNodes[0]
            .childNodes[this.prevTouchEmojiNumber].classList.add('a-emoji-zoom-out') // 新增縮小

          // 當前要放大的表情符號
          this.commentLongPressEmojiBlockName.childNodes[0]
            .childNodes[currentTouchEmojiNumber].classList.add(zoomInClass)
          this.commentLongPressEmojiBlockName.childNodes[0]
            .childNodes[currentTouchEmojiNumber].classList.remove('a-emoji-zoom-out')
          const currentEmojiText = document.querySelector(`${id}_${currentTouchEmojiNumber+1}`);
          currentEmojiText.classList.add(showTextClass); // 放大顯示的文字 (偽元素)
          this.prevTouchEmojiNumber = currentTouchEmojiNumber
        }
        return
      }

      if(!(isXLimited && isYLimited) && this.prevTouchEmojiNumber !== '') {
        this.inEmojiTouching = false
        const prevEmojiText = document.querySelector(`${id}_${this.prevTouchEmojiNumber+1}`);
        prevEmojiText.classList.remove(showTextClass); // 移除放大顯示的文字 (偽元素)
        this.commentLongPressEmojiBlockName.childNodes[0]
          .childNodes[this.prevTouchEmojiNumber].classList.remove('a-emoji-zoom-out')
        this.commentLongPressEmojiBlockName.childNodes[0]
          .childNodes[this.prevTouchEmojiNumber].classList.remove(zoomInClass)
        this.prevTouchEmojiNumber = ''
      }
    },
    updateDisplayMyReaction(reactionType) {
      const composeReactionType = {
        1: "讚",
        3: "愛心",
        4: "大笑",
        5: "生氣",
        6: "驚訝"
      };
      this.displayMyReaction = composeReactionType[reactionType] || '讚'
      const displayId = this.composeEmoji.findIndex(c => c.id === reactionType)
      if(this.saveMyReactionType === 0 && reactionType > 0) { // 無按過
        this.myReactionTextStyle = 'color: #000000'
        this.openDialog(displayId)
      } else if (this.saveMyReactionType === reactionType) { // 點相同 === 取消
        this.myReactionTextStyle = ''
        this.displayMyReaction =  '讚'
      } else if (reactionType === 0) { // 取消
        this.myReactionTextStyle = ''
      } else if(reactionType !== 0) { // 重複按
        this.openDialog(displayId)
      }

      // 點擊相同視為取消，故更新時要送 0
      const updateReactionType = this.saveMyReactionType === reactionType ? 0 : reactionType
      this.saveMyReactionType = updateReactionType

      // 移除放大
      if(this.prevTouchEmojiNumber !== '') {
        const zoomInClass =
          this.zoomInDirection === 'up' ? 'a-emoji-zoom-in-up' : 'a-emoji-zoom-in-down'
        const id = `.commentEmojiBlock-${this.comment.comment.commentId}_a-emoji-id`
        const showTextClass = this.zoomInDirection === 'up' ? 'showTextUp' : 'showTextDown'
        const prevEmojiText = document.querySelector(`${id}_${this.prevTouchEmojiNumber+1}`);
        prevEmojiText.classList.remove(showTextClass); // 移除放大顯示的文字 (偽元素)
        this.commentLongPressEmojiBlockName.childNodes[0]
          .childNodes[this.prevTouchEmojiNumber].classList.remove(zoomInClass) // 移除放大
      }
    },
    openDialog(displayId) {
      this.isDisplayDialog = true
      this.displayTouchMoveImage = this.composeEmoji[displayId].imageName
      this.displayTouchMoveText = this.composeEmoji[displayId].text
    },
    clickReaction(reactionType, isLongPress) {
      const payload = {
          lotterId: this.comment.comment.commentId,
          lotterType: 1, // 1 留言, 2 回覆
          type: reactionType,
          aliasId: this.aliasID,
      }
      this.requestClickCommentReaction({
        id: 'vote',
        body: payload,
        onSuccess: (res)  => {
          this.handleGetReactions();
        }
      })
      if(!isLongPress) {
        // 點擊讚
        this.pageInfoData['cat0'] = this.cat0
        const eventItems = [
        {
          type: 'clickEvent',
          eventId: '1049',
          event: 'comment_content_page_item_click',
          pageInfo: this.pageInfoData,
          clickInfo: {
            uuid: this.comment.comment.commentId,
            type: 'comment_function_bar',
            name: 'emoji',
          },
        },
      ];
        passEvent(eventItems);
      }
    },
    myReactionText(myReaction) {
      const composeReaction = {
        1: "讚",
        3: "愛心",
        4: "大笑",
        5: "生氣",
        6: "驚訝"
      };
      return composeReaction[myReaction] || "讚";
    },
    handleGetComment() {
      const commentIds = [this.comment.comment.commentId];
      this.requestGetComment({
        newsMainBannerId: this.newsMainBannerId,
        commentIds: commentIds,
        aliasId: this.aliasID,
        entityId: this.subjectID,
        onSuccess: (res) => {
          this.handleGetReactions();
        },
        onFailed: (err) => {
        },
      });
    },
    handleDeleteComment() {
      this.requestDeleteComment({
        aliasId: this.aliasID,
        entityId: this.subjectID,
        commentId: this.comment.comment.commentId,
        onSuccess: (res) => {
          this.deleteCommentInList({
            commentId: this.comment.comment.commentId,
          });
        },
        onFailed: (err) => {
          this.$emit('deleteFailed');
        },
      });
    },
    handleGetReplies() {
      this.requestGetReplies({
        id: 'vote',
        entityType: 'vote',
        commentId: this.comment.comment.commentId,
        aliasId: this.aliasID,
        prev: '',
        next: '',
        show: this.comment.replyCount,
        sort: 0,
        onSuccess: (res) => {
          this.$nextTick(function () {
            this.isLoading = false;
          });
        },
        onFailed: (err) => {
        },
      });
    },
    handleGetReactions() {
      this.requestGetReactions({
        id: 'vote',
        lotterType: 1, // 1 留言，2 回覆
        lotterId: this.comment.comment.commentId,
        aliasId: this.aliasID,
        prev: '',
        next: '',
        show: 30,
        sort: 0,
        onSuccess: (res) => {
          this.commentListReactions = res.data.listReactions
        },
        onFailed: (err) => {
        },
      });
    },
    toTimeSinceString(timestamp) {
      return timeSince(timestamp);
    },
    sendReply(replyId) {
      this.$emit('sendReply', this.comment.comment.commentId, this.comment.comment.author);

      // [event trace] 12-3 留言或回覆的功能列點擊-1:回覆
      let eventItems = [
        {
          type: 'clickEvent',
          eventId: '1049',
          event: 'comment_content_page_item_click',
          pageInfo: {
            page: this.fromPage === 'CommentList' ? 'comment' : 'poll',
            type: 'poll',
            uuid: this.subjectID,
            provider: '',
            cat0: [],
            cat1: [],
            cat2: [],
            tag: [],
          },
          clickInfo: {
            uuid: '',
            type: '',
            name: 'reply',
          },
        },
      ];
      if (!replyId) {
        // 留言
        eventItems[0].clickInfo.uuid = this.comment.comment.commentId;
        eventItems[0].clickInfo.type = 'comment_function_bar';
      } else {
        // 回覆
        eventItems[0].clickInfo.uuid = replyId;
        eventItems[0].clickInfo.type = 'reply_function_bar';
      }
      passEvent(eventItems);
    },
    deleteComment() {
      this.message = {
        title: '確定刪除留言嗎?',
        subtitle: ['刪除後將無法再找回，確定要刪除嗎?'],
        callbackOk: () => {
          this.handleDeleteComment();

          // [event trace] 12-3 留言或回覆的功能列點擊-5:刪除
          const eventItems = [
            {
              type: 'clickEvent',
              eventId: '1049',
              event: 'comment_content_page_item_click',
              pageInfo: {
                page: this.fromPage === 'CommentList' ? 'comment' : 'poll',
                type: 'poll',
                uuid: this.subjectID,
                provider: '',
                cat0: [],
                cat1: [],
                cat2: [],
                tag: [],
              },
              clickInfo: {
                uuid: this.comment.comment.commentId,
                type: 'comment_function_bar',
                name: 'delete',
              },
            },
          ];
          passEvent(eventItems);
        },
        callbackCancel: () => {},
      };
      this.$refs.alert.showPopup();
    },
    toggleReplies() {
      this.isShowReplies = !this.isShowReplies;
      if (this.isShowReplies) {
        this.handleGetReplies();

        // [event trace] 12-5 留言點擊查看更多留言或查看之前的回覆 (看更多)
        const eventItems = [
          {
            type: 'clickEvent',
            eventId: '1049',
            event: 'comment_content_page_item_click',
            pageInfo: {
              page: this.fromPage === 'CommentList' ? 'comment' : 'poll',
              type: 'poll',
              uuid: this.subjectID,
              provider: '',
              cat0: [],
              cat1: [],
              cat2: [],
              tag: [],
            },
            clickInfo: {
              uuid: this.comment.comment.commentId,
              type: 'reply',
              name: 'more',
            },
          },
        ];
        passEvent(eventItems);
      }
    },
    showReportComment(lotterId, lotterType) {
      this.$emit('showReportComment', lotterId, lotterType, this.comment.comment.commentId);

      // [event trace] 12-3 留言或回覆的功能列點擊-4:檢舉
      let eventItems = [
        {
          type: 'clickEvent',
          eventId: '1049',
          event: 'comment_content_page_item_click',
          pageInfo: {
            page: this.fromPage === 'CommentList' ? 'comment' : 'poll',
            type: 'poll',
            uuid: this.subjectID,
            provider: '',
            cat0: [],
            cat1: [],
            cat2: [],
            tag: [],
          },
          clickInfo: {
            uuid: lotterId,
            type: '',
            name: 'report',
          },
        },
      ];
      if (lotterType === 1) {
        // 留言
        eventItems[0].clickInfo.type = 'comment_function_bar';
      } else {
        // 回覆
        eventItems[0].clickInfo.type = 'reply_function_bar';
      }
      passEvent(eventItems);
    },
    readMore() {
      this.$refs.content.style.maxHeight = 'none';
      this.isOverflowing = false;
    },
  },
  updated() {
    const element = this.$refs.content;
    if (!element) {
      this.isOverflowing = false;
    }
    this.isOverflowing = element.offsetHeight < element.scrollHeight;
  },
};
</script>

<style lang="sass" scoped>
.headshot
  height: 30px
  width: 30px
  border-radius: 50%
  margin-right: 12px 
  background: url('~@/assets/images/unnamed.png') center no-repeat
  
.right
  width: calc(100% - 45px)

.name
  color: rgb(51, 51, 51)
  font-family: PingFangTC-Medium
  font-size: 15px
  line-height: 20px
  letter-spacing: 0.1px
  margin-bottom: 8px
  padding-top: 3px

.context
  color: #333333
  font-family: PingFangTC-Regular
  font-size: 15px
  letter-spacing: 0.25px
  line-height: 24px
  margin-bottom: 8px
  margin-right: 42px
  max-height: 72px
  overflow-y: hidden
  white-space: pre-wrap
  word-break: break-all
  hyphens: auto

.read-more
  margin-bottom: 14px
  font-family: PingFangTC-Medium
  font-size: 15px
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 10%)
  position: relative
  width: 120px
  color: #999999

.clear
  clear: both

.time
  color: #999999
  font-family: PingFangTC-Regular
  font-size: 13px
  line-height: 18px
  letter-spacing: 0.4px
  margin-right: 16px

.reply
  color: #999999
  font-family: PingFangTC-Medium
  font-size: 13px
  line-height: 18px
  letter-spacing: 0.4px
  margin-right: 16px

.report
  color: #999999
  font-family: PingFangTC-Medium
  font-size: 13px
  line-height: 18px
  letter-spacing: 0.4px
  margin-right: 16px

.thumb-counting
  font-family: PingFangTC-Regular
  font-size: 13px
  line-height: 20px
  letter-spacing: 0.4px
  color: #999999
  margin: -2px 0px 0px 5px

.like 
  margin-right: 16px
  font-size: 13px
  color: #999999
  line-height: 18px
  letter-spacing: 0.4px

.thumbs
  margin-right: 14px

.horizontal-line
  background: #999999
  height: 1px
  width: 24px
  margin: 0px 15px
  margin-left: 0px

.view-pre-comment
  font-family: PingFangTC-Regular
  font-size: 13px
  line-height: 18px
  letter-spacing: 0.4px
  color: #999999

.check-comment
  margin-top: 8px

.reply-comment
  margin: 8px 0px 0px 0px

.litter-emoji-block
  margin-right: 5px

.litter-emoji-container div:nth-child(1)
  z-index: 2

.litter-emoji-container div:nth-child(2)
  z-index: 1

.litter-emoji-container div:nth-last-child
  z-index: 0
  margin-right: 3px

.litter-emoji-container
  color: #999999
  font-family: PingFangTC-Medium
  font-size: 13px
  line-height: 18px
  letter-spacing: 0.4px

.a-litter-emoji 
  margin-right: -3px

.a-litter-emoji > img 
  -webkit-touch-callout: none
  -webkit-user-select: none
  -webkit-user-drag: none


.emoji-icon 
  width: 16px
  height: 16px
  background-color: #fff
  border-radius: 5px
</style>

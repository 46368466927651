export default {
  methods: {
    requestGetVoteList({
      chatID = 0,
      aliasID = 0,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('GET_VOTE_LIST', {
        chatID,
        aliasID,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
  },
};

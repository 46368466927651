import Vue from 'vue';

// vue-loading-overlay
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import Loader from '../components/Loader.vue';

Vue.use(VueLoading);

export default {
  components: {
    Loader,
  },
  methods: {
    showLoading() {
      // 顯示 Loading
      return this.$loading.show({
        canCancel: false,
        // onCancel: this.yourMethodName,
        container: null,
        color: 'rgb(0, 171, 0)',
        // loader: 'dots',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
      }, {
        default: this.$createElement('Loader'),
        // before: this.$createElement('Loader'),
        // after: this.$createElement('Loader'),
      });
    },
    hideLoading(loader, time) {
      if (time === undefined) {
        loader.hide();
      } else {
        setTimeout(() => loader.hide(), time);
      }
    },
  },
};

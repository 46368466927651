export const getOSType = () => {
  if (navigator) {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const osList = [
      {
        name: 'ios',
        prediction: ua => /iPad|iPhone|iPod/.test(ua) && !window.MSStream,
      },
      {
        name: 'android',
        prediction: ua => /android/i.test(ua),
      },
    ];
    const os = osList.find(x => x.prediction(userAgent));
    return os ? os.name : undefined;
  }
  return '';
};

export default getOSType;

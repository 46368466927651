class Option {
  constructor({
    optionID,
    title,
    imageUri,
    sortID,
    votingRate,
    isSelected,
  }) {
    this.optionID = optionID;
    this.title = title;
    this.imageUri = imageUri;
    this.sortID = sortID;
    this.votingRate = votingRate;
    this.isSelected = isSelected;
  }

  serialize() {
    return {
      optionID: this.optionID,
      title: this.title,
      imageUri: this.imageUri,
      sortID: this.sortID,
      votingRate: this.votingRate,
      isSelected: this.isSelected,
    };
  }
}

export default Option;

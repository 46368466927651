<template lang="html">
  <div class="textBarArea">
    <div>
      <div class="title_15px resultTextArea">{{ championOption.title }}</div>
      <div class="percentNumArea">{{ championOption.votingRate }}<span class="percentMark">%</span></div>
    </div>
    <progress-bar :options="options" :value="championOption.votingRate" style="width:100%"/>
  </div>
</template>

<script>
import SwitchButton from '../../components/SwitchButton.vue';
import ProgressBar from 'vuejs-progress-bar'

export default {
  name: 'ResultTextBarChampion',
  components: {
    SwitchButton,
    ProgressBar,
  },
  props: ['championOption'],
  watch: {
    championOption: function(newVal, oldVal) {

    },
  },
  data() {
    return {
      // ProgressBar 使用-樣式
      options: {
        text:{
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: true
        },
        progress: {
          color: 'rgb(38,208,124)',
          backgroundColor: 'rgb(238,238,238)',
          inverted: false
        },
        layout: {
          height: 16,
          //width: 220 ,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 25,
          progressPadding: 0,
          type: 'line',
        }
      },
      // ProgressBar 使用-值
      value: 75,
    }
  },
  mounted: function(){
    var svg = this.$el.querySelectorAll('.vuejs-progress-bar svg');
    for (var i = 0; i <= svg.length - 1; i++) {
      svg[i].style['width'] = '100%';
      svg[i].style['border-radius'] = '4px';
    }

    // 進度 Bar 動畫 (svg 的 line animation)
    var line = this.$el.querySelectorAll('.vuejs-progress-bar line');
    //line[1].style['animation'] = 'offset 2s linear forwards';
    line[1].innerHTML = '<animate attributeName="x2" \
                         from="0" to="' + this.championOption.votingRate + '%" dur="1s"></animate>';
  }
};
</script>

<style lang="sass" scoped>
  .textBarArea
    height: 60px
    width: 100%

  .percentNumArea
    width: 43px
    height: 32px
    display: inline-block
    margin-left: 20px
    text-align: center
    line-height: 32px
    font-size: 24px
    color: rgb(51,51,51)

  .percentMark
    font-size: 15px

  .resultTextArea
    width: calc(100% - 68px)
    display: inline-block
</style>

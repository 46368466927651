<template lang="html">
  <div v-show="isShowAll" class="datetimePickerAll">
    <transition name="fade">
      <div v-show="isShow" @click="cancel" class="datetimePickerMask"></div>
    </transition>
    <transition name="slide">
      <div v-show="isShow" class="datetimePicker">
        <div class="nav">
          <div class="leftitem" @click="cancel">取消</div>
          <div class="title">結束時間</div>
          <div class="rightitem" @click="ok">確定</div>
        </div>
        <rollerPickerBox :layer="false">
          <rollerPicker :data="yearList" :index.sync="yearIndex" @change="onYearChange" :options="{height:200, style:{'font-size': '17px'}, loop:false}"></rollerPicker>
          <rollerPicker :data="monthList" :index.sync="monthIndex" @change="onMonthChange" :options="{width:60, height:200, style:{'font-size': '17px'}, loop:false}"></rollerPicker>
          <rollerPicker :data="dayList" :index.sync="dayIndex" @change="onDayChange" :options="{width:60, height:200, style:{'font-size': '17px'}, loop:false}"></rollerPicker>
          <rollerPicker :data="hourList" :index="hourIndex" @change="onHourChange" :options="{width:60, height:200, style:{'font-size': '17px'}, loop:false}"></rollerPicker>
          <rollerPicker :data="minuteList" :index="minuteIndex" @change="onMinuteChange" :options="{width:60, height:200, style:{'font-size': '17px'}, loop:false}"></rollerPicker>
        </rollerPickerBox>
        <div class="bottom"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import vueIosRollerPicker from 'vue-ios-roller-picker';

Vue.use(vueIosRollerPicker);

const getDoubleBitNumber = (n) => {
  return n < 10 ? '0' + n : (n + '');
}

export default {
  name: 'iOSDateTimePicker',
  props: {
    initdata: {
      type: Number,
    }
  },
  watch: {
    initdata: function(newVal, oldVal) {
      this.setDateTime(this.initdata);
    },
  },
  data() {
    return {
      timestampWhenShow: Date.now(),
      isShowAll: false,
      isShow: false,
      yearIndex: 0,
      monthIndex: 0,
      dayIndex: 0,
      hourIndex: 0,
      minuteIndex: 0,
      weekDays: ['日', '一', '二', '三', '四', '五', '六'],
    }
  },
  computed: {
    yearList() {
      const crtYear = new Date().getFullYear();
      const arr = [];
      for (let i = crtYear; i <= crtYear + 1; i++) {
        //arr.unshift({text: i + '年', value: i}); //倒排
        arr.push({text: i + '年', value: i});
      }
      return arr;
    },
    monthList() {
      const arr = [];
      for (let i = 1; i <= 12; i++) {
        arr.push({text: i + '月', value: i});
      }
      return arr;
    },
    dayList() {
      const d = new Date(this.year, this.month, 0);
      const arr = [];
      for (let i = 1; i <= d.getDate(); i++) {
        arr.push({text: i + '日', value: i});
      }
      return arr;
    },
    hourList() {
      const arr = [];
      for (let i = 0; i <= 23; i++) {
        arr.push({text: getDoubleBitNumber(i), value: i});
      }
      return arr;
    },
    minuteList() {
      const arr = [];
      for (let i = 0; i <= 55; i=i+5) {
        arr.push({text: getDoubleBitNumber(i), value: i});
      }
      return arr;
    },
    year() {
      return this.yearList[parseInt(this.yearIndex)].value;
    },
    month() {
      return this.monthList[parseInt(this.monthIndex)].value;
    },
    day() {
      return this.dayList[parseInt(this.dayIndex)].value;
    },
    time() {
      const h = this.hourList[this.hourIndex].text;
      const m = this.minuteList[this.minuteIndex].text;
      const n = h < 12 ? '上午' : '下午'
      return `${n} ${h < 12 ? h : h - 12}:${m}`;
    },
    toFormatString() {
      const date = this.dayjs(this.toTimestamp);
      const weekdayChinese = date.format(this.weekDays[date.day()]);
      return `${this.year}/${this.month}/${this.day} (${weekdayChinese}) ${this.time}`;
    },
    toTimestamp() {
      const h = this.hourList[this.hourIndex].text;
      const m = this.minuteList[this.minuteIndex].text;
      const dateformat = `${this.year}-${this.month}-${this.day} ${h}:${m}`;
      // 解決 Safari 轉型失敗問題：『.replace(/-/g,'/')』
      // return new Date(dateformat.replace(/-/g,'/')).getTime();
      return this.dayjs(dateformat).valueOf();
    },
  },
  methods: {
    onYearChange(index) {
      this.yearIndex = index;
    },
    onMonthChange(index) {
      const oldDayIndex = this.dayIndex;
      this.monthIndex = index;
      // dayList() 會因為月份而即時更新，需要檢查該月是否有該日
      // 若有該日則沿用舊選項，反之則預設為 1 日
      setTimeout(() => {
        if (oldDayIndex <= this.dayList.length) {
          this.dayIndex = oldDayIndex;
        }
      }, 0);
    },
    onDayChange(index) {
      this.dayIndex = index;
    },
    onHourChange(index) {
      this.hourIndex = index;
    },
    onMinuteChange(index) {
      this.minuteIndex = index;
    },
    show() {
      this.timestampWhenShow = this.toTimestamp;
      this.isShowAll = true;
      setTimeout(() => {
        this.isShow = true;
      }, 100);
    },
    getFormatString() {
      return this.toFormatString;
    },
    setDateTime(timestamp) {
      const date = new Date(timestamp);
      if (isNaN(date.getFullYear())) return false;
      const y = date.getFullYear();
      const M = date.getMonth() + 1;
      const d = date.getDate();
      const h = date.getHours();
      var m = date.getMinutes();
      for (let i = 0; i < this.yearList.length; i++) {
        const el = this.yearList[i];
        if (el.value === y) {
          this.yearIndex = i;
        }
      }
      this.monthIndex = M - 1;
      setTimeout(() => {
        // 避免 onMonthChange 影響
        this.dayIndex = d - 1;
      }, 10);
      this.hourIndex = m > 55 ? h + 1 : h;
      this.minuteIndex = m > 55 ? 0 : Math.ceil(m/5);
    },
    rollback() {
      this.setDateTime(this.timestampWhenShow);
      this.ok();
    },
    ok() {
      this.$emit('callback', this.toFormatString, this.toTimestamp);

      this.isShow = false;
      setTimeout(() => {
        this.isShowAll = false;
      }, 500);
    },
    cancel() {
      this.isShow = false;
      setTimeout(() => {
        this.isShowAll = false;
      }, 500);
    },
  },
  mounted: function() {
    //#region 滾輪區域
    var pickerContent = document.querySelectorAll('.roller-picker-box-content');
    for (var i = 0; i <= pickerContent.length - 1; i++) {
      //pickerContent[i].style['background'] = 'rgba(239, 239, 244, 0.94)';
      pickerContent[i].style['background'] = 'rgba(244, 244, 244, 0.94)';
    }
    //#endregion

    //#region 滾輪上方選項
    //var pickerLayerT = document.querySelectorAll('.roller-picker-box-layer-top');
    //for (var i = 0; i <= pickerLayerT.length - 1; i++) {
    //  pickerLayerT[i].style['background'] = 'transparent';
    //}
    //#endregion

    //#region 滾輪下方選項
    //var pickerLayerB = document.querySelectorAll('.roller-picker-box-layer-bottom');
    //for (var i = 0; i <= pickerLayerB.length - 1; i++) {
    //  pickerLayerB[i].style['background'] = 'transparent';
    //}
    //#endregion

    if (this.initdata) {
      this.setDateTime(this.initdata);
    }
  },
};
</script>

<style lang="sass" scoped>
.datetimePickerAll
  z-index: 20
  position: absolute
  top: 0px
  left: 0px
  width: 100%
  height: 100%
  .datetimePickerMask
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.28)
  .datetimePicker
    position: fixed
    left: 0px
    bottom: 0px
    width: 100%
    border-top-left-radius: 18px
    border-top-right-radius: 18px
    font-family: -apple-system, BlinkMacSystemFont, sans-serif
    .nav
      position: relative
      background-color: rgba(244, 244, 244, 0.94)
      display: flex
      width: 100%
      height: 44px
      line-height: 44px
      font-family: PingFangTC-Medium
      border-top-left-radius: 18px
      border-top-right-radius: 18px
      .leftitem
        float: left
        margin-left: 20px
        flex: 60px
        font-weight: bold
        color: rgba(0, 122, 255, 1)
      .title
        float: left
        text-align: center
        font-weight: bold
        color: rgba(51, 51, 51, 1)
      .rightitem
        float: right
        text-align: right
        margin-right: 20px
        flex: 60px
        font-weight: bold
        color: rgba(0, 122, 255, 1)
    .bottom
      background-color: rgba(244, 244, 244, 0.94)
      width: 100%
      height: 34px
      //border-top: 1px solid rgba(60, 60, 67, 0.29)

  .fade-enter, .fade-leave-to
    opacity: 0
  .fade-enter-active, .fade-leave-active
    transition: all .5s
  .fade-enter-to, .fade-leave
    opacity: 1

  .slide-enter, .slide-leave-to
    //opacity: 0
    bottom: -308px
  .slide-enter-active, .slide-leave-active
    transition: all .5s
  .slide-enter-to, .slide-leave
    //opacity: 1
    bottom: 0px
</style>
<template lang="html">
  <div class="barArea">
    <div class="leftArea">
      <div class="barText leftText">一人多票</div>
    </div>
    <div class="rightArea">
      <div class="switchBtnArea">
        <SwitchButton :isOpen="cellIsMultipleVote" @switched="switchedCallback"/>
      </div>
    </div>
  </div>

</template>

<script>

import SwitchButton from '../../components/SwitchButton.vue';

export default {
  name: 'MutipleVoteSwitchCell',
  components: {
    SwitchButton,
  },
  props: ['isMultipleVote'],
  watch: {
    isMultipleVote: function(){
      this.cellIsMultipleVote = this.isMultipleVote;
    }
  },
  data() {
    return{
      cellIsMultipleVote: this.isMultipleVote,
    }
  },
  methods: {
    switchedCallback(isOpen){
      this.cellIsMultipleVote = isOpen;
      this.$emit('isMultipleVoteSwitch', this.cellIsMultipleVote);
    }
  },
};
</script>

<style lang="sass" scoped>
.barArea
  width: 100%
  height: 51px
  position: relative

.leftArea
  width: calc(100% - 65px)
  display: inline-block
  position: absolute
  top: 15px

.rightArea
  width: 60px
  display: inline-block
  position: absolute
  top: 9px
  right: 0px

.barText
  font-size: 15px
  line-height: 20px
  letter-spacing: 0.1px

.leftText
  color: rgb(51, 51, 51)

.switchBtnArea
  width: 52px
</style>

<template lang="html">
  <div class="SubjectBanner" :style="{ 'background-image': 'url(\'' + image + '\')', 'background-size': '100% auto' }">
    <div class="bannerMask">
      <div class="bg_green flex justify_content_center">
        {{ votePeopleCount }} 人參與投票
      </div>
      <div class="limit15_20 mlmtmb">
        {{ content }}
      </div>
      <div class="flex space-between ml_20 mr_20">
        <div class="text_11px">
          <div v-show="createdUserName">建立者：{{ createdUserName }}</div>
        </div>
        <div v-if="endTime < Date.now()" class="text_11px">
        </div>
        <div v-else-if="dueDays >= 1" class="text_11px">
          剩餘 {{ dueDays }} 天
        </div>
        <div v-else-if="dueDays >= 0" class="text_11px">
          即將結束
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubjectBanner',
  props: ['content', 'image', 'createdUserName', 'votePeopleCount', 'dueDays', 'endTime'],
};
</script>

<style lang="sass" scoped>
.SubjectBanner
  background-image: url("http://fakeimg.pl/440x320/282828/EAE0D0/")
  background-repeat: no-repeat
  background-position: center
  background-color: rgba(0, 0, 0, 0.28)
  width: 100%
  height: 187.5px
  margin: 0 auto

.bannerMask
  position: absolute
  width: 100%
  height: 187.5px
  background-color: rgba(0,0,0,0.28)

.bg_green
  background: rgb(38, 208, 124)
  font-size: 13px
  color: rgb(255, 255, 255)
  width: 112px
  height: 24px
  line-height: 24px
  border-radius: 0 0 5px 0

.mlmtmb
  margin: 34px 0px 60px 20px
  </style>

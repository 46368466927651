<!--綠框 完成 按鈕-->
<template lang="html">
  <div class="roundBtnContainer">
    <div class="roundBtnActive">
      <div class="btnText">完成</div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'RoundButtonComplete',

};
</script>

<style lang="sass" scoped>
.roundBtnContainer
  height: 60px
  width: 40%
  max-width: 165px
  text-align: center
  position: relative
  display: inline-block
  margin: 0 8px

.roundBtnActive
  width: 100%
  max-width: 343px
  height: 40px
  border-radius: 16px
  background-color: #26d07c
  margin-top: 10px
  text-align: center
  display: inline-block
  position: relative

.btnText
  color: #ffffff
  font-size: 15px
  margin-top: 12px
</style>

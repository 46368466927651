class Question {
  constructor({
    questionID,
    title,
    mode,
    type,
    isMultipleVote,
    sortID,
    optionList,
  }) {
    this.questionID = questionID;
    this.title = title;
    this.mode = mode;
    this.type = type;
    this.isMultipleVote = isMultipleVote;
    this.sortID = sortID;
    this.optionList = optionList;
  }

  serialize() {
    return {
      questionID: this.questionID,
      title: this.title,
      mode: this.mode,
      type: this.type,
      isMultipleVote: this.isMultipleVote,
      sortID: this.sortID,
      optionList: this.optionList,
    };
  }
}

export default Question;

<!-- 新增留言 -->
<template lang="html">
  <div style="width:100%">
    <!-- #region 正在回覆 -->
    <div v-if="lotterType == 2" class="replying flex space-between align-items">
      <div class="replying-txt">正在回覆 {{ replyAuthorName }}</div>
      <!-- #region 叉叉圖 -->
      <div class="close-icon" @click="closeReply">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="14" height="14">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0842 10L15.9259 5.1583C16.0821 5.00209 16.0821 4.74882 15.9259 4.59261L15.4074 4.07407C15.2511 3.91786 14.9979 3.91786 14.8417 4.07407L9.99996 8.91578L5.15825 4.07407C5.00204 3.91786 4.74878 3.91786 4.59257 4.07407L4.07402 4.59261C3.91781 4.74882 3.91781 5.00209 4.07402 5.1583L8.91573 10L4.07402 14.8417C3.91781 14.9979 3.91781 15.2512 4.07402 15.4074L4.59257 15.9259C4.74878 16.0822 5.00204 16.0822 5.15825 15.9259L9.99996 11.0842L14.8417 15.9259C14.9979 16.0822 15.2511 16.0822 15.4074 15.9259L15.9259 15.4074C16.0821 15.2512 16.0821 14.9979 15.9259 14.8417L11.0842 10Z" fill="#121212" />
          </mask>
          <g mask="url(#mask0)">
            <rect width="20" height="20" fill="#a1a1a1"/>
          </g>
        </svg>
      </div>
      <!-- #endregion 叉叉圖 -->
    </div>
    <!-- #endregion 正在回覆 -->

    <!-- 投票頁不需要 留言頁需要 class:comment-reply-border-top -->
    <div class="comment-reply flex align-items comment-reply-border-top">
      <div class="left-item">
        <div class="headshot" :style="{ 'background-image': (avatar != '') ? 'url(\'' + avatar + '\')' : '', 'background-size': 'cover' }"></div>
      </div>
      <div class="right-item">
        <div :class="content != '' ? 'right-item-input' : 'right-item-input-without-send'">
          <AutosizeTextarea ref="input" :placeholder="placeholder" @setValue="setValue" @overSize="overSize" />
        </div>
        <div class="right-item-send" v-if="content != ''">
          <img :class="['send', isSending ? 'sending' : '']" src="@/assets/images/icSendMPrimary.png" alt="" @click="send">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setItemWithExpiry, getItemWithExpiry } from '@/utils/storageHelper';
import { comment } from '@/mixins';
import AutosizeTextarea from '../../components/AutosizeTextarea.vue';

export default {
  name: 'Commenting',
  mixins: [comment],
  components: {
    AutosizeTextarea,
  },
  props: ['newsMainBannerId', 'lotterType', 'lotterId', 'aliasID', 'replyAuthorName'],
  watch: {
    content: function(newVal, oldVal) {
      this.$emit('contentChange');
    },
  },
  data() {
    return {
      content: '',
      isSending: false,
    }
  },
  computed: {
    nickname() {
      return this.aliasID ?
        this.$store.state.Auth.aliases.filter(x => x.aliasID == this.aliasID)[0].nickname : ''
    },
    avatar() {
      if (!this.aliasID) {
        return '';
      }
      if(this.$store.state.Auth.aliases !== null || this.$store.state.Auth.aliases.length > 0) {
        return this.$store.state.Auth.aliases.filter(x => x.aliasID == this.aliasID)[0].avatar;
      }
      return ''
    },
    placeholder() {
      const action = this.lotterType == 1 ? '新增留言' : '回覆';
        return this.nickname === '' ? '' : `以${this.nickname}${action}......`;
    },
  },
  methods: {
    setValue(value) {
      this.content = value;
      setItemWithExpiry('content', this.content, 1800);
    },
    overSize() {
      this.$emit('overSize');
    },
    closeReply() {
      this.$emit('closeReply');
    },
    send() {
      if (this.lotterType == 1) {
        this.handleCreateComment();
      } else {
        this.handleCreateRelpy();
      }
      setItemWithExpiry('content', '', 0);
    },
    handleCreateComment() {
      this.isSending = true;
      this.requestCreateComment({
        newsMainBannerId: this.newsMainBannerId,
        aliasId: this.aliasID,
        entityId: this.lotterId,
        content: this.content,
        userMentions: [],
        onSuccess: (res) => {
          this.isSending = false;
          this.$refs.input.clearValue();
          this.$emit('sendCallback');
        },
        onFailed: (err) => {
          this.isSending = false;
        },
      });
    },
    handleCreateRelpy() {
      this.isSending = true;
      this.requestCreateRelpy({
        newsMainBannerId: this.newsMainBannerId,
        aliasId: this.aliasID,
        commentId: this.lotterId,
        content: this.content,
        userMentions: [],
        onSuccess: (res) => {
          this.isSending = false;
          this.$refs.input.clearValue();
          this.addReplyToComment({
            commentId: this.lotterId,
          });
          this.$emit('sendCallback');
          this.$emit('closeReply');
        },
        onFailed: (err) => {
          this.isSending = false;
          this.$emit('closeReply');
        },
      });
    },
    showKeyboard() {
      this.$refs.input.setFocus();
    },
    hideKeyboard() {
      this.$refs.input.setBlur();
    },
  },
  mounted() {
    const storageValue = getItemWithExpiry('content');
    if (storageValue && storageValue != '') {
      this.$refs.input.setValueWithStorage(storageValue);
    }
  },
};
</script>

<style lang="sass" scoped>
.left-item
  width: 38px
  height: 100%

.right-item
  width: calc(100% - 38px - 32px)
  display: flex

.right-item-input-without-send
  width: 100%

.right-item-input
  width: calc(100% - 38px)

.right-item-send
  width: 38px

.headshot
  height: 30px
  width: 30px
  background: url('~@/assets/images/unnamed.png') center no-repeat
  border-radius: 50%
  position: fixed
  bottom: 23px

.send
  width: 30px
  height: 30px
  position: fixed
  bottom: 23px
  right: 12px

.sending
  filter: grayscale(100%)

.replying
  border-top: 0.6px solid #ededed
  background-color: #ffffff
  width: 100%
  height: 40px
  background-color: rgba(255, 255, 255, 0.08)

.replying-txt
  font-family: PingFangTC-Regular
  font-size: 13px
  line-height: 20px
  letter-spacing: 0.4px
  color: #999999
  margin: 0px 16px

.comment-reply
  width: 100%
  min-height: 56px
  padding: 8px 16px

.comment-reply-border-top
  border-top: 0.6px solid #d9d7d7

.close-icon
  margin-right: 16px
</style>

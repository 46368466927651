<template lang="html">
  <div class="barArea" :style="endTimeError == true ? 'border: 1px solid #ed5b4c ; border-radius: 10px' :''">
    <div class="leftArea">
      <div class="barText leftText">結束時間 <span class="redMust">*</span></div>
    </div>
    <div class="rightArea">
      <span v-if="!dateTimeString" class="barText rightText">未設定</span>
      <span v-else class="barText rightText">{{ dateTimeString }}</span>
      <span class="rightArrow">
        <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask8" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="5" width="8" height="14">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.45258 12.5215L3.74501 18.2841C3.53633 18.4948 3.19584 18.4948 2.98715 18.2841L2.70489 17.9991C2.49902 17.7913 2.49902 17.4564 2.70489 17.2485L7.90321 12L2.70489 6.75155C2.49902 6.5437 2.49902 6.20879 2.70489 6.00094L2.98715 5.71595C3.19584 5.50525 3.53633 5.50525 3.74501 5.71595L9.45258 11.4786C9.73781 11.7666 9.73781 12.2335 9.45258 12.5215Z" fill="#333333"/>
          </mask>
          <g mask="url(#mask8)">
            <rect y="2" width="12" height="20" fill="#999999"/>
          </g>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EndTimeCell',
  props: ['dateTimeString', 'endTimeError'],
  watch: {
    dateTimeString: function(newVal, oldVal) {
    },
  },
};
</script>

<style lang="sass" scoped>
.barArea
  width: 100%
  height: 51px
  position: relative

.leftArea
  width: calc(100% - 65px)
  top: 15px
  position: absolute

.rightArea
  width: 220px
  text-align: right
  position: absolute
  top: 15px
  right: 0px

.barText
  font-size: 15px
  line-height: 20px
  letter-spacing: 0.1px

.leftText
  color: rgb(51, 51, 51)

.redMust
  color: #ed5b4c

.rightText
  color: #666666
  margin-right: 4px

.rightArrow
  float: right //解決『圖片高度』比『文字 line-height』高 4px，而造成文字與圖片無法對齊問題
  svg
    margin-top: -2px //解決『圖片高度』比『文字 line-height』高 4px，而造成文字與圖片無法對齊問題
</style>

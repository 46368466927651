<template lang="html">
  <div v-show="isDataLoad">
    <SubjectBanner
     :content="voteResult.title"
     :image="voteResult.imageUri"
     :createdUserName="voteResult.createdUserName"
     :votePeopleCount="voteResult.votePeopleCount"
     :dueDays="voteResult.dueDays"
     :endTime="voteResult.endTime"
    />

    <div class="pageContainer">
      <PageContainer class="PageContainerPosition">
        <!-- 設為公告/取消公告/選單...按鈕 -->
        <SubjectSettingCell v-show="isAdmin" :subjectID="subjectID" :aliasID="this.aliasID" :chatID="this.chatID" 
        :isPinned="voteResult.isPinned" :votePeopleCount="voteResult.votePeopleCount" :isEnded="voteResult.isEnded"/>
        <!-- 建立者模式才有;參與者無 -->
          
        <!-- 說明文字 -->
        <div class="txt">
          {{ voteResult.description }}
        </div>
        <!-- 投票結果-冠軍(一般版) -->
        <div v-if="questionType == 1" v-for="championOption in championOptions">
          <div class="answerRow">
            <div class="txtCheckboxArea">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.4921 30H11.5079C10.0409 30 8.78119 28.9814 8.50783 27.5741L6.03918 14.8649C5.65933 12.9093 8.13248 11.6831 9.52552 13.1363L14.7352 18.571L18.1434 11.1723C18.8635 9.60922 21.1365 9.60922 21.8566 11.1723L25.2648 18.571L30.4745 13.1363C31.8675 11.6831 34.3407 12.9093 33.9608 14.8649L31.4922 27.5741C31.2188 28.9814 29.9591 30 28.4921 30Z" fill="#FFB54C"/>
                <path d="M20.5 20C20.5 19.7239 20.2761 19.5 20 19.5C19.7239 19.5 19.5 19.7239 19.5 20C19.5 20.42 19.3699 21.0611 18.9942 21.5828C18.6369 22.0791 18.037 22.5 17 22.5C16.7239 22.5 16.5 22.7239 16.5 23C16.5 23.2761 16.7239 23.5 17 23.5C17.42 23.5 18.0611 23.6301 18.5828 24.0058C19.0791 24.3631 19.5 24.963 19.5 26C19.5 26.2761 19.7239 26.5 20 26.5C20.2761 26.5 20.5 26.2761 20.5 26C20.5 25.58 20.6301 24.9389 21.0058 24.4172C21.3631 23.9209 21.963 23.5 23 23.5C23.2761 23.5 23.5 23.2761 23.5 23C23.5 22.7239 23.2761 22.5 23 22.5C22.58 22.5 21.9389 22.3699 21.4172 21.9942C20.9209 21.6369 20.5 21.037 20.5 20Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="ResulTextBarArea">
              <ResultTextBarChampion :championOption="championOption"/>
            </div>
          </div>
        </div>
        <!-- 投票結果-冠軍(圖片版) -->
        <div class="championArea" v-else v-for="championOption, index in championOptions">
          <div class="picArea">
            <!-- 皇冠圖 -->
            <svg class="crownImg" width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M61.9675 37.199L37.199 61.9675C35.0596 64.1069 31.737 64.4585 29.2861 62.8048L7.15177 47.8708C3.74595 45.5728 5.56435 40.1779 9.71513 40.2656L25.2382 40.5938L19.4188 24.8338C18.1893 21.5042 21.5042 18.1893 24.8338 19.4188L40.5938 25.2382L40.2656 9.71511C40.1779 5.56433 45.5728 3.74594 47.8708 7.15177L62.8048 29.286C64.4585 31.737 64.1069 35.0596 61.9675 37.199Z" fill="#FFB54C"/>
              <path d="M35.7071 34.293C35.3166 33.9025 34.6834 33.9025 34.2929 34.293C33.9024 34.6835 33.9024 35.3167 34.2929 35.7072C34.9089 36.3232 35.658 37.4536 35.8724 38.7702C36.0766 40.0247 35.8124 41.5235 34.2929 43.043C33.9024 43.4335 33.9024 44.0667 34.2929 44.4572C34.6834 44.8477 35.3166 44.8477 35.7071 44.4572C36.3231 43.8412 37.4535 43.0921 38.77 42.8778C40.0246 42.6735 41.5233 42.9377 43.0429 44.4572C43.4334 44.8477 44.0666 44.8477 44.4571 44.4572C44.8476 44.0667 44.8476 43.4335 44.4571 43.043C43.8411 42.427 43.0919 41.2966 42.8776 39.9801C42.6734 38.7255 42.9375 37.2268 44.4571 35.7072C44.8476 35.3167 44.8476 34.6835 44.4571 34.293C44.0666 33.9025 43.4334 33.9025 43.0429 34.293C42.4269 34.909 41.2965 35.6582 39.9799 35.8725C38.7254 36.0767 37.2266 35.8126 35.7071 34.293Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <!-- 大頭圖片 -->
            <div class="championImg" :style="{ 'background-image': 'url(\'' + championOption.imageUri + '\')' }"></div>
            <!-- 綠色勾 -->
            <svg class="championCheckIcon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="championOption.isVoted">
              <circle cx="19.9997" cy="20" r="14.6667" fill="white"/>
              <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="5" width="30" height="30">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9997 34.6667C28.0999 34.6667 34.6663 28.1002 34.6663 20C34.6663 11.8999 28.0999 5.33337 19.9997 5.33337C11.8995 5.33337 5.33301 11.8999 5.33301 20C5.33301 28.1002 11.8995 34.6667 19.9997 34.6667ZM26.3767 17.5086C26.6892 17.1961 26.6892 16.6896 26.3767 16.3772L25.6225 15.6229C25.3101 15.3105 24.8035 15.3105 24.4911 15.6229L18.6663 21.4478L15.5081 18.2896C15.1957 17.9772 14.6892 17.9772 14.3767 18.2896L13.6225 19.0439C13.3101 19.3563 13.3101 19.8628 13.6225 20.1752L17.5349 24.0877C18.1598 24.7125 19.1728 24.7125 19.7977 24.0877L26.3767 17.5086Z" fill="#26D07C"/>
              </mask>
              <g mask="url(#mask1)">
                <rect width="40" height="40" fill="#26D07C"/>
              </g>
            </svg>
          </div>
          <ResultPictureBarChampion :championOption="championOption" />
          <!-- 分隔線 -->
          <div class="dividerArea">
            <div class="dividerLine"></div>
            <div class="dividerDot"></div>
            <div class="dividerLine"></div>
          </div>
        </div>
        
        <!-- 其他選項 -->
        <ResultAnswerCell :questionType="questionType" :option="option" :maxVotingRate="maxVotingRate" v-for="option in otherOptions" :key="option.optionID" />

        <!-- 已投票 -->
        <div class="votedText" v-if="voteResult.isVoted">已投票</div>

        <!-- 看其他選項 按鈕 -->
        <RoundButtonSeeOthers @click.native="seeOthers" />
        <iOSAlert ref="alert" :message="message" />
      </PageContainer>
    </div>
  </div>
</template>

<script>
import { passEvent } from '@/utils/trackingHelper';
import { loading, auth, getVoteResult } from '@/mixins';
import iOSAlert from '../components/iOSAlert.vue';

//10.2 建立投票
import PageContainer from '../components/PageContainer.vue';

//10.3.1 投票頁面-設為公告/取消公告/選單...按鈕
import SubjectSettingCell from './components/SubjectSettingCell.vue';

//10.3.1 投票頁面-主題Banner
import SubjectBanner from './components/SubjectBanner.vue';

//10.4.1 投票結果頁-冠軍答案比例條
import ResultTextBarChampion from './components/ResultTextBarChampion.vue';

//10.4.1 投票結果頁圖片版-冠軍答案比例條
import ResultPictureBarChampion from './components/ResultPictureBarChampion.vue';

//10.4.1 投票結果頁-答案比例條
import ResultAnswerCell from './components/ResultAnswerCell.vue';

//看其他投票按鈕
import RoundButtonSeeOthers from '../components/RoundButtonSeeOthers.vue';

// Loading
var loader;

export default {
  name: 'VoteResult',
  mixins: [loading, auth, getVoteResult],
  components: {
    iOSAlert,
    PageContainer,
    SubjectSettingCell,
    SubjectBanner,
    ResultTextBarChampion,
    ResultPictureBarChampion,
    ResultAnswerCell,
    RoundButtonSeeOthers,
  },
  data() {
    return {
      isDataLoad: false,
      subjectID: 0,
      message: {
        title: "",
        subtitle: [],
        callbackOk: () => {},
        callbackCancel: () => {},
      },
      NotExitMessage: {
        title: '投票活動不存在',
        subtitle: ['', ''],
        callbackOk: () => {
          this.$router.push({ name: 'voteList' });
        }
      },
    }
  },
  computed: {
    voteResult() {
      return this.$store.state.GetVoteResult;
    },
    questionMode() {
      if (this.$store.state.GetVoteResult &&
          this.$store.state.GetVoteResult.questionList &&
          this.$store.state.GetVoteResult.questionList.length > 0) {
        return this.$store.state.GetVoteResult.questionList[0].mode;
      } else {
        return 2;
      }
    },
    questionType() {
      if (this.$store.state.GetVoteResult &&
          this.$store.state.GetVoteResult.questionList &&
          this.$store.state.GetVoteResult.questionList.length > 0) {
        return this.$store.state.GetVoteResult.questionList[0].type;
      } else {
        return 1;
      }
    },
    maxVotingRate() {
      if (this.$store.state.GetVoteResult &&
          this.$store.state.GetVoteResult.questionList &&
          this.$store.state.GetVoteResult.questionList.length > 0) {
        const optionList = this.$store.state.GetVoteResult.questionList[0].optionList;
        const maxVotingRate = Math.max.apply(null, optionList.map(function(option) {
          return option.votingRate;
        }));
        return maxVotingRate;
      } else {
        return 0;
      }
    },
    championOptions() {
      if (this.$store.state.GetVoteResult &&
          this.$store.state.GetVoteResult.questionList &&
          this.$store.state.GetVoteResult.questionList.length > 0) {
        //return this.$store.state.GetVoteResult.questionList[0].optionList
        //       .reduce((a,b) => Number(a.votingRate) > Number(b.votingRate) ? a : b);
        const optionList = this.$store.state.GetVoteResult.questionList[0].optionList;
        if (this.maxVotingRate == 0) return [];
        const options = optionList.filter(option => {
          return option.votingRate == this.maxVotingRate;
        });
        if (this.questionType == 1) {
          return options;
        } else {
          return [options[0]];
        }
      } else {
        return [];
      }
    },
    otherOptions() {
      if (this.$store.state.GetVoteResult &&
          this.$store.state.GetVoteResult.questionList &&
          this.$store.state.GetVoteResult.questionList.length > 0) {
        return this.$store.state.GetVoteResult.questionList[0].optionList
               .filter(option => this.championOptions.indexOf(option) < 0);
      }
    },
    isAdmin() {
      return this.$store.state.Auth.isAdmin;
    }
  },
  methods: {
    // 取得 VoteData
    handleGetVoteResult() {
      this.requestGetVoteResult({
        aliasID: this.aliasID,
        subjectID: this.subjectID,
        onSuccess: (res) => {
          this.isDataLoad = true;
          this.hideLoading(loader);
          const eventItems = [
            {
              type: 'pageViewEvent',
              eventId: '1045',
              event: 'poll_page_view',
              pageInfo: {
                page: 'poll',
                type: this.questionMode === 1 ? 'poll_pk' : 'poll',
                uuid: this.subjectID,
                name: this.voteResult.title,
                amount: this.voteResult.votePeopleCount,
                source: 'chatroom',
                cat0: [],
                cat1: [],
              },
              isAdditiveView: false,
            },
          ];
          passEvent(eventItems);

        },
        onFailed: (err) => {
          this.hideLoading(loader);
          if (err.response.data.error.message.indexOf("Data Already Deleted") > -1) {
            this.isDataLoad = true;
            this.message = this.NotExitMessage;
            this.$refs.alert.showPopup();
          }
        },
      });
    },
    seeOthers() {
      const eventItems = [
        {
          type: 'clickEvent',
          eventId: '1046',
          event: 'poll_item_click',
          pageInfo: {
            page: 'poll',
            type: this.questionMode === 1 ? 'poll_pk' : 'poll',
            uuid: this.subjectID,
            name: this.voteResult.title,
            amount: this.voteResult.votePeopleCount,
            source: 'chatroom',
            cat0: [],
            cat1: [],
            status: this.voteResult.isVoted ? 'polled' : 'open',
          },
          clickInfo: {
            name: 'other_polls',
          },
        },
      ];
      passEvent(eventItems);

      this.goVoteList();
    },
    goVoteList() {
      this.$router.push({ name: 'voteList' })
    },
  },
  mounted() {
    // 顯示 Loading
    loader = this.showLoading();

    this.subjectID = this.$route.query.subjectid;

    // 同時取得『使用者 AccessToken > OpenId』+『ChatID』
    this.getTokenAndAliasidAndChatid((res) => {
      // 是否有管理者權限
      this.getIsAdmin({
        chatID: this.chatID,
        aliasID: this.aliasID,
        onSuccess: (res) => {
        },
        onFailed: (err) => {
        },
      });

      // 取得 VoteData
      this.handleGetVoteResult();
    });
  },
  beforeDestroy() {
    this.hideLoading(loader);
    this.isLoading = false;
  },
};
</script>

<style lang="sass" scoped>
  .txtCheckboxArea
    display: flex
    margin-right: 5px
    width: 48px
    height: 48px
    align-items: center
    justify-content: center

  .ResulTextBarArea
    display: inline-block
    width: calc(100% - 72px)

  .answerRow
    display: flex
    align-items: center
    margin-bottom: 30px

  .PageContainerPosition
    z-index: 2
    top: 150px
    position: absolute
    overflow-x: hidden
    @include media(xs) // RWD sass max-width: 320px
      top: 160px
      border-radius: 11px 11px 0px 0px

  .championArea
    margin-bottom: 40px

  .championImg
    width: 200px
    height: 200px
    border-radius: 100px
    background-color: #eee
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    //background-image: 串資料撈圖片當背景

  .picArea
    position: relative
    width: 200px
    height: 200px
    margin: 0px auto 20px auto

  .crownImg
    position: absolute
    top: -18px
    left: -5px

  .txt
    margin: 12px 40px 20px 12px
    color: #333333

  .votedText
    text-align: center
    color: #cccccc
    font-size: 15px
    margin-bottom: 16px

  .dividerArea
    display: flex
    justify-content: center
    margin-top: 12px

  .dividerLine
    width: 48px
    border-top: solid 0.5px #dddddd
    display: inline-block
    margin-top: 1.4px

  .dividerDot
    width: 4px
    height: 4px
    background-color: #dddddd
    border-radius: 2px
    display: inline-block
    margin: 0px 8px

  .championCheckIcon
    position: absolute
    bottom: 8px
    right: 8px
</style>

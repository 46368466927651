<template lang="html">
  <div class="textBarArea">
    <div class="resultText">{{ championOption.title }}</div>
    <progress-bar :options="options" :value="championOption.votingRate" style="width:100%"/>
    <div class="percentNumArea">{{ championOption.votingRate }}<span class="percentMark">%</span></div>
  </div>
</template>

<script>
import ProgressBar from 'vuejs-progress-bar'

export default {
  name: 'ResultPictureBarChampion',
  components: {
    ProgressBar,
  },
  props: ['championOption'],
  watch: {
    championOption: function(newVal, oldVal) {

    },
  },
  data() {
    return {
      // ProgressBar 使用-樣式
      options: {
        text:{
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: true
        },
        progress: {
          color: 'rgb(38,208,124)',
          backgroundColor: 'rgb(238,238,238)',
          inverted: false
        },
        layout: {
          height: 8,
          //width: 220 ,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 25,
          progressPadding: 0,
          type: 'line',
        }
      },
    }
  },
  mounted: function(){
    var svg = this.$el.querySelectorAll('.vuejs-progress-bar svg');
    for (var i = 0; i <= svg.length - 1; i++) {
      svg[i].style['width'] = '100%';
      svg[i].style['border-radius'] = '4px';
    }
    // 進度 Bar 動畫 (svg 的 line animation)
    var line = this.$el.querySelectorAll('.vuejs-progress-bar line');
    //line[1].style['animation'] = 'offset 2s linear forwards';
    line[1].innerHTML = '<animate attributeName="x2" \
                         from="0" to="' + this.championOption.votingRate + '%" dur="1s"></animate>';
  }
};
</script>

<style lang="sass" scoped>
  .textBarArea
    width: calc(100% - 132px)
    margin: 0px auto

  .percentNumArea
    width: calc(100% - 10px)
    height: 32px
    text-align: center
    line-height: 32px
    font-size: 24px
    color: rgb(51,51,51)
    margin: 0 auto
    margin-top: 8px
    margin-left: 10px

  .percentMark
    font-size: 15px

  .resultText
    color: #333333
    font-size: 20px
    line-height: 28px
    letter-spacing: 0.15px
    margin-bottom: 8px

  .progressBar
    margin-bottom: 8px
</style>

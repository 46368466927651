<template lang="html">
  <div>
    <UnsavedChangesDialog
      :title="message.title"
      :subtitle="message.subtitle"
      :show="shouldShowDialog"
      @cancel="closePopup"
      @discard="discard"
      @save="save" />
  </div>
</template>

<script>
import UnsavedChangesDialog from 'vue-unsaved-changes-dialog';

export default {
  name: 'iOSAlert',
  props: ['message'],
  data() {
    return {
      shouldShowDialog: false,
    }
  },
  methods: {
    showPopup() {
      this.shouldShowDialog = true;
      // 偵測當 DOM 物件異動時，執行指定事件 [此處為原生 javascript 寫法]
      /*
      window.addEventListener('DOMNodeInserted', function() {
        var h1 = document.getElementsByTagName('h1');
        for (var i = 0; i <= h1.length - 1; i++) {
          h1[i].style['color'] = '#000';
          h1[i].style['font-size'] = '17px';
        }
      }, false);
      */
    },
    closePopup() {
      this.shouldShowDialog = false;
    },
    async save() {
      // 原套件 Code, 將被 updated() 覆蓋
      try {
        await this.saveChangesToServer();
        this.exit();
      } catch(e) {
      }
    },
    async saveChangesToServer() {
      // 原套件 Code, 將被 updated() 覆蓋
    },
    discard() {
      // 原套件 Code, 將被 updated() 覆蓋
      this.exit();
    },
    exit() {
      // 原套件 Code, 將被 updated() 覆蓋
      this.closePopup();
    },
  },
  components: {
    UnsavedChangesDialog,
  },
  mounted: function(){
    // 這邊能寫真正的 javascript
    // alert('mounted');
  },
  updated: function () {
    // 當 DOM 被 re-rendered 時，會進入此函式
    this.$nextTick(function () {
      var self = this;

      //#region 大標題
      var h1 = document.querySelectorAll('.mobile-dialog--wrapper h1');
      for (var i = 0; i <= h1.length - 1; i++) {
        h1[i].style['color'] = '#000';
        h1[i].style['font-size'] = '17px';
        h1[i].style['font-weight'] = 'bold';
      }
      //#endregion

      //#region 小標題
      var p = document.querySelectorAll('.mobile-dialog--wrapper p');
      for (var i = 0; i <= p.length - 1; i++) {
        p[i].style['color'] = '#000';
        p[i].style['font-size'] = '13px';
      }
      //#endregion

      //#region 調整 text-section 的 padding
      var text = document.querySelectorAll('.mobile-dialog--wrapper .text-section');
      for (var i = 0; i <= text.length - 1; i++) {
        text[i].style['padding-top'] = '2px';
        text[i].style['padding-bottom'] = '16px';
      }
      //#endregion

      //#region 將套件的 button-row 改成 flex-direction: row
      var divButtonRow = document.querySelectorAll('.mobile-dialog--wrapper .button-row');
      for (var i = 0; i <= divButtonRow.length - 1; i++) {
        divButtonRow[i].style['flex-direction'] = 'row-reverse';
      }
      //#endregion

      //#region 按鈕-取消 (原本是 Discard)
      var callbackCancel = this.message.callbackCancel;
      var btnDiscard = document.querySelectorAll('.mobile-dialog--wrapper .button-danger');
      for (var i = 0; i <= btnDiscard.length - 1; i++) {
        btnDiscard[i].style['color'] = 'rgba(0, 122, 255, 1)';
        btnDiscard[i].style['font-size'] = '17px';
        btnDiscard[i].style['font-weight'] = '400';
        btnDiscard[i].innerHTML = '取消';

        //#region 變更原本的 :active 樣式
        //Tap 壓下
        btnDiscard[i].addEventListener("touchstart", function(event) {
          event.preventDefault();
          this.style['color'] = 'rgba(0, 122, 255, 0.5)';
          this.style['background-color'] = 'rgba(189, 195, 199, 0.5)';
        }, false);

        //Tap 放開
        btnDiscard[i].addEventListener("touchend", function(event) {
          event.preventDefault();
          this.style['color'] = 'rgba(0, 122, 255, 1)';
          callbackCancel();
          self.exit();
        }, false);
        //#endregion
      }
      //#endregion

      //#region 按鈕-確定
      var callbackOk = this.message.callbackOk;
      var btnOK = document.querySelectorAll('.mobile-dialog--wrapper .button-success');
      for (var i = 0; i <= btnOK.length - 1; i++) {
        btnOK[i].style['border-left'] = '1px solid #edeeef';
        btnOK[i].style['border-bottom-right-radius'] = '14px';
        btnOK[i].style['color'] = 'rgba(0, 122, 255, 1)';
        btnOK[i].style['font-size'] = '17px';
        btnOK[i].style['font-weight'] = '400';
        btnOK[i].innerHTML = '確定';

        //#region 變更原本的 :active 樣式
        //Tap 壓下
        btnOK[i].addEventListener("touchstart", function(event) {
          event.preventDefault();
          this.style['color'] = 'rgba(0, 122, 255, 0.5)';
          this.style['background-color'] = 'rgba(189, 195, 199, 0.5)';
        }, false);

        //Tap 放開
        btnOK[i].addEventListener("touchend", function(event) {
          event.preventDefault();
          this.style['color'] = 'rgba(0, 122, 255, 1)';
          callbackOk();
          self.exit();
        }, false);
        //#endregion
      }
      //#endregion

      //#region 按鈕-取消 (原本是 Cancel 要消失)
      var btnCancel = document.querySelectorAll('.mobile-dialog--cancel-button');
      for (var i = 0; i <= btnCancel.length - 1; i++) {
        btnCancel[i].style['display'] = 'none';
      }
      //#endregion

      //#region 當沒傳入 this.message.callbackCancel 時，顯示單顆按鈕的 alert 視窗
      if (!callbackCancel) {
        btnDiscard[0].style['display'] = 'none';
        btnOK[0].style['border-bottom-left-radius'] = '14px';
      }
      //#endregion

      //#region 調整 mobile-dialog--wrapper 樣式
      var divWrapper = document.querySelectorAll('.mobile-dialog--wrapper');
      divWrapper[0].style['width'] = '270px';
      divWrapper[0].style['border-radius'] = '14px';
      //#endregion
    })
  }
};
</script>

<style lang="sass" scoped>
// 這邊設定樣式無效，因為此處 DOM 物件是動態生成的，這邊設定會抓不到
// 要由 javascript 去調整樣式
</style>
<template lang="html">
  <div class="barArea">
    <div class="leftArea">
      <div class="barText leftText">大圖模式</div>
    </div>
    <div class="rightArea">
      <!-- 用 click 會跑兩次，因為 click 包含 touchstart、touchend -->
      <div class="switchBtnArea">
        <SwitchButton :isOpen="isPicture" @switched="switchedCallback" />
      </div>
    </div>
  </div>
</template>

<script>
import SwitchButton from '../../components/SwitchButton.vue';

export default {
  name: 'PictureOrTextSwitchCell',
  components: {
    SwitchButton,
  },
  props: ['questionType'],
  watch: {
    questionType: function(newVal, oldVal) {
      this.isPicture =  this.questionType == 2;
    }
  },
  data() {
    return {
      isPicture: this.questionType == 2, // 是否為大圖模式 (1:一般模式 / 2:大圖模式)
    }
  },
  methods: {
    switchedCallback(isOpen) {
      // 切換『文字模式』/『大圖模式』
      this.isPicture = isOpen;
      this.$emit('parentSwitch', this.isPicture);
    }
  },
};
</script>

<style lang="sass" scoped>
.barArea
  width: 100%
  height: 51px
  position: relative

.leftArea
  width: calc(100% - 65px)
  display: inline-block
  position: absolute
  top: 15px

.rightArea
  width: 60px
  display: inline-block
  position: absolute
  top: 9px
  right: 0px

.barText
  font-size: 15px
  line-height: 20px
  letter-spacing: 0.1px

.leftText
  color: rgb(51, 51, 51)

.switchBtnArea
  width: 52px
</style>

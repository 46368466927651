<template lang="html">
    <!-- 彈窗在螢幕置中，可帶入 text 和 image 兩種類型 -->
    <div class="container" :style="`opacity: ${isShowDialog ? '1' : '0'};`"
      >
      <div v-show="text" class="text" ref="emojiText">
        <span style="padding: 5px 13px;">{{ text }}</span>
      </div>
      <div v-if="imageSrc" ref="emojiImage">
        <img :src='imageSrc' width="90px" height="90px">
      </div>
    </div>
</template>

<script>
export default {
  name: 'CustomDialog',
  props: ['isDisplayDialog', 'text', 'imageSrc'],
  computed: {
    isShowDialog: {
      get() {
        return this.isDisplayDialog
      },
      set(val) {
        this.$emit('update:isDisplayDialog', val)
      }
    }
  },
  watch: {
    isDisplayDialog(val) {
      if(val) {
        if(this.$refs.emojiImage.classList.contains('container-fade-out')) {
          this.$refs.emojiImage.classList.remove('container-fade-out')
          this.$refs.emojiText.classList.remove('container-fade-out')
        }
        this.$refs.emojiImage.classList.add('container-fade-in')
        this.$refs.emojiText.classList.add('container-fade-in-for-text')
        setTimeout(() => {
          this.$refs.emojiImage.classList.remove('container-fade-in')
          this.$refs.emojiText.classList.remove('container-fade-in-for-text')
          this.$refs.emojiImage.classList.add('container-fade-out')
          this.$refs.emojiText.classList.add('container-fade-out')
        }, 500)
        setTimeout(() => {
          this.isShowDialog = false
        }, 1000)
      }
    }
  }
};
</script>

<style scoped>
@keyframes fadeOut {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
    transform: scale(0.5);
  }
  20% {
    opacity: 0.3;
    transform: scale(1);
  }
  30% {
    opacity: 0.5;
    transform: scale(1.2);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInText {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.5;
  }
}


.container {
  display: flex;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: calc(50% - 135px);
  left: calc(50% - 45px);
  z-index: 1;
}

.container-fade-in {
  animation: fadeIn 500ms linear;
}

.container-fade-in-for-text {
  animation: fadeInText 500ms linear;
}

.container-fade-out {
  animation: fadeOut 700ms linear;
}

.text {
  background: #000000;
  opacity: 0.6;
  border-radius: 23px;
  color: white;
  text-align: center;
  line-height: 2;
  height: 32px;
  font-size: 16px;
  margin-bottom: 10px;
}
</style>

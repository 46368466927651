export default {
  methods: {
    requestGetIsVoted({
      aliasID,
      subjectID,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('GET_IS_VOTED', {
        aliasID,
        subjectID,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
  },
};

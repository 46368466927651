<template lang="html">
  <div class="SubCellContainer">
    <div class="textArea titleArea" :style="subjectError == true ?'border: 1px solid #ed5b4c':''">
      <textarea-autosize
        class="title"
        maxlength="15"
        rows="1"
        placeholder="請輸入投票標題吧"
        ref="myTextarea"
        :min-height="32"
        :max-height="100"
        @blur.native="getSubjectContent"
        v-model.trim="inputSubjectContent"
      />
    </div>
    <div class="textArea contentArea">
      <textarea-autosize
        class="content"
        maxlength="28"
        rows="1"
        placeholder="投票說明"
        ref="myTextarea"
        :min-height="20"
        :max-height="120"
        @blur.native="getSubjectDescription"
        v-model.trim="inputSubjectDescription"
      />
    </div>
  </div>
</template>

<script>
// vue-textarea-autosize
import Vue from 'vue';
import TextareaAutosize from 'vue-textarea-autosize';

export default {
  name: 'SubjectCell',
  props: ['title', 'description', 'subjectCheck'],
  watch: {
    title: function(){
      this.inputSubjectContent = this.title;
    },
    description: function(){
      this.inputSubjectDescription = this.description;
    },
    subjectCheck: function(){
      if(this.subjectCheck == true){
        this.checkError();
      }
    }
  },
  data() {
    return{
      inputSubjectContent: this.title,
      inputSubjectDescription: this.description,
      subjectError: false,
    }
  },
  methods: {
    getSubjectContent(){
      this.checkError();
      this.$emit('getSubjectContent',this.inputSubjectContent)
    },
    getSubjectDescription(){
      this.$emit('getSubjectDescription',this.inputSubjectDescription)
    },
    getSubjectError(){
      this.$emit('getSubjectError',this.subjectError)
    },
    checkError() {
      if(this.inputSubjectContent == ''){
        this.subjectError = true;
      }else{
        this.subjectError = false;
      }
      this.getSubjectError();
    }
  },
};
Vue.use(TextareaAutosize);
</script>

<style lang="sass" scoped>
.SubCellContainer
  width: 100%
  position: relative

.textArea
  border: solid 0.5px rgb(221, 221, 221)
  border-radius: 10px
  width: auto // 避免被 global.sass 設定的 .textArea 影響

.titleArea
  padding: 18.7px 16px
  margin-bottom: 12px
  background-color: rgb(255, 255, 255)

.contentArea
  padding: 15px 16px
  background-color: rgb(255, 255, 255)

.title
  width: calc(100% - 5px)
  height: 32.7px
  font-size: 20px
  line-height: 28px
  border: none
  resize: none
  color: rgb(51, 51, 51)

.content
  width: 100%
  font-size: 15px
  line-height: 20px
  letter-spacing: 0.25px
  border: none
  resize: none
  color: rgb(51, 51, 51)

.vueTextarea
  width: 100%

\::placeholder // Chrome, Firefox, Opera, Safari 10.1+
  color: rgb(221, 221, 221)
  opacity: 1 // Firefox
</style>

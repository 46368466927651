<template lang="html">
  <div v-show="isDataLoad">
    <SubjectBanner
     :content="voteData.title"
     :image="voteData.imageUri"
     :createdUserName="voteData.createdUserName"
     :votePeopleCount="voteData.votePeopleCount"
     :dueDays="voteData.dueDays"
     :endTime="voteData.endTime"
    />
    <div>
      <PageContainer class="PageContainerPosition">
        <!-- 避免使用者在滑動 carousel 選項時，不小心上下滑動觸發頁面重整 -->  
        <div class="avoidRefresh"
         @touchstart="handleTouchStart"
         @touchmove="handleTouchMove"
         @touchend="handleTouchEnd">
          <!-- 說明文字 -->
          <div class="txt">
            {{ voteData.description }}
          </div>
  
          <!-- 文字模式  -->
          <!-- 判斷單選/複選 -->
          <!-- ＰＫ模式 只有兩個選項; 一般模式 >= 2 -->
          <OptionCell v-if="questionType == 1" :option="option" v-for="option in optionList"
            :key="option.optionID"
            @checkedCallback="optionCellChecked"
            style="width:calc(100% - 40px);"
          />
  
          <!-- 圖片模式 slicker -->
          <!-- ＰＫ模式 只有兩個選項; 一般模式 >= 2 -->
        <!-- swiper 套件 -->
        <swiper v-if="questionType == 2" :options="swiperOption">
          <swiper-slide class="sss" v-for="option in optionList" :key="option.optionID" 
          style="width: 260px; height: 350px" >
            <div style="height: 260px; width:260px" class="label picSquare mr_24" :class="(checkedOptionIds.indexOf(option.optionID) > -1) ? 'Select' : 'UnSelect'">
              <div class="picArea" :style="{ 'background-image': 'url(\'' + option.imageUri + '\')' }"></div>
              <OptionCell :option="option" @checkedCallback="optionCellChecked" style="border: 0px solid #ccc;height:80px;margin"/>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
  
          <!-- 撐高的div -->
          <div class="blankDiv"></div>
        </div>

        <!--  判斷是否已投票  -->
        <!-- 下好離手 btnActive 綠色/btnDisabled 灰色 #dddddd -->
        <RoundButtonActive :isActive="checkedOptionIds.length > 0" @click.native="checkedOptionIds.length > 0 ? handleVote() : null" />
  
        <div class="flex align-items" style="justify-content: space-between">
          <!-- 留言筆數 -->
          <CommentCount :count="commentCount" />
  
          <!-- 熱門/最新-->
          <div class="comment-menu flex align-items justify-end">
            <div :class="['comment-menu-text', commentSort == 1 ? 'comment-menu-text-active' : '']" @click="switchSort(1)">熱門</div>
            <div class="line"></div>
            <div :class="['comment-menu-text', commentSort == 2 ? 'comment-menu-text-active' : '']" @click="switchSort(2)">最新</div>
          </div>
        </div>
  
        <!-- 新增留言 -->
        <div class="comment-reply flex align-items comment-reply-border-top" @click="goCommentList('Y')">
          <div class="left-item">
            <div class="headshot" :style="{ 'background-image': (avatar != '') ? 'url(\'' + avatar + '\')' : '', 'background-size': 'cover' }"></div>
          </div>
          <div class="right-item">
            <div class="right-item-input comment">新增留言......</div>
          </div>
        </div>
  
        <!-- 一則留言 comment-mr -->
        <Comment
          class="mb_40"
          fromPage="Vote"
          :newsMainBannerId="newsMainBannerId"
          :subjectID="subjectID"
          :aliasID="aliasID"
          v-for="comment in commentList"
          :comment="comment"
          :key="comment.comment.commentId"
          :myReaction="comment.myReaction"
          :pageInfoData="commentPageInfoData"
          @sendReply="sendReply"
          @showReportComment="showReportComment"
          @deleteFailed="deleteFailed" 
        />
  
        <div v-if="commentCount > 5" class="more-comment" @click="goCommentList('N')">查看更多留言</div>
        <div v-else class="no-more-comment"></div>
  
        <iOSAlert ref="alert" :message="message" />
      </PageContainer>

      <!-- 留言功能列 -->
      <CommentToolbar
        id="voteEmojiBLock"
        class="bar"
        :newsMainBannerId="newsMainBannerId"
        :subjectID="subjectID"
        :count="commentCount"
        :reactionCount="reactionCount"
        :isClickedReaction="isClickedReaction"
        :longPressEmojiBlockName="'voteEmojiBLock'"
        :pageInfoData="pageInfoData"
        :saveMyReactionType="saveMyReactionType"
        @sendMessage="sendMessage"
        @sendDataToApps="sendDataToApps"
        @clickReaction="clickReaction"
        @updateDisplayMyReaction="updateDisplayMyReaction"
        @showDialogEmoji="showDialogEmoji"
        @getEmojiTouching="getEmojiTouching"
      />
      
      <!-- 檢舉留言 -->
      <div v-if="showReport" class="mask"></div>
      <ReportComment
        v-if="showReport"
        class="report-comment"
        :newsMainBannerId="newsMainBannerId"
        :aliasID="aliasID"
        :lotterType="lotterType"
        :lotterId="lotterId"
        :reportCommentId="reportCommentId"
        @hideReportComment="hideReportComment"
        @operationSucess="operationSucess" 
        @operationFailed="operationFailed"
      />
    </div>

    <!-- 長按五種表情後的彈窗 -->
    <CustomDialog
      :isDisplayDialog.sync="isDisplayDialog"
      :text="displayTouchMoveText"
      :imageSrc="require(`@/assets/images/emoji/${displayTouchMoveImage}.png`)"
    />

    <!-- 五種表情符號 -->
    <LongPressEmojiBlock 
      id="voteEmojiBLock"
      :pageInfoData="pageInfoData"
      :saveMyReactionType="saveMyReactionType"
      :emojiTouching="emojiTouching"
      zoomInDirection="up"
      @clickReaction="clickReaction"
      @updateDisplayMyReaction="updateDisplayMyReaction"
    />

    <toast ref="toast" />
  </div>
</template>

<script>
import { getOSType } from '@/utils/navigatorHelper';
import {
  generateInsideSharingParams,
  generateOutsideSharingParamsPromise,
} from '@/utils/shareHelper';
import { passEvent } from '@/utils/trackingHelper';
import { Vote, VoteQuestion } from '@/store/entity/request';
import { loading, auth, getIsVoted, getVoteResult, vote, comment } from '@/mixins';
import iOSAlert from '../../components/iOSAlert.vue';
import CustomDialog from '../../components/shared/CustomDialog.vue';

// swiper
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import "swiper/css/swiper.css";

// slicker local
import { Carousel, Slide } from 'vue-carousel';

import PageContainer from '../../components/PageContainer.vue';

// 10.3.1 投票頁面-主題Banner
import SubjectBanner from '../components/SubjectBanner.vue';

// 10.3.1 投票頁面-設為公告/取消公告/選單...按鈕
import SubjectSettingCell from '../components/SubjectSettingCell.vue';

// 10.3.1 投票頁面單一選項-大圖模式(無框)/純文字模式(有框) (共用一條)
import OptionCell from '../components/OptionCell.vue';

// 圓角長按鈕 Active/Disabled 兩種狀態做一版，然後用狀態換樣式
import RoundButtonActive from '../../components/RoundButtonActive.vue';

// 留言筆數
import CommentCount from '../components/CommentCount.vue';

// 留言列表頁的『一列』
import Comment from '../components/Comment.vue';

// 留言工具列
import CommentToolbar from '../components/CommentToolbar.vue';

// 檢舉畫面
import ReportComment from '../components/ReportComment.vue';

// Toast
import Toast from '../../components/Toast.vue';

import LongPressEmojiBlock from '../../components/LongPressEmojiBlock.vue';

// Loading
var loader;

export default {
  name: 'Vote',
  mixins: [loading, auth, getIsVoted, getVoteResult, vote, comment],
  components: {
    iOSAlert,
    Slide,
    Carousel,
    PageContainer,
    SubjectBanner,
    SubjectSettingCell,
    OptionCell,
    RoundButtonActive,
    CommentCount,
    Comment,
    CommentToolbar,
    ReportComment,
    Toast,
    LongPressEmojiBlock,
    Swiper,
    SwiperSlide,
    CustomDialog
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        }
      },
      newsMainBannerId: 0,
      isDataLoad: false,
      subjectID: 0,
      optionList: [],
      checkedOptionIds: [],
      carouselY: 0, // 避免滑動 carousel 時，不小心觸發向下拉事件，造成瀏覽器重整
      message: {
        title: "",
        subtitle: [],
        callbackOk: () => {},
        callbackCancel: () => {},
      },
      alreadyEditMessage: {
        title: '此投票已被編輯過，請於確定後再試一次。',
        subtitle: ['', ''],
        callbackOk: () => {
        },
      },
      NotExitMessage: {
        title: '投票活動不存在',
        subtitle: ['', ''],
        callbackOk: () => {
          this.goVoteList();
        }
      },
      commentCount: 0,
      reactionCount: 0,
      showReport: false,
      lotterType: 1, // 1:留言; 2:回覆
      lotterId: '',  // subjectID(留言) 或 commentId(回覆)
      reportCommentId: '',
      isClickedReaction: false,
      saveMyReactionType: 0,

      // 表情符號框彈出後，滑動到表情符號要放大縮小的參數
      isDisplayDialog: false,
      displayTouchMoveText: '',
      displayTouchMoveImage: 'imgImEmojiLike',
      emojiTouching: false,
    }
  },
    mounted() {
    // 顯示 Loading
    loader = this.showLoading();

    this.subjectID = this.$route.query.subjectid;

    // 取得 newsMainBannerId
    this.newsMainBannerId = this.$route.query.newsMainBannerId;

    // 關閉長按五種表情符號
    document.addEventListener('click', this.closeLongPress ,true);
    document.addEventListener('scroll', this.closeLongPress ,true);

    // 取得『使用者 Access Token』+『使用者 Open Id』+『使用者 Alias Id』
    this.getTokenAndOpenidAndAliasid((res) => {
      // 取得 VoteData
      this.handleGetVoteData();

      setTimeout(() => {
        this.handleGetCommentCount();
        this.handleGetLikeCount();
        this.switchSort(this.commentSort, true);
      }, 500);
    });
  },
  computed: {
    voteData() {
      return this.$store.state.GetVoteResult;
    },
    questionMode() {
      if (this.$store.state.GetVoteResult &&
          this.$store.state.GetVoteResult.questionList &&
          this.$store.state.GetVoteResult.questionList.length > 0) {
        return this.$store.state.GetVoteResult.questionList[0].mode;
      } else {
        return 2; // 1:PK, 2:一般
      }
    },
    questionType() {
      if (this.$store.state.GetVoteResult &&
          this.$store.state.GetVoteResult.questionList &&
          this.$store.state.GetVoteResult.questionList.length > 0) {
        return this.$store.state.GetVoteResult.questionList[0].type;
      } else {
        return 1; //1文字 2梗圖模式
      }
    },
    isMultipleVote() {
      if (this.$store.state.GetVoteResult &&
          this.$store.state.GetVoteResult.questionList &&
          this.$store.state.GetVoteResult.questionList.length > 0) {
        return this.$store.state.GetVoteResult.questionList[0].isMultipleVote;
      } else {
        return false;
      }
    },
    nickname() {
      return this.aliasID ?
        this.$store.state.Auth.aliases.filter(x => x.aliasID == this.aliasID)[0].nickname : ''
    },
    avatar() {
      return this.aliasID ?
        this.$store.state.Auth.aliases.filter(x => x.aliasID == this.aliasID)[0].avatar : ''
    },
    placeholder() {
      return this.nickname == '' ? '' : `以${this.nickname}新增留言......`;
    },
    commentSort() {
      return this.$store.state.Comment.sort;
    },
    commentList() {
      if (this.$store.state.Comment && this.$store.state.Comment.list) {
        return this.$store.state.Comment.list;
      } else {
        return [];
      }
    },
    cat0() {
      let value = '';
      switch (parseInt(this.newsMainBannerId, 10)) {
        case 1:
          value = '電玩星';
          break;
        case 2:
          value = '放鬆星';
          break;
        case 3:
          value = '時事星';
          break;
      }
      return value;
    },
    utmMedium() {
      let value = '';
      switch (parseInt(this.newsMainBannerId, 10)) {
        case 1:
          value = 'news_game';
          break;
        case 2:
          value = 'news_fun';
          break;
        case 3:
          value = 'news_hot';
          break;
      }
      return value;
    },
    pageInfoData() {
      return {
        page: 'poll',
        type: this.questionMode === 1 ? 'poll_pk' : 'poll',
        uuid: this.subjectID,
        name: this.voteData.title,
        amount: this.voteData.votePeopleCount,
        source: 'news',
        status: this.voteData.isVoted ? 'polled' : 'open',
      }
    },
    commentPageInfoData() {
      return {
        page: 'poll',
        type: 'poll',
        uuid: this.subjectID
      }
    }
  },
  methods: {
    closeLongPress() {
      const voteEmo = document.getElementById('voteEmojiBLock')
      if(voteEmo && voteEmo.style.display !== 'none') {
        voteEmo.classList.add('fade-out')
        setTimeout(() => {
          voteEmo.classList.remove('fade-out')
          voteEmo.style.display = 'none'
          voteEmo.style.zIndex = 3
        }, 100)
      }
    },
    getEmojiTouching(isInTouching) {
      this.emojiTouching = isInTouching
    },
    showDialogEmoji({ isDisplay, image, text }) {
      this.isDisplayDialog = isDisplay
      this.displayTouchMoveImage = image
      this.displayTouchMoveText = text
    },
    clickReaction(reactionType) {
      const payload = {
        aliasId: this.aliasID,
        entityType: 'vote',
        type: reactionType
      }

      if(this.isClickedReaction && reactionType === 0) {
        this.isClickedReaction = false
        this.reactionCount -= 1
        this.saveMyReactionType = 0
      } else if(!this.isClickedReaction) {
        this.isClickedReaction = true
        this.reactionCount += 1
        this.saveMyReactionType = reactionType
      }
      this.requestVoteClickReaction({
        entityId: this.subjectID,
        body: payload
      })
    },
    updateDisplayMyReaction(reactionType) {
      const composeReactionType = {
        1: "讚",
        3: "愛心",
        4: "大笑",
        5: "生氣",
        6: "驚訝"
      };

      const composeEmoji = [
        { id: 1, imageName: 'imgImEmojiLike', text: '讚' },
        { id: 3, imageName: 'imgImEmojiHeart', text: '愛心' },
        { id: 4, imageName: 'imgImEmojiLaugh', text: '大笑' },
        { id: 5, imageName: 'imgImEmojiAngry', text: '生氣' },
        { id: 6, imageName: 'imgImEmojiWow', text: '驚訝' },
      ]

      this.displayMyReaction = composeReactionType[reactionType] || '讚'
      const displayId = composeEmoji.findIndex(c => c.id === reactionType)
      if(this.saveMyReactionType === 0 && reactionType > 0) { // 無按過
        this.myReactionTextStyle = 'color: #000000'
        this.showDialogEmoji({
          isDisplay: true,
          image: composeEmoji[displayId].imageName,
          text: composeEmoji[displayId].text,
        })
      } else if (this.saveMyReactionType === reactionType) { // 點相同 === 取消
        this.myReactionTextStyle = ''
        this.displayMyReaction =  '讚'
      } else if (reactionType === 0) { // 取消
        this.myReactionTextStyle = ''
      } else if (reactionType !== 0) { // 重複按
        this.showDialogEmoji({
          isDisplay: true,
          image: composeEmoji[displayId].imageName,
          text: composeEmoji[displayId].text,
        })
      }
      this.saveMyReactionType = reactionType
    },
    handleGetIsVoted() {
      this.requestGetIsVoted({
        aliasID: this.aliasID,
        subjectID: this.subjectID,
        onSuccess: (res) => {
          if (this.$store.state.GetIsVoted.isVoted) {
            this.$router.push({
              name: 'newsVoteResult',
              query: {
                'subjectid': this.subjectID,
                'newsMainBannerId': this.newsMainBannerId
              }
            });
          }
        },
        onFailed: (err) => {

        },
      });
    },
    handleGetVoteData() {
      this.requestGetVoteResult({
        aliasID: this.aliasID,
        subjectID: this.subjectID,
        onSuccess: (res) => {
          if (this.$store.state.GetVoteResult &&
              this.$store.state.GetVoteResult.questionList &&
              this.$store.state.GetVoteResult.questionList.length > 0) {
            //this.optionList = this.$store.state.GetVoteResult.questionList[0].optionList;
            // deep copy『$store.state』物件，才能複製物件並修改
            const originalOptionList = this.$store.state.GetVoteResult.questionList[0].optionList;
            this.optionList = JSON.parse(JSON.stringify(originalOptionList));
            if(this.$store.state.GetVoteResult.isEnded) {
             this.goVoteResult();
             return;
            }
            if(this.$store.state.GetVoteResult.isVoted) {
             this.goVoteResult();
             return;
            }
          }
          setTimeout(() => {
            this.isDataLoad = true;
            this.hideLoading(loader);
          }, 500);

          const eventItems = [
            {
              type: 'pageViewEvent',
              eventId: '1045',
              event: 'poll_page_view',
              pageInfo: {
                page: 'poll',
                type: this.questionMode === 1 ? 'poll_pk' : 'poll',
                uuid: this.subjectID,
                name: this.voteData.title,
                amount: this.voteData.votePeopleCount,
                source: 'news',
                cat0: [this.cat0],
                cat1: [],
              },
              isAdditiveView: false,
            },
          ];
          passEvent(eventItems);
        },
        onFailed: (err) => {
          this.hideLoading(loader);
          if (err.response.data.error.message.indexOf("Data Already Deleted") > -1) {
            this.isDataLoad = true;
            this.message = this.NotExitMessage;
            this.$refs.alert.showPopup();
          }
        },
      });
    },
    handleVote() {
      let vote = new Vote({
        aliasID: this.aliasID,
        version: this.voteData.version,
        questionList: [],
      });
      const voteQuestion = new VoteQuestion({
        questionID: 1,
        optionIDCheckList: this.checkedOptionIds,
      });
      vote.questionList.push(voteQuestion);

      // call mixins
      this.requestVote({
        subjectID: this.subjectID,
        vote: vote,
        onSuccess: (res) => {
          const eventItems = [
            {
              type: 'clickEvent',
              eventId: '1046',
              event: 'poll_item_click',
              pageInfo: {
                page: 'poll',
                type: this.questionMode === 1 ? 'poll_pk' : 'poll',
                uuid: this.subjectID,
                name: this.voteData.title,
                amount: this.voteData.votePeopleCount,
                source: 'news',
                cat0: [this.cat0],
                cat1: [],
                status: this.voteData.isVoted ? 'polled' : 'open',
              },
              clickInfo: {
                name: 'poll',
              },
            },
          ];
          passEvent(eventItems);

          this.goVoteResult();
        },
        onFailed: (err) => {
          if (err.response.data.error.code === 20013){
            this.message = this.alreadyEditMessage;
            this.$refs.alert.showPopup();
          }
        },
      });
    },
    optionCellChecked(optionID, isChecked) {
      // index => 處理 checkedOptionIds
      const indexChecked = this.checkedOptionIds.indexOf(optionID);

      // index => 處理 optionList
      const indexData = this.optionList.findIndex(option => option.optionID == optionID);

      // 判斷原本是否有被選過
      if (indexChecked > -1) {
        this.checkedOptionIds.splice(indexChecked, 1); // checkedOptionIds
        this.optionList[indexData].isVoted = false;    // optionList
      }

      if (isChecked) {
        // 單選時，取消其他選項
        if (!this.isMultipleVote) {
          this.checkedOptionIds = [];
          this.optionList.forEach(option => {
            option.isVoted = false;
          });
        }
        this.checkedOptionIds.push(optionID);      // checkedOptionIds
        this.optionList[indexData].isVoted = true; // optionList
      }
    },
    handleTouchStart(event) {
      this.carouselY = event.changedTouches[0].clientY;
    },
    handleTouchMove(event) {
      const nowY = event.changedTouches[0].clientY;
      if (nowY - this.carouselY > 0) {
        event.stopPropagation();
      }
    },
    handleTouchEnd(event) {
      const nowY = event.changedTouches[0].clientY;
      if (nowY - this.carouselY > 0) {
        if(this.voteData.questionList[0].type === 2) {
          // 避免使用者在滑動 carousel 選項時，不小心上下滑動觸發頁面重整
          event.preventDefault();
        }
        event.stopPropagation();
      }
      this.carouselY = 0;
    },
    goVoteResult() {
      this.$router.replace({
        name: 'newsVoteResult',
        query: {
          'subjectid': this.subjectID,
          'newsMainBannerId': this.newsMainBannerId
        }
      });
    },
    goVoteList() {
      this.$router.push({
        name: 'newsVoteList',
        query: {
          'newsMainBannerId': this.newsMainBannerId
        }
      });
    },
    switchSort(sort, isAuto) {
      this.saveSort({ sort: sort });
      if (sort == 1) {
        this.handleGetCommentsByPopular();
      } else {
        this.handleGetCommentsByNewest();
      }

      if (isAuto) {
        return;
      }

      // [event trace] 12-1 點擊留言排序
      const eventItems = [
        {
          type: 'clickEvent',
          eventId: '1049',
          event: 'comment_content_page_item_click',
          pageInfo: {
            page: 'poll',
            type: 'poll',
            uuid: this.subjectID,
            provider: '',
            cat0: [],
            cat1: [],
            cat2: [],
            tag: [],
          },
          clickInfo: {
            type: 'sort',
            name: this.commentSort === 1 ? 'pop' : 'latest',
          },
        },
      ]
      passEvent(eventItems);
    },
    handleGetCommentCount() {
      this.requestGetCommentCount({
        id: 'vote',
        entityType: 'vote',
        entityId: this.subjectID,
        onSuccess: (res) => {
          this.commentCount = this.$store.state.Comment.count;
        },
        onFailed: (err) => {
        },
      });
    },
    handleGetLikeCount() {
      this.requestGetLikeCount({
        entityType: 'vote',
        aliasId: this.aliasID,
        entityIds: [this.subjectID],
        onSuccess: (res) => {
          this.reactionCount = res.data[0].reactionCount
          this.isClickedReaction = res.data[0].myReaction === 0 ? false : true
          this.saveMyReactionType = res.data[0].myReaction
        },
        onFailed: (err) => {
        },
      });
    },
    handleGetCommentsByPopular() {
      this.requestGetCommentsByPopular({
        id: 'vote',
        entityId: this.subjectID,
        entityType: 'vote',
        aliasId: this.aliasID,
        show: 5,
        onSuccess: (res) => {
        },
        onFailed: (err) => {
        },
      });
    },
    handleGetCommentsByNewest() {
      this.requestGetCommentsByNewest({
        id: 'vote',
        entityType: 'vote',
        entityId: this.subjectID,
        aliasId: this.aliasID,
        prev: '',
        sort: 1,
        show: 5,
        next: '',
        onSuccess: (res) => {
        },
        onFailed: (err) => {
        },
      });
    },
    sendReply(commentId, author) {
      const link = `commentList?subjectid=${this.subjectID}`
                 + `&newsMainBannerId=${this.newsMainBannerId}`
                 + `&commentId=${commentId}`
                 + `&authorId=${author.aliasId}`
                 + `&authorName=${author.name}`;
      location.href = link;
    },
    showReportComment(lotterId, lotterType, reportCommentId) {
      this.lotterId = lotterId;
      this.lotterType = lotterType;
      this.reportCommentId = reportCommentId;
      this.showReport = true;
    },
    hideReportComment() {
      this.showReport = false;
    },
    operationSucess() {
      this.$refs.toast.show('檢舉已送出', 2000);
    },
    operationFailed() {
      this.$refs.toast.show('操作失敗', 2000);
    },
    deleteFailed() {
      this.$refs.toast.show('操作失敗', 2000);
    },
    goCommentList(isShowKeyboard) {
      // [event trace] 12-5 留言點擊查看更多留言或查看之前的回覆 (看更多)
      if (isShowKeyboard == 'N') {
        const eventItems = [
          {
            type: 'clickEvent',
            eventId: '1049',
            event: 'comment_content_page_item_click',
            pageInfo: {
              page: 'poll',
              type: 'poll',
              uuid: this.subjectID,
              provider: '',
              cat0: [],
              cat1: [],
              cat2: [],
              tag: [],
            },
            clickInfo: {
              uuid: '',
              type: 'comment',
              name: 'more',
            },
          },
        ];
        passEvent(eventItems);
      }

      const subjectid = this.subjectID;
      const newsMainBannerId = this.newsMainBannerId;
      const link = `commentList?subjectid=${subjectid}&newsMainBannerId=${newsMainBannerId}`
                 + `&isShowKeyboard=${isShowKeyboard}`;
      location.href = link;
    },
    sendMessage() {
      const { messageBody, selectOption } = generateInsideSharingParams({
        pageTitle: this.cat0,
        title: this.voteData.title,
        imageUri: this.voteData.imageUri,
        uri: location.href,
      });

      BGO.send_message_v2(messageBody, selectOption);
    },
    sendDataToApps() {
      let linkWithUtm = `${location.href}`
      const title = `${this.voteData.title} | beanfun! ${this.cat0}`
      const description = this.voteData.description
      const imageUrl = this.voteData.imageUri
      const defaultLink = linkWithUtm
      const domain = process.env.VUE_APP_UNIVERSAL_LINKS_DOMAIN;
      const id = process.env.VUE_APP_OFFICAIL_ACCOUNT_ID;
      const enTitle = encodeURIComponent(this.cat0);
      const enLink = encodeURIComponent(defaultLink);
      const universalLink = `${domain}/h5page/${id}?url=${enLink}&theme=1&title=${enTitle}`;
      BGO.create_shortlink(
        title,
        description,
        imageUrl,
        universalLink,
        data => {
        if (data) {
          const link = data.shortlink
          const text = `${link} \n嘿，想跟你分享 beanfun! 這個投票！來看看吧！`
          BGO.share_with_sharesheet(text, link, 'NewsVotes');
        }
      });
    },
  },
  beforeDestroy() {
    this.hideLoading(loader);
    this.isLoading = false;
  },
};
</script>

<style lang="sass" scoped>
.txt
  margin: 17px 32px 20px 32px

.PageContainerPosition
  z-index: 1
  top: 150px
  position: absolute
  overflow-x: hidden
  @include media(xs) // RWD sass max-width: 320px
    top: 160px
    border-radius: 11px 11px 0px 0px

.picSquare
  width: 260px
  height: 275px
  border-radius: 20px
  overflow: hidden

.Select
  border: solid 2px #26d07c

.UnSelect
  border: solid 1px #ededed
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)

.fit-picture
  max-width: 100%
  height: 195px
  margin: 0 auto
  display: block

.picArea
  width: 100%
  height: 195px
  background-color: rgb(255, 255, 255)
  display: inline-block
  background-position: center
  background-repeat: no-repeat
  background-size: contain

.label
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.VueCarousel-slide
  position: relative
  min-height: 300px

.blankDiv
  height: 30px
  width: 100%

// 避免使用者在滑動 carousel 選項時，不小心上下滑動觸發頁面重整
.avoidRefresh
  width: 100vw       // 寬度設定為螢幕寬度滿版
  margin-left: -20px // 由於 PageContainer 有設定 padding-left:20px，這邊要補回去
  //background: #000 // 除錯查問題時，打開此註解

.comment-menu
  margin: 12px

.comment-menu-text
  color: #515151
  font-family: NotoSansTC-Medium
  font-size: 15px
  letter-spacing: 0.1px
  line-height: 20px

.comment-menu-text-active
  color: #26d07c

.line
  background: #cccccc
  height: 16px
  width: 1px
  margin: 0px 16px

.comment-reply
  padding: 8px 0px
  margin-top: -16px
  margin-bottom: 12px

.left-item
  width: 30px
  height: 100%

.right-item
  width: calc(100% - 30px)
  display: flex

.right-item-input
  width: 100%

.headshot
  height: 30px
  width: 30px
  border-radius: 50%
  background: url('~@/assets/images/unnamed.png') center no-repeat

.comment
  height: 40px
  line-height: 40px
  color: #CCCCCC
  font-family: Noto Sans TC
  font-size: 15px
  margin: auto 14px

.more-comment
  font-family: Noto Sans TC
  font-size: 15px
  font-style: normal
  font-weight: 500
  line-height: 22px
  letter-spacing: 0px
  text-align: center
  color: #26D07C
  margin: 0px auto 
  margin-bottom: 28px

.no-more-comment
  margin-bottom: 28px

.bar
  z-index: 3
  position: fixed
  bottom: 0px
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1)
  background: #ffffff

.report-comment
  position: fixed
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  background: #ffffff
  border-radius: 30px
  padding: 20px
  z-index: 3

.mask
  position: fixed
  top: 0px
  z-index: 3
  background: rgba(0, 0, 0, 0.28)
  width: 100%
  height: 100%
</style>

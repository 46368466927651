<template lang="html">
  <textarea
    class="textarea"
    :placeholder="placeholder"
    maxlength="5000"
    ref="textarea"
    rows="1"
    @focus="resizeTextarea"
    @keyup="resizeTextarea"
    @paste.prevent="onPaste"
    v-model="value"
  />
</template>

<script>
export default {
  name: 'AutosizeTextarea',
  props: ['placeholder'],
  watch: {
    value: function(newVal, oldVal) {
      this.setValue();
    }
  },
  data() {
    return {
      value: '',
    }
  },
  methods: {
    resizeTextarea() {
      const { textarea } = this.$refs;
      textarea.style.overflow = 'hidden';
      textarea.style.height = 'auto';
      if (textarea.scrollHeight > 100) {
        textarea.style.height = '100px';
        textarea.style.overflow = 'scroll';
      } else {
        textarea.style.height = textarea.scrollHeight + 2 + 'px';
      }
      const radius = 30 * (40 / parseFloat(textarea.style.height));
      textarea.style.borderRadius = `${radius}px`;
    },
    setValueWithStorage(storageValue) {
      this.value = storageValue;
    },
    setValue() {
      this.$emit('setValue', this.value);
    },
    clearValue() {
      this.value = '';
      this.$nextTick(function () {
        this.resizeTextarea();
      });
    },
    setFocus() {
      this.$refs.textarea.focus();
    },
    setBlur() {
      this.$refs.textarea.blur();
    },
    onPaste(e) {
      const maxlength = this.$refs.textarea.getAttribute("maxlength");
      let text = e.clipboardData.getData('text/plain');

      let startPos = this.$refs.textarea.selectionStart;
      let endPos = this.$refs.textarea.selectionEnd;

      let oldValue = this.value;
      this.value = oldValue.substring(0, startPos) + text + oldValue.substring(endPos, oldValue.length);

      if (this.value.length > maxlength) {
        this.value = this.value.slice(0, maxlength);
        this.$emit('overSize');
      }

      this.$nextTick(function () {
        this.resizeTextarea();
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.textarea
  resize: none
  background: #FAFAFA
  border: 1px solid rgba(211, 211, 211, 0.5)
  box-sizing: border-box
  border-radius: 30px
  width: 100%
  font-family: Roboto
  font-size: 15px
  line-height: 15px
  min-height: 40px
  padding: 12px 11px
  //margin: auto

::placeholder
  color: #cccccc
</style>

// 參考文件: https://docs.google.com/document/d/1D3kwfoZS9-itiHxEmP0SkoTbkEpa4ZKy/edit

export const passEvent = (
  eventItems,
) => {
  const traceEvents = [];

  eventItems.forEach((eventItem) => {
    const {
      eventId,
      event,
      pageInfo,
      isAdditiveView,
      clickInfo,
      impressionInfo,
      pageSearchTerm,
    } = eventItem;

    switch (eventItem.type) {
      case 'pageViewEvent':
        traceEvents.push(
          new window.beanfunWebTraceSDK.events.PageViewEvent({
            eventId,
            event,
            pageInfo,
            isAdditiveView,
          }),
        );
        break;
      case 'clickEvent':
        traceEvents.push(
          new window.beanfunWebTraceSDK.events.ClickEvent({
            eventId,
            event,
            pageInfo,
            clickInfo,
          }),
        );
        break;
      case 'impressionEvent':
        traceEvents.push(
          new window.beanfunWebTraceSDK.events.ImpressionEvent({
            eventId,
            event,
            pageInfo,
            impressionInfo,
            isAdditiveView,
          }),
        );
        break;
      case 'searchEvent':
        traceEvents.push(
          new window.beanfunWebTraceSDK.events.SearchEvent({
            eventId,
            event,
            pageInfo,
            pageSearchTerm,
          }),
        );
        break;
      default:
        break;
    }
  });

  if (traceEvents.length !== 0) {
    window.sender.passEvent(traceEvents);
  }
};

export default passEvent;

export default {
  methods: {
    requestGetVoteResult({
      aliasID = 0, subjectID = 0, onSuccess, onFailed,
    }) {
      this.$store.dispatch('GET_VOTE_RESULT', {
        aliasID,
        subjectID,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    requestGetVoteResultV2({
      aliasID = 0,
      subjectID = 0,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('GET_VOTE_RESULT_V2', {
        aliasID,
        subjectID,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
  },
};

class Vote {
  constructor({
    aliasID,
    version,
    questionList,
  }) {
    this.aliasID = aliasID;
    this.version = version;
    this.questionList = questionList;
  }

  serialize() {
    return {
      aliasID: this.aliasID,
      version: this.version,
      questionList: this.questionList,
    };
  }
}

export default Vote;

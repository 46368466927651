export default {
  methods: {
    requestEditVote({
      editVote = null,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('PUT_EDIT_VOTE', {
        editVote,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
  },
};

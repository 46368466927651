<template lang="html">

  <div class="VoteNowButton">
    馬上投票
  </div>

</template>

<script>
export default {
  name: 'VoteNowButton',
};
</script>

<style lang="sass" scoped>
.VoteNowButton
  width: 85px
  height: 32px
  border-radius: 12px
  background: #26d07c
  color: #ffffff
  line-height: 32px
  text-align: center
  margin: 5px
</style>

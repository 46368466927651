export default {
  methods: {
    requestDeleteVote({
      subjectID = 0,
      modifiedUser = '',
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('DELETE_VOTE', {
        subjectID,
        modifiedUser,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
  },
};

<template lang="html">
  <div>
    <div class="optionArea">
      <!-- 文字 -->
      <div class="textArea contentArea" v-bind:class="{pic_contentArea: questionType == 2}" :style="optionError == true ? 'border: 1px solid #ed5b4c':''">
        <div v-show="questionMode == 2 && optionCount > 2" class="closeBtn" @click="deleteOption">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask6" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="14" height="14">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0842 10L15.9259 5.1583C16.0821 5.00209 16.0821 4.74882 15.9259 4.59261L15.4074 4.07407C15.2511 3.91786 14.9979 3.91786 14.8417 4.07407L9.99996 8.91578L5.15825 4.07407C5.00204 3.91786 4.74878 3.91786 4.59257 4.07407L4.07402 4.59261C3.91781 4.74882 3.91781 5.00209 4.07402 5.1583L8.91573 10L4.07402 14.8417C3.91781 14.9979 3.91781 15.2512 4.07402 15.4074L4.59257 15.9259C4.74878 16.0822 5.00204 16.0822 5.15825 15.9259L9.99996 11.0842L14.8417 15.9259C14.9979 16.0822 15.2511 16.0822 15.4074 15.9259L15.9259 15.4074C16.0821 15.2512 16.0821 14.9979 15.9259 14.8417L11.0842 10Z" fill="#262626"/>
            </mask>
            <g mask="url(#mask6)">
              <rect width="20" height="20" fill="#CCCCCC"/>
            </g>
          </svg>
        </div>
        <textarea-autosize
          class="content"
          :style="questionMode == 2 && optionCount > 2 ? '' : 'margin-left:20px'"
          rows="1"
          :maxlength="questionMode == 2 ? '24' : '8'"
          :placeholder="questionMode == 2 ? '輸入選項' : option.sortID == 1 ? '紅方選項' : '藍方選項'"
          ref="myTextarea"
          :min-height="30"
          :max-height="90"
          @blur.native="updateOption"
          v-model.trim="inputOptionContent"
        />
      </div>

      <!-- 圖片 -->
      <div class="textArea pic_picArea" v-if="questionType == 2" @click="selectImage(4,3)" v-bind:style="{ 'background-image': 'url(\'' + imageUri + '\')', 'border': optionImgError ? '1px solid #ed5b4c' : '' }">
        <svg class="picIcon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="imageUri == ''">
          <g clip-path="url(#clip0)">
            <mask id="mask7" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="18" height="18">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.91667 5.91667H19.0833C19.6356 5.91667 20.0833 6.36438 20.0833 6.91667V14.7544L18.0343 12.7054C17.6112 12.2823 16.9253 12.2823 16.5022 12.7054L13.7114 15.4962L12.3433 14.1281C11.9202 13.7051 11.2343 13.7051 10.8112 14.1281L5.91667 19.0226V6.91667C5.91667 6.36438 6.36438 5.91667 6.91667 5.91667ZM6.97725 20.0833H19.0833C19.6356 20.0833 20.0833 19.6356 20.0833 19.0833V16.8757L17.2683 14.0607L14.772 16.5569L15.4988 17.2838C15.7331 17.5181 15.7331 17.898 15.4988 18.1323L15.2867 18.3444C15.0524 18.5787 14.6725 18.5787 14.4382 18.3444L11.7582 15.6645L11.5772 15.4834L6.97725 20.0833ZM4.25 6.91667C4.25 5.44391 5.44391 4.25 6.91667 4.25H19.0833C20.5561 4.25 21.75 5.44391 21.75 6.91667V19.0833C21.75 20.5561 20.5561 21.75 19.0833 21.75H6.91667C5.44391 21.75 4.25 20.5561 4.25 19.0833V6.91667ZM10.0575 11.0473C10.6042 11.0473 11.0473 10.6041 11.0473 10.0575C11.0473 9.51078 10.6042 9.06762 10.0575 9.06762C9.51083 9.06762 9.06767 9.51078 9.06767 10.0575C9.06767 10.6041 9.51083 11.0473 10.0575 11.0473ZM10.0575 12.5473C11.4326 12.5473 12.5473 11.4326 12.5473 10.0575C12.5473 8.68235 11.4326 7.56762 10.0575 7.56762C8.68241 7.56762 7.56767 8.68235 7.56767 10.0575C7.56767 11.4326 8.68241 12.5473 10.0575 12.5473Z" fill="#333333"/>
            </mask>
            <g mask="url(#mask7)">
              <rect x="0.5" y="0.5" width="25" height="25" fill="#DDDDDD"/>
            </g>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="25" height="25" fill="white" transform="translate(0.5 0.5)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <iOSAlert ref="alert" :message="message" />
  </div>
</template>

<script>
import { Option } from '@/store/entity/request';
import { file } from '../../mixins';

// vue-textarea-autosize
import Vue from 'vue';
import TextareaAutosize from 'vue-textarea-autosize';

import iOSAlert from '../../components/iOSAlert.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'EditedOptionsCell',
  mixins: [file],
  components: {
    iOSAlert,
  },
  props: ['subjectID', 'questionMode', 'questionType', 'option', 'optionCount', 'optionCheck', ],
  watch: {
    subjectID: function(newVal, oldVal) {
    },
    questionMode: function(newVal, oldVal) {
    },
    // 用來判斷是否顯示右邊圖片框
    questionType: function(newVal, oldVal) {
      this.$refs.myTextarea.$el.focus()
      this.$refs.myTextarea.$el.blur()
    },
    option: function(newVal, oldVal) {
      this.inputOptionContent = this.option.title;
      this.imageUri = this.option.imageUri;
    },
    optionCount: function(newVal, oldVal) {
    },
    optionCheck: function(newVal, oldVal) {
      if (this.optionCheck == true) {
        this.checkError();
      }
    }
  },
  data() {
    return {
      inputOptionContent: this.option.title, // 文字輸入框
      imageUri: this.option.imageUri,        // 圖片框
      optionError: false,
      optionImgError: false,
      message: {
        title: "",
        subtitle: [],
        callbackOk: () => {},
        callbackCancel: () => {},
      },
      uploadErrorMsg: {
        title: '圖片上傳失敗',
        subtitle: ['', ''],
        callbackOk: () => {
        },
      },
    }
  },
  methods: {
    updateOption() {
      this.checkError();
      const option = new Option({
        sortID: this.option.sortID,
        title: this.inputOptionContent,
        imageUri: this.imageUri,
      });
      this.$emit('updateOption', option);
    },
    deleteOption() {
      this.$emit('deleteOption', this.option.sortID);
    },
    checkError() {
      if (this.inputOptionContent == '') {
        this.optionError = true;
      } else {
        this.optionError = false;
      }
      if (this.imageUri == '') {
        this.optionImgError = true;
      } else {
        this.optionImgError = false;
      }
    },
    // 選取圖片
    selectImage(scaleW, scaleH) {
      BGO.get_multiple_cropped_image_type(
        {
          "type": "rectangle",
          "scale": {
            "width": scaleW,
            "height": scaleH
          },
          "grid": true,
        },
        (data) => {
          //this.imageUri = "data:image/png;base64," + data.imgs[0].image_content + "";

          // 移除 data.imgs[0].image_content 最後方的換行符號
          //this.imageUri = this.imageUri.replace(/\r?\n|\r/g, '');

          // 將 base64 轉為 bytes
          const blob = this.base64ToBytes(data.imgs[0].image_content.replace(/\r?\n|\r/g, ''));

          // call mixins
          this.upload({
            subjectID: this.subjectID,
            file: blob,
            onSuccess: (res) => {
              this.imageUri = this.$store.state.File.file_uri;
              this.updateOption();
            },
            onFailed: (err) => {
              this.message = this.uploadErrorMsg;
              this.$refs.alert.showPopup();
            },
          });
        },
        1,
        0
      );
    },
  },
};

Vue.use(TextareaAutosize);
</script>

<style lang="sass" scoped>
.textArea
  border: solid 0.5px rgb(221, 221, 221)
  border-radius: 10px
  width: auto // 避免被 global.sass 設定的 .textArea 影響
  position: relative

.contentArea
  //padding: 10px 16px
  //background-color: rgb(255, 255, 255)

.text_contentArea
  margin-bottom: 12px

.content
  width: calc(100% - 40px)
  font-size: 15px
  line-height: 30px
  letter-spacing: 0.25px
  border: none
  resize: none
  color: rgb(51, 51, 51)
  //position: relative
  top: 4px
  padding: 0px
  margin: 10px 0px 5px 40px

.vueTextarea
  width: 100%

\::placeholder // Chrome, Firefox, Opera, Safari 10.1+
  color: rgb(221, 221, 221)
  opacity: 1 // Firefox

.optionArea
  width: 100%
  position: relative
  margin-bottom: 12px

.pic_contentArea
  display: inline-block
  width: calc(100% - 63px)

.pic_picArea
  height: 46px
  width: 46px
  background-color: rgb(255, 255, 255)
  display: inline-block
  position: absolute
  right: 0px
  top: calc(50% - 24px)
  background-position: center
  background-repeat: no-repeat
  background-size: cover

.flex
  display: flex

.picIcon
  position: absolute
  margin: 10.5px
  width: 25px
  color: red

.closeBtn
  display: inline-block
  position: absolute
  top: calc(50% - 10px)
  left: 10px
  margin-right: 8px
</style>

<template lang="html">
  <!-- link 顯示 og info -->
  <div v-if="hasOgInfo" class="link-og-data">
    <div class="og-image">
      <img :src="ogImage" @error="(e) => { e.target.src = '@/assets/images/default_og_image.png' }"/>
    </div>
    <div class="og-text">
      <div class="og-title">{{ ogTitle }}</div>
      <div class="og-description">{{ ogDescription }}</div>
    </div>
  </div>
</template>

<script>
import { comment } from '@/mixins';

export default {
  name: 'LinkOgBlock',
  props: ['comment', 'type'],
  mixins: [comment],
  data() {
    return {
      hasOgInfo: false,
      ogImage: '',
      ogTitle: '',
      ogDescription: '',
    }
  },
  mounted() {
    this.messageGetOgInfo()
  },
  methods: {
    async messageGetOgInfo() {
      let message = ''
      let ogData = undefined
      switch(this.type) {
        case 'comment':
          message = this.comment.comment.content;
          break
        case 'reply':
          message = this.comment.reply.content;
          break
      }
      if (!/(www.|tw.|(http(s?)):\/\/)/i.test(message)) {
        return;
      }
      const regex =
        /(www.|tw.|(http(s?)):\/\/)[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]?/gi;
      const urls = message.match(regex);
      const firstUrl = urls ? urls[0] : null;
      if (firstUrl) {
        const url = firstUrl.match(/^(www.|tw.)/)
          ? `https://${firstUrl}`
          : firstUrl;
        ogData = await this.requestOgData(url);
        (this.ogImage =
          ogData.image || require("@/assets/images/default_og_image.png")),
          (this.ogTitle = ogData.title || new URL(url).hostname),
          (this.ogDescription = ogData.description || "點選此處開啟連結");
        this.hasOgInfo = true;
      }
    },
  },
};
</script>

<style scoped>
.link-og-data {
  display: flex;
  height: 65px;
  background: #eee;
  border-radius: 5px;
  margin-right: 30px;
  align-items: center;
  margin-bottom: 5px;
}

.og-image > img {
  border-radius: 5px 0 0 5px;
  width: 65px;
  height: 65px;
  display: block;
  object-fit: cover;
}

.og-text {
  margin-left: 5px;
  margin-right: 10px;
}

.og-text .og-title {
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 1.2;
  margin-bottom: 3px;
}

.og-text .og-description {
  font-size: 10px;
  color: gray;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
</style>

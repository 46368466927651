<!-- 檢舉留言 -->
<template lang="html">
  <div class="card">
    <div class="flex align-center align-items justify-end">
      <div class="title">檢舉</div>
      <!-- #region 叉圖 -->
      <div @click="goBack">
        <img class="close-icon" src="@/assets/images/icCloseLDark.png" alt="">
      </div>
      <!-- #endregion 叉圖 -->
    </div>
    <div class="tell-reason">告訴我們檢舉原因</div>
    <div class="to-beanfun">被檢舉的留言將會回報給 beanfun! 做審核。</div>
    <div class="flex align-center reasons" @click="setReportDetailType(2)">
      <div v-if="reportDetailType == 2" class="circle-icon-checked">
        <div class="circle-checked"></div>
      </div>
      <div v-else class="circle-icon"></div>
      <div class="reason-txt">廣告或垃圾訊息</div>
    </div>
    <div class="flex align-center reasons" @click="setReportDetailType(3)">
      <div v-if="reportDetailType == 3" class="circle-icon-checked">
        <div class="circle-checked"></div>
      </div>
      <div v-else class="circle-icon"></div>
      <div class="reason-txt">仇恨言論或暴力</div>
    </div>
    <div class="flex align-center reasons" @click="setReportDetailType(4)">
      <div v-if="reportDetailType == 4" class="circle-icon-checked">
        <div class="circle-checked"></div>
      </div>
      <div v-else class="circle-icon"></div>
      <div class="reason-txt">騷擾或霸凌</div>
    </div>
    <div class="flex align-center reasons" @click="setReportDetailType(5)">
      <div v-if="reportDetailType == 5" class="circle-icon-checked">
        <div class="circle-checked"></div>
      </div>
      <div v-else class="circle-icon"></div>
      <div class="reason-txt">裸露或性相關</div>
    </div>
    <!-- #region 送出按鈕 -->
    <div class="flex justify_content_center" @click="handleCreateReportDetail">
      <div :class="['send-btn flex justify_content_center', reportDetailType > 0 ? 'send-btn-active' : '']">
        <div class="send-txt">送出</div>
      </div>
    </div>
    <!-- #endregion 送出按鈕 -->
  </div>
</template>

<script>
import { comment } from '@/mixins';
import { passEvent } from '@/utils/trackingHelper';

export default {
  name: 'ReportComment',
  mixins: [comment],
  props: ['newsMainBannerId', 'aliasID', 'lotterType', 'lotterId', 'reportCommentId'],
  data() {
    return {
      reportDetailType: 0,
    }
  },
  computed: {
    stateComment() {
      return this.$store.state.Comment.list
             .filter(x => x.comment.commentId == this.reportCommentId)[0];
    },
  },
  methods: {
    setReportDetailType(type) {
      this.reportDetailType = type;
    },
    handleCreateReportDetail() {
      if (this.reportDetailType <= 0) {
        return;
      }
      this.requestCreateReportDetail({
        newsMainBannerId: this.newsMainBannerId,
        reporterId: this.aliasID,
        lotterId: this.lotterId,
        lotterType: this.lotterType,
        type: this.reportDetailType,
        reason: '',
        onSuccess: (res) => {
          this.$emit('operationSucess');
          this.hide();

          // [event trace] 12-7 送出檢舉留言或回覆
          let reportDetail = '';
          switch (this.reportDetailType) {
            case 2:
              reportDetail = '廣告或垃圾訊息';
              break;
            case 3:
              reportDetail = '仇恨言論或暴力';
              break;
            case 4:
              reportDetail = '騷擾或霸凌';
              break;
            case 5:
              reportDetail = '裸露或性相關';
              break;
            default:
              reportDetail = '';
              break;
          }
          let content = '';
          if (this.lotterType == 1) {
            content = this.stateComment.comment.content;
          } else {
            content = this.stateComment.replies
                      .filter(x => x.reply.replyId == this.lotterId)[0].reply.content;
          }
          const eventItems = [
            {
              type: 'clickEvent',
              eventId: '1049',
              event: 'comment_content_page_item_click',
              pageInfo: {
                page: 'comment_report_comment',
                type: 'poll',
                uuid: this.lotterId,
                content: content,
              },
              clickInfo: {
                type: this.lotterType == 1 ? 'comment' : 'reply',
                cat: reportDetail,
                name: 'sent_report',
              },
            },
          ];
          passEvent(eventItems);
        },
        onFailed: (err) => {
          this.$emit('operationFailed');
        },
      });
    },
    hide() {
      this.$emit('hideReportComment');
    },
    goBack() {
      this.hide();
    },
  },
};
</script>

<style lang="sass" scoped>
.card
  width: 280px
  height: 380px

.title
  font-family: PingFangTC-Medium
  font-style: normal
  font-weight: 500
  font-size: 17px
  line-height: 24px
  letter-spacing: 0.15px
  color: #333333
  margin-right: 80px

.tell-reason
  font-family: PingFangTC-Medium
  font-size: 15px
  color: #262626
  margin-top: 24px

.to-beanfun
  font-family: PingFangTC-Regular
  font-size: 13px
  line-height: 18px
  letter-spacing: -0.08px
  color: #949494
  margin-top: 5px

.circle-icon
  border: 2px solid  #d3d3d3
  border-radius: 50%
  width: 16px
  height: 16px
  margin-right: 14px

.circle-icon-checked
  border: 2px solid  #26d07c
  border-radius: 50%
  width: 16px
  height: 16px
  margin-right: 14px
  position: relative

.circle-checked
  width: 10px
  height: 10px
  border-radius: 50%
  background: #26d07c
  position: absolute
  left: 3px
  top: 3px

.reason-txt
  font-family: PingFangTC-Regular
  font-size: 15px
  line-height: 20px

.reasons
  margin-top: 21px

.send-btn
  background: #cccccc
  width: 260px
  height: 40px
  line-height: 40px
  border-radius: 16px
  margin-top: 60px

.send-btn-active
  background: #26d07c

.send-txt
  font-size: 15px
  color: #ffffff
  font-family: PingFangTC-Medium
  margin: 0 auto

.close-icon
  width: 36px
  height: 36px
</style>

<template lang="html">
  <div v-show="isDataLoad">
    <SubjectBanner
     :content="voteData.title"
     :image="voteData.imageUri"
     :createdUserName="voteData.createdUserName"
     :votePeopleCount="voteData.votePeopleCount"
     :dueDays="voteData.dueDays"
     :endTime="voteData.endTime"
    />
    <PageContainer class="PageContainerPosition">
      <!-- 設為公告/取消公告/選單...按鈕 -->
      <!-- 建立者模式才有;參與者無 -->
      <SubjectSettingCell v-show="isAdmin" :subjectID="subjectID" :aliasID="this.aliasID" :chatID="this.chatID" 
      :isPinned="voteData.isPinned" :votePeopleCount="voteData.votePeopleCount" :isEnded="voteData.isEnded" />
     
      <!-- 避免使用者在滑動 carousel 選項時，不小心上下滑動觸發頁面重整 -->
      <div class="avoidRefresh"
       @touchstart="handleTouchStart"
       @touchmove="handleTouchMove"
       @touchend="handleTouchEnd">
        <!-- 說明文字 -->
        <div class="txt">
          {{ voteData.description }}
        </div>

        <!-- 文字模式  -->
        <!-- 判斷單選/複選 -->
        <!-- ＰＫ模式 只有兩個選項; 一般模式 >= 2 -->
        <OptionCell v-if="questionType == 1" :option="option" v-for="option in optionList"
          :key="option.optionID"
          @checkedCallback="optionCellChecked"
          style="width:calc(100% - 40px);"
        />

        <!-- 圖片模式 slicker -->
        <!-- ＰＫ模式 只有兩個選項; 一般模式 >= 2 -->
        <!-- swiper 套件 -->
        <swiper  v-if="questionType == 2" :options="swiperOption">
          <swiper-slide v-for="option in optionList" :key="option.optionID" style="width: 260px; height: 350px">
            <div class="label picSquare mr_24" style="height: 260px; width:260px" :class="(checkedOptionIds.indexOf(option.optionID) > -1) ? 'Select' : 'UnSelect'">
              <div class="picArea" :style="{ 'background-image': 'url(\'' + option.imageUri + '\')' }"></div>
              <OptionCell :option="option" @checkedCallback="optionCellChecked" style="border: 0px solid #ccc;height:80px;margin"/>
            </div>
          </swiper-slide>
        </swiper>

        <!-- 撐高的div -->
        <div class="blankDiv"></div>
      </div>

      <!--  判斷是否已投票  -->
      <!-- 下好離手 btnActive 綠色/btnDisabled 灰色 #dddddd -->
      <RoundButtonActive :isActive="checkedOptionIds.length > 0" />
      <iOSAlert ref="alert" :message="message" />
    </PageContainer>
  </div>
</template>

<script>
import { Vote, VoteQuestion } from '@/store/entity/request';
import { loading, auth, getIsVoted, getVoteResult, vote } from '@/mixins';
import iOSAlert from '../components/iOSAlert.vue';

// swiper
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import "swiper/css/swiper.css";

//slicker local
import { Carousel, Slide } from 'vue-carousel';

import PageContainer from '../components/PageContainer.vue';

// 10.3.1 投票頁面-主題Banner
import SubjectBanner from './components/SubjectBanner.vue';

//10.3.1 投票頁面-設為公告/取消公告/選單...按鈕
import SubjectSettingCell from './components/SubjectSettingCell.vue';

// 10.3.1 投票頁面單一選項-大圖模式(無框)/純文字模式(有框) (共用一條)
import OptionCell from './components/OptionCell.vue';

// 圓角長按鈕 Active/Disabled 兩種狀態做一版，然後用狀態換樣式
import RoundButtonActive from '../components/RoundButtonActive.vue';

// Loading
var loader;

export default {
  name: 'Preview',
  mixins: [loading, auth, getIsVoted, getVoteResult, vote],
  components: {
    iOSAlert,
    Slide,
    Carousel,
    PageContainer,
    SubjectBanner,
    SubjectSettingCell,
    OptionCell,
    RoundButtonActive,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        }
      },
      isDataLoad: false,
      subjectID: 0,
      optionList: [],
      checkedOptionIds: [],
      carouselY: 0, // 避免滑動 carousel 時，不小心觸發向下拉事件，造成瀏覽器重整
      message: {
        title: "",
        subtitle: [],
        callbackOk: () => {},
        callbackCancel: () => {},
      },
      alreadyEditMessage: {
        title: '此投票已被編輯過，請於確定後再試一次。',
        subtitle: ['', ''],
        callbackOk: () => {
        },
      },
      NotExitMessage: {
        title: '投票活動不存在',
        subtitle: ['', ''],
        callbackOk: () => {
          this.$router.push({ name: 'voteList' });
        }
      },
    }
  },
  computed: {
    voteData() {
      return this.$store.state.GetVoteResult;
    },
    questionType() {
      if (this.$store.state.GetVoteResult &&
          this.$store.state.GetVoteResult.questionList &&
          this.$store.state.GetVoteResult.questionList.length > 0) {
        return this.$store.state.GetVoteResult.questionList[0].type;
      } else {
        return 1; //1文字 2梗圖模式
      }
    },
    isMultipleVote() {
      if (this.$store.state.GetVoteResult &&
          this.$store.state.GetVoteResult.questionList &&
          this.$store.state.GetVoteResult.questionList.length > 0) {
        return this.$store.state.GetVoteResult.questionList[0].isMultipleVote;
      } else {
        return false;
      }
    },
    isAdmin() {
      return this.$store.state.Auth.isAdmin;
    }
  },
  methods: {
    handleGetVoteData() {
      this.requestGetVoteResultV2({
        aliasID: 0,
        subjectID: this.subjectID,
        onSuccess: (res) => {
          if (this.$store.state.GetVoteResult &&
              this.$store.state.GetVoteResult.questionList &&
              this.$store.state.GetVoteResult.questionList.length > 0) {
            //this.optionList = this.$store.state.GetVoteResult.questionList[0].optionList;
            // deep copy『$store.state』物件，才能複製物件並修改
            var originalOptionList = this.$store.state.GetVoteResult.questionList[0].optionList;
            this.optionList = JSON.parse(JSON.stringify(originalOptionList));
          }
          setTimeout(() => {
            this.isDataLoad = true;
            this.hideLoading(loader);
          }, 500);
        },
        onFailed: (err) => {
          this.hideLoading(loader);
          if (err.response.data.error.message.indexOf("Data Already Deleted") > -1) {
            this.isDataLoad = true;
            this.message = this.NotExitMessage;
            this.$refs.alert.showPopup();
          }
        },
      });
    },
    optionCellChecked(optionID, isChecked) {
      // index => 處理 checkedOptionIds
      const indexChecked = this.checkedOptionIds.indexOf(optionID);

      // index => 處理 optionList
      const indexData = this.optionList.findIndex(option => option.optionID == optionID);

      // 判斷原本是否有被選過
      if (indexChecked > -1) {
        this.checkedOptionIds.splice(indexChecked, 1); // checkedOptionIds
        this.optionList[indexData].isVoted = false;    // optionList
      }

      if (isChecked) {
        // 單選時，取消其他選項
        if (!this.isMultipleVote) {
          this.checkedOptionIds = [];
          this.optionList.forEach(option => {
            option.isVoted = false;
          });
        }
        this.checkedOptionIds.push(optionID);      // checkedOptionIds
        this.optionList[indexData].isVoted = true; // optionList
      }
    },
    handleTouchStart(event) {
      this.carouselY = event.changedTouches[0].clientY;
    },
    handleTouchMove(event) {
      const nowY = event.changedTouches[0].clientY;
      if (nowY - this.carouselY > 0) {
        event.stopPropagation();
      }
    },
    handleTouchEnd(event) {
      const nowY = event.changedTouches[0].clientY;
      if (nowY - this.carouselY > 0) {
        event.stopPropagation();
      }
      this.carouselY = 0;
    },
  },
  mounted() {
    // 顯示 Loading
    loader = this.showLoading();

    this.token = process.env.VUE_APP_TOKEN_FOR_PREVIEW;
    this.tokenExpiredAt = 4102329600000;
    this.saveToken({ token: this.token, tokenExpiredAt: this.tokenExpiredAt });
    this.subjectID = this.$route.query.subjectid;

    // 取得 VoteData
    this.handleGetVoteData();
  },
  beforeDestroy() {
    this.hideLoading(loader);
    this.isLoading = false;
  },
};
</script>

<style lang="sass" scoped>
.txt
  margin: 17px 32px 20px 32px

.PageContainerPosition
  z-index: 2
  top: 150px
  position: absolute
  overflow-x: hidden
  @include media(xs) // RWD sass max-width: 320px
    top: 160px
    border-radius: 11px 11px 0px 0px

.picSquare
  width: 260px
  height: 275px
  border-radius: 20px
  overflow: hidden

.Select
  border: solid 2px #26d07c

.UnSelect
  border: solid 1px #ededed
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)

.fit-picture
  max-width: 100%
  height: 195px
  margin: 0 auto
  display: block

.picArea
  width: 100%
  height: 195px
  background-color: rgb(255, 255, 255)
  display: inline-block
  //background-size: 100% auto
  background-position: center
  background-repeat: no-repeat
  background-size: contain

.label
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.VueCarousel-slide
  position: relative
  min-height: 300px

.blankDiv
  height: 47px
  width: 100%

// 避免使用者在滑動 carousel 選項時，不小心上下滑動觸發頁面重整
.avoidRefresh
  width: 100vw       // 寬度設定為螢幕寬度滿版
  margin-left: -20px // 由於 PageContainer 有設定 padding-left:20px，這邊要補回去
  //background: #000 // 除錯查問題時，打開此註解
</style>

<template lang="html">
  <div>
    <div class="page-margin" ref="page" :style="'overflow:scroll; height:' + pageHeight">
      <!-- #region 無留言空白頁 -->
      <div v-if="!isLoading && commentCount <= 0" class="flex justify_content_center align-items no-comment">
        <div class="horizontal-line"></div>
        <div class="welcome-txt">快來留言吧!</div>
        <div class="horizontal-line"></div>
      </div>
      <!-- #endregion 無留言空白頁 -->

      <!-- #region 留言頁 -->
      <div class="page-margin" v-else-if="!isLoading">
        <div class="comment-menu flex align-items justify-end">
          <div :class="['comment-menu-text', commentSort == 1 ? 'comment-menu-text-active' : '']" @click="switchSort(1)">熱門</div>
          <div class="line"></div>
          <div :class="['comment-menu-text', commentSort == 2 ? 'comment-menu-text-active' : '']" @click="switchSort(2)">最新</div>
        </div>

        <!-- 一則留言 comment-mr -->
        <Comment
          class="comment-mr"
          fromPage="CommentList"
          :newsMainBannerId="newsMainBannerId"
          :subjectID="subjectID"
          :aliasID="aliasID"
          v-for="comment in commentList"
          :comment="comment"
          :key="comment.comment.commentId"
          :id="comment.comment.commentId"
          :myReaction="comment.myReaction"
          :pageInfoData="pageInfoData"
          @sendReply="sendReply"
          @showReportComment="showReportComment"
          @deleteFailed="deleteFailed" 
          ref="commentComponent"
        />

        <!-- 一則回覆的留言 reply-comment -->
        <!--<Comment class="reply-comment" />-->

        <scroll-loader :loader-method="loadMore" :loader-disable="disable"></scroll-loader>
      </div>
      <!-- #endregion 留言頁 -->
    </div>

    <!-- 輸入留言/輸入回覆 -->
    <Commenting
      class="commenting"
      ref="commenting"
      :newsMainBannerId="newsMainBannerId"
      :lotterType="lotterType"
      :lotterId="lotterId"
      :aliasID="aliasID"
      :replyAuthorName="replyAuthorName"
      @contentChange="watchCommentingClientHeight"
      @overSize="overSize"
      @closeReply="closeReply"
      @sendCallback="sendCallback"
    />

    <!-- 檢舉留言 -->
    <div v-if="showReport" class="mask"></div>
    <ReportComment
      v-if="showReport"
      class="report-comment"
      :newsMainBannerId="newsMainBannerId"
      :aliasID="aliasID"
      :lotterType="lotterType"
      :lotterId="lotterId"
      :reportCommentId="reportCommentId"
      @hideReportComment="hideReportComment"
      @operationSucess="operationSucess" 
      @operationFailed="operationFailed"
    />

    <toast ref="toast" />
  </div>
</template>

<script>
import { passEvent } from '@/utils/trackingHelper';
import { loading, auth, comment } from '@/mixins';
// 留言列表頁的『一列』
import Comment from '../components/Comment.vue';
import Commenting from '../components/Commenting.vue';
import ReportComment from '../components/ReportComment.vue';
import Toast from '../../components/Toast.vue';

// Loading
var loader;

// vue-scroll-loader
import Vue from 'vue';
import ScrollLoader from 'vue-scroll-loader';
Vue.use(ScrollLoader);

export default {
  name: 'CommentList',
  mixins: [loading, auth, comment],
  components: {
    Comment,
    Commenting,
    ReportComment,
    Toast,
  },
  data() {
    return {
      newsMainBannerId: 0,
      subjectID: 0,
      lotterType: 1, // 1:留言; 2:回覆
      lotterId: '',  // subjectID(留言) 或 commentId(回覆)
      reportCommentId: '',
      replyAuthorName: '',
      isLoading: true,
      showReport: false,
      pageHeight: 'auto',
      size: 30,
      page: 1,
      disable: true,
    }
  },
  computed: {
    commentSort() {
      return this.$store.state.Comment.sort;
    },
    commentCount() {
      return this.$store.state.Comment.count;
    },
    commentList() {
      if (this.$store.state.Comment && this.$store.state.Comment.list) {
        return this.$store.state.Comment.list;
      } else {
        return [];
      }
    },
    listDataByPopular() {
      if (this.$store.state.Comment && this.$store.state.Comment.listDataByPopular) {
        return {
          listCommentIds: this.$store.state.Comment.listDataByPopular.listCommentIds,
        };
      } else {
        return {
          listCommentIds: [],
        };
      }
    },
    listDataByNewest() {
      if (this.$store.state.Comment && this.$store.state.Comment.listDataByNewest) {
        return {
          next: this.$store.state.Comment.listDataByNewest.next,
        }
      } else {
        return {
          next: '',
        };
      }
    },
    totalPages() {
      return Math.ceil(this.commentCount / this.size);
    },
    pageInfoData() {
      return  {
        page: 'comment',
        type: 'poll',
        uuid: this.subjectID,
      }
    }
  },
  methods: {
    showReportComment(lotterId, lotterType, reportCommentId) {
      this.lotterId = lotterId;
      this.lotterType = lotterType;
      this.reportCommentId = reportCommentId;
      this.showReport = true;
    },
    hideReportComment() {
      this.showReport = false;
    },
    operationSucess() {
      this.$refs.toast.show('檢舉已送出', 2000);
    },
    operationFailed() {
      this.$refs.toast.show('操作失敗', 2000);
    },
    deleteFailed() {
      this.$refs.toast.show('操作失敗', 2000);
    },
    watchCommentingClientHeight() {
      this.$nextTick(function () {
        const commentingClientHeight = this.$refs.commenting.$el.clientHeight;
        this.pageHeight = `calc(100vh - ${commentingClientHeight}px - 10px)`;
      })
    },
    overSize() {
      this.$refs.commenting.hideKeyboard();
      this.$refs.toast.show('字數超過上限\n僅保留5000字', 3500);
    },
    switchSort(sort) {
      this.saveSort({ sort: sort });
      this.disable = true;
      this.page = 1;
      this.handleGetCommentCount();

      // [event trace] 12-1 點擊留言排序
      const eventItems = [
        {
          type: 'clickEvent',
          eventId: '1049',
          event: 'comment_content_page_item_click',
          pageInfo: {
            page: 'comment',
            type: 'poll',
            uuid: this.subjectID,
            provider: '',
            cat0: [],
            cat1: [],
            cat2: [],
            tag: [],
          },
          clickInfo: {
            type: 'sort',
            name: this.commentSort == 1 ? 'pop' : 'latest',
          },
        },
      ];
      passEvent(eventItems);
    },
    loadMore() {
      if (this.commentSort == 1) {
        if (this.page == 1) {
          this.handleGetCommentsByPopular();
        } else {
          this.handleGetCommentsByIds();
        }
      } else {
        this.handleGetCommentsByNewest();
      }
    },
    handleGetCommentCount() {
      this.requestGetCommentCount({
        id: 'vote',
        entityType: 'vote',
        entityId: this.subjectID,
        onSuccess: (res) => {
          this.hideLoading(loader);
          this.isLoading = false;

          if (this.commentCount > 0) {
            // 觸發 load more
            this.disable = false;
          } else {
            this.disable = true;
          }
        },
        onFailed: (err) => {
          this.hideLoading(loader);
          this.isLoading = false;
        },
      });
    },
    handleGetCommentsByPopular() {
      this.requestGetCommentsByPopular({
        id: 'vote',
        entityId: this.subjectID,
        entityType: 'vote',
        aliasId: this.aliasID,
        show: this.size,
        onSuccess: (res) => {
          this.disable = this.page >= this.totalPages;
          if (this.page < this.totalPages) {
            this.page += 1;
          }
        },
        onFailed: (err) => {
        },
      });
    },
    handleGetCommentsByNewest() {
      this.requestGetCommentsByNewest({
        id: 'vote',
        entityType: 'vote',
        entityId: this.subjectID,
        aliasId: this.aliasID,
        prev: '',
        sort: 1,
        show: this.size,
        next: this.listDataByNewest.next,
        onSuccess: (res) => {
          this.disable = this.page >= this.totalPages;
          if (this.page < this.totalPages) {
            this.page += 1;
          }

          // [event trace] 12-8 留言頁面最新留言排序曝光
          let impressionInfo = res.data.listComments.map(x => ({
            uuid: x.comment.commentId,
            type: 'comment',
          }));
          const eventItems = [
            {
              type: 'impressionEvent',
              eventId: '1053',
              event: 'comment_content_page_impression',
              pageInfo: {
                page: 'comment',
                type: 'poll',
              },
              impressionInfo: impressionInfo,
              isAdditiveView: true,
            },
          ];
          passEvent(eventItems);
        },
        onFailed: (err) => {
        },
      });
    },
    handleGetCommentsByIds() {
      const commentIds = this.listDataByPopular.listCommentIds
                         .slice((this.page - 1) * this.size, this.page * this.size);
      this.requestGetCommentsByIds({
        newsMainBannerId: this.newsMainBannerId,
        commentIds: commentIds,
        aliasId: this.aliasID,
        entityId: this.subjectID,
        onSuccess: (res) => {
          this.disable = this.page >= this.totalPages;
          if (this.page < this.totalPages) {
            this.page += 1;
          }
        },
        onFailed: (err) => {
        },
      });
    },
    scrollToLast() {
      this.disable = false;
      const scrollToLast = setInterval(() => {
        if (this.disable) {
          clearInterval(scrollToLast);
        }
        this.$refs.page.scrollTo(0, this.$refs.page.scrollHeight);
      }, 500);
    },
    scroll(id) {
      const page = this.$refs.page;
      const object = document.getElementById(id);
      var top = object.offsetTop;
      var height = object.clientHeight;
      page.scrollTo({
        top: top + height - page.clientHeight + 30,
        left: 0,
        behavior: 'smooth',
      });
    },
    sendReply(commentId, author) {
      this.lotterType = 2;
      this.lotterId = commentId;
      this.replyAuthorName = author.aliasId == this.aliasID ? '自己' : author.name;
      this.$refs.commenting.showKeyboard();
      setTimeout(() => {
        this.scroll(commentId);
      }, 100);
    },
    closeReply() {
      this.lotterType = 1;
      this.lotterId = this.subjectID;
      this.replyAuthorName = '';
    },
    sendCallback() {
      if (this.lotterType == 1) {
        // 留言送出
        if (this.commentSort == 1) {
          //this.switchSort(1); // 當送出後，要重取資料時，執行此行
          this.$refs.page.scrollTo(0, this.$refs.page.scrollHeight);
        } else {
          this.$refs.page.scrollTo(0, 0);
        }

        // [event trace] 12-2 送出留言或回覆
        const eventItems = [
          {
            type: 'clickEvent',
            eventId: '1049',
            event: 'comment_content_page_item_click',
            pageInfo: {
              page: 'comment',
              type: 'poll',
              uuid: this.subjectID,
              provider: '',
              cat0: [],
              cat1: [],
              cat2: [],
              tag: [],
            },
            clickInfo: {
              uuid: '',
              type: 'comment',
              name: 'sent',
            },
          },
        ];
        passEvent(eventItems);
      } else {
        // 回覆送出
        let index = 0;
        for (let i = 0; i < this.commentList.length; i += 1) {
          if (this.commentList[i].comment.commentId === this.lotterId) {
            index = i;
            break;
          }
        }

        this.$refs.commentComponent[index].isShowReplies = true;
        this.$refs.commentComponent[index].handleGetReplies();

        // [event trace] 12-2 送出留言或回覆
        const eventItems = [
          {
            type: 'clickEvent',
            eventId: '1049',
            event: 'comment_content_page_item_click',
            pageInfo: {
              page: 'comment',
              type: 'poll',
              uuid: this.subjectID,
              provider: '',
              cat0: [],
              cat1: [],
              cat2: [],
              tag: [],
            },
            clickInfo: {
              uuid: this.lotterId,
              type: 'reply',
              name: 'sent',
            },
          },
        ];
        passEvent(eventItems);
      }
    },
  },
  mounted() {
    // 顯示 Loading
    loader = this.showLoading();

    this.subjectID = this.$route.query.subjectid;
    this.lotterId = this.subjectID;

    // 取得 newsMainBannerId
    this.newsMainBannerId = this.$route.query.newsMainBannerId;

    // 取得『使用者 Access Token』+『使用者 Open Id』+『使用者 Alias Id』
    this.getTokenAndOpenidAndAliasid((res) => {
      this.watchCommentingClientHeight();

      this.deleteCommentList();
      this.switchSort(this.commentSort);
    });

    if (this.$route.query.isShowKeyboard == 'Y') {
      setTimeout(() => {
        this.$refs.commenting.showKeyboard();
      }, 1000);

      // 新增留言(點擊)＿熱門排序：至留言頁內容最下方
      if (this.commentSort == 1) {
        this.scrollToLast();
      }
    }

    // 由前一頁點擊回覆時，自動 scroll 至該留言
    const commentId = this.$route.query.commentId;
    if (commentId) {
      const authorId = this.$route.query.authorId;
      const authorName = this.$route.query.authorName;
      const author = {
        aliasId: authorId,
        name: authorName,
      }
      setTimeout(() => {
        this.sendReply(commentId, author);
      }, 1000);
    }

    // [event trace] 12-6 點擊查看更多留言開啟留言頁面
    const eventItems = [
      {
        type: 'pageViewEvent',
        eventId: '1050',
        event: 'comment_page_view',
        pageInfo: {
          page: 'comment',
          type: 'poll',
        },
        isAdditiveView: false,
      },
    ];
    passEvent(eventItems);
  },
  beforeDestroy() {
    this.hideLoading(loader);
    this.isLoading = false;
  },
};
</script>

<style lang="sass" scoped>
.page-margin
  //margin: 0px 16px

.commenting
  z-index: 3
  position: fixed
  bottom: 0px
  background: #ffffff

.no-comment
  margin-top: 50px

.welcome-txt
  color: #999999
  font-family: PingFangTC-Regular
  font-size: 13px
  letter-spacing: 0.4px

.line
  background: #cccccc
  height: 16px
  width: 1px
  margin: 0px 16px

.horizontal-line
  background: #999999
  height: 1px
  width: 24px
  margin: 0px 15px

.comment-menu
  margin: 12px
  margin-right: 16px

.comment-menu-text
  color: #515151
  font-family: NotoSansTC-Medium
  font-size: 15px
  letter-spacing: 0.1px
  line-height: 20px

.comment-menu-text-active
  color: #26d07c

.reply-comment
  margin: 24px 0px 24px 38px

.comment-mr
  margin: 24px 0px 24px 16px

.report-comment
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  background: #ffffff
  border-radius: 30px
  padding: 20px
  z-index: 3

.mask 
  position: absolute
  top: 0px
  z-index: 3
  background: rgba(0, 0, 0, 0.28) 
  width: 100%
  height: 100%
</style>

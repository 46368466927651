<template lang="html">
  <div class="mb_12 textArea areaHeight flex justify-start justify_content_center" @click="checked">
    <!-- 狀態變動 置換兩個svg圖  -->
    <!--svg 打勾圈 isVote = true 時-->
    <img class="align-self-center circle" src="@/assets/images/selectorOkMPrimary.png" alt="" v-if="isCheck">
      
    <!--svg 預設圈-->
    <img class="align-self-center circle" src="@/assets/images/selectorDefaultMWhite.png" alt="" v-if="!isCheck">

    <!--選項文字-->
    <p class="title_15px align-self-center py_20 mr_20">
     {{ option.title }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'OptionCell',
  props: ['option'],
  watch: {
    'option.isVoted': function() {
      this.isCheck = this.option.isVoted;
    },
    deep: true
  },
  data() {
    return {
      isCheck: false,
    }
  },
  methods: {
    checked() {
      this.isCheck = !this.isCheck;
      this.$emit('checkedCallback', this.option.optionID, this.isCheck);
    },
  },
}
</script>

<style lang="sass" scoped>
.areaHeight
  min-height: 60px
  margin: 0 auto

.circle
  min-width: 30px
  max-width: 30px
  margin: 0px 12px
</style>

<!--綠框 看其他投票 按鈕-->
<template lang="html">
  <div class="roundBtnContainer">
    <div class="roundBtnActive">
      <div class="btnText">看其他投票</div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'RoundButtonSeeOthers',

};
</script>

<style lang="sass" scoped>
.roundBtnContainer
  height: 60px
  width: 100%
  text-align: center
  position: relative

.roundBtnActive
  width: 100%
  max-width: 343px
  height: 40px
  border-radius: 16px
  background-color: #fff
  border: solid 1px #26d07c
  margin-top: 10px
  text-align: center
  display: inline-block
  position: relative

.btnText
  color: #26d07c
  font-size: 15px
  line-height: 40px
</style>

<template lang="html">
  <div :class="!subject.isEnded ? 'VoteListTableViewCell py_12 flex' : 'VoteListTableViewCell flex mt_24 pb_20'">
    <!--左邊區塊: 100% - 85px-->
    <div class="left-area flex align-items">
      <div class="flex align-center flex-col">
        <div class="title_15px">
        {{ subject.subjectTitle }}
        </div>
        <div class="subtitle_13px">
          <p>已有{{ subject.votePeopleCount | people }}人參與投票</p>
        </div>
        <div v-if="!subject.isEnded">
          <div class="flex">
            <div class="flex justify_content_center  countDownBtn">
              <p v-if="subject.dueDays >= 1" class="text_11px btn_text">倒數 {{ subject.dueDays }} 天</p>
              <p v-else-if="subject.dueDays >= 0" class="text_11px btn_text">即將結束</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!--右邊區塊: 固定 85px-->
    <div class="right-area flex align-items">
      <div v-if="!subject.isEnded && !subject.isVoted">
        <VoteNowButton class="right_0 align-center align-self-center" @click.native="goVote" />
      </div>
      <div v-if="subject.isEnded">
        <VoteResultButton class="right_0 align-center align-self-center" @click.native="goResult" />
      </div>
      <div v-if="!subject.isEnded && subject.isVoted">
        <VotedButton class="right_0 align-center align-self-center" @click.native="goResult" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VoteNowButton from '../../components/VoteNowButton.vue';
import VotedButton from '../../components/VotedButton.vue';
import VoteResultButton from '../../components/VoteResultButton.vue';

export default {
  props: ['subject'],
  name: 'VoteListTableViewCell',
  components: {
    VoteNowButton,
    VotedButton,
    VoteResultButton
  },
  methods: {
    goVote() {
      this.$emit('goVote', this.subject);
    },
    goResult() {
      this.$emit('goResult', this.subject);
    }
  }
};

// 使用 Vue.filter 把數字加上千分號
Vue.filter('people', function(num) {
    const parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
});
</script>

<style lang="sass" scoped>
.right_0
  position: relative
  right: 0px

.h_35
  height: 35px

.h_25
  height: 25px

.mt_12
  margin-top: 12px

.VoteListTableViewCell
  width: 100%
  border-bottom: 0.5px solid rgb(153, 153, 153)
  position: relative

.countDownBtn
  width: 65px
  height: 20px
  background: rgb(153, 153, 153)
  border-radius: 24px
  line-height: 14px

.btn_text
  height: 14px
  margin: auto

.left-area
  width: calc(100% - 85px)

.right-area
  width: 85px
  text-align: center
  margin: auto
</style>

<template lang="html">
  <div class="flex space-between">
    <div @click="setPinnedInChat" class="flex">
      <!--svg 打勾圈 isPinned = true 時-->
      <svg v-if="isSelfPinned" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="11" fill="white"/>
        <mask id="mask10" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="22">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15 26C21.0751 26 26 21.0751 26 15C26 8.92487 21.0751 4 15 4C8.92487 4 4 8.92487 4 15C4 21.0751 8.92487 26 15 26ZM19.7828 13.1314C20.0171 12.8971 20.0171 12.5172 19.7828 12.2829L19.2171 11.7172C18.9828 11.4829 18.6029 11.4829 18.3686 11.7172L14 16.0858L11.6313 13.7172C11.397 13.4829 11.0171 13.4829 10.7828 13.7172L10.2171 14.2829C9.9828 14.5172 9.9828 14.8971 10.2171 15.1314L13.1514 18.0657C13.6201 18.5343 14.3799 18.5343 14.8485 18.0657L19.7828 13.1314Z" fill="#26D07C"/>
        </mask>
        <g mask="url(#mask10)">
          <rect width="30" height="30" fill="#26D07C"/>
        </g>
      </svg>

      <!--svg 預設圈-->
      <svg v-else class="align-self-center  svg_circle"  xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">
        <path fill="#fff" stroke="#CCC" d="M25.5 15c0 5.799-4.701 10.5-10.5 10.5S4.5 20.799 4.5 15 9.201 4.5 15 4.5 25.5 9.201 25.5 15z"/>
      </svg>

      <p class="settingTilie title_15px align-self-center">
        設為公告
      </p>
    </div>
    <!-- Alert/Confirm -->
    <iOSAlert ref="alert" :message="message" />
    <svg @click="attemptToMenu" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask11" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="13" width="20" height="4">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.2002 17C8.30476 17 9.2002 16.1046 9.2002 15C9.2002 13.8954 8.30476 13 7.2002 13C6.09563 13 5.2002 13.8954 5.2002 15C5.2002 16.1046 6.09563 17 7.2002 17ZM17.0002 15C17.0002 16.1046 16.1048 17 15.0002 17C13.8956 17 13.0002 16.1046 13.0002 15C13.0002 13.8954 13.8956 13 15.0002 13C16.1048 13 17.0002 13.8954 17.0002 15ZM24.8002 15C24.8002 16.1046 23.9047 17 22.8002 17C21.6956 17 20.8002 16.1046 20.8002 15C20.8002 13.8954 21.6956 13 22.8002 13C23.9047 13 24.8002 13.8954 24.8002 15Z" fill="#262626"/>
      </mask>
      <g mask="url(#mask11)">
        <rect width="30" height="30" fill="#333333"/>
      </g>
    </svg>
    <!-- Context Menu -->
    <iOSContextMenu class="position_absolute" ref="contextMenu" :menus="menus" />
  </div>
</template>

<script>
import iOSAlert from '../../components/iOSAlert.vue';
import iOSContextMenu from '../../components/iOSContextMenu.vue';
import { deleteVote, endVote, getIsPinned, setPinned } from '@/mixins';

export default {
  name: 'SubjectSettingCell',
  mixins: [deleteVote, endVote, getIsPinned, setPinned],
  props: ['subjectID' ,'aliasID', 'chatID', 'isPinned', 'votePeopleCount','isEnded'],
  watch: {
    isPinned: function() {
      this.isSelfPinned = this.isPinned;
    },
    isEnded: function() {
      if (this.isEnded) {
        this.menus = [
          {
            title: '刪除',
            callback: () => {
              this.message = this.messageCancelVote;
              this.$refs.alert.showPopup();
            }
          },
        ]
      }
    },
  },
  data() {
    return {
      isSelfPinned: this.isPinned,
      isHasPinnedInChat: false,
      message: {
        title: '',
        subtitle: [''],
        callbackOk: () => {},
        callbackCancel: () => {},
      },
      messageHasPinnedInChat: {
        title: '確定將投票設為公告嗎？',
        subtitle: ['此投票將覆蓋先前的投票公告。'],
        callbackOk: () => {
          this.handleSetPinned(true, () => {
            this.isSelfPinned = true;
          });
        },
        callbackCancel: () => {
        },
      },
      messageCancelPinned: {
        title: '確定取消公告嗎？',
        subtitle: ['投票公告將會從聊天室上方移除。'],
        callbackOk: () => {
          this.handleSetPinned(false, () => {
            this.isSelfPinned = false;
          });
        },
        callbackCancel: () => {
        },
      },
      messageCancelVote: {
        title: '確定要刪除投票？',
        subtitle: ['刪除此投票，所有內容將會被移除。'],
        callbackOk: () => {
          this.requestDeleteVote({
              subjectID: this.subjectID,
              modifiedUser: this.aliasID,
              onSuccess: (res) => {
                this.$router.push({ name: 'voteList' })
              },
              onFailed: (err) => {
              },
            });
        },
        callbackCancel: () => {
        },
      },
      messageEndVote: {
        title: '確定要結束投票？',
        subtitle: ['此投票將結束並進行結算。'],
        callbackOk: () => {
          this.requestEndVote({
              subjectID: this.subjectID,
              modifiedUser: this.aliasID,
              onSuccess: (res) => {
                this.$router.push({ name: 'voteList' })
              },
              onFailed: (err) => {
              },
            });
        },
        callbackCancel: () => {
        },
      },
      messageUnableEdit: {
        title: '已有人投票，無法編輯此投票',
        subtitle: [''],
        callbackOk: () => {
          this.requestEndVote({
              onSuccess: (res) => {
              },
              onFailed: (err) => {
              },
            });
        },
      },
      menus: [
        {
          title: '編輯',
          callback: () => {
            if(this.votePeopleCount < 1 ){
              this.$router.push({ name: 'editVote', query: { 'subjectid': this.subjectID } })
            }else{
              this.message = this.messageUnableEdit;
              this.$refs.alert.showPopup();
            }
          }
        },
        {
          title: '刪除',
          callback: () => {
            this.message = this.messageCancelVote;
            this.$refs.alert.showPopup();
          }
        },
        {
          title: '結束投票',
          callback: () => {
            this.message = this.messageEndVote;
            this.$refs.alert.showPopup();
          }
        },
      ],
    }
  },
  methods: {
    setPinnedInChat() {
      if (!this.isSelfPinned) {
        this.handleGetIsPinned(() => {
          if (this.$store.state.GetIsPinned.isHasPinned) {
            this.message = this.messageHasPinnedInChat;
            this.$refs.alert.showPopup();
          } else {
            this.handleSetPinned(true, () => {
              this.isSelfPinned = true;
            });
          }
        });
      } else {
        this.message = this.messageCancelPinned;
        this.$refs.alert.showPopup();
      }
    },
    attemptToMenu() {
      this.$refs.contextMenu.showPopup();
    },
    handleGetIsPinned(success) {
      this.requestGetIsPinned({
        chatID: this.chatID,
        onSuccess: (res) => {
          success(res);
        },
        onFailed: (err) => {
        },
      });
    },
    handleSetPinned(isPinned, success) {
      this.requestSetPinned({
        chatID: this.chatID,
        subjectID: this.subjectID,
        isPinned: isPinned,
        onSuccess: (res) => {
          success(res);
        },
        onFailed: (err) => {
        },
      });
    }
  },
  components: {
    iOSAlert,       // Alert/Confirm
    iOSContextMenu, // Context Menu
  },
  mounted() {
    if (this.isEnded) {
      this.menus = [
        {
          title: '刪除',
          callback: () => {
            this.message = this.messageCancelVote;
            this.$refs.alert.showPopup();
          }
        },
      ]
    }
  },
};
</script>

<style lang="sass" scoped>
.settingTilie
  margin: 0 auto
  align-items: center
  font-weight: bold

</style>

import { render, staticRenderFns } from "./OptionCell.vue?vue&type=template&id=a2051058&scoped=true&lang=html&"
import script from "./OptionCell.vue?vue&type=script&lang=js&"
export * from "./OptionCell.vue?vue&type=script&lang=js&"
import style0 from "./OptionCell.vue?vue&type=style&index=0&id=a2051058&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2051058",
  null
  
)

export default component.exports
<!-- 留言筆數 -->
<template lang="html">
  <div class="count">
    留言 ({{ count }})
  </div>
</template>

<script>
export default {
  name: 'CommentCount',
  props: ['count'],
  methods: {
  },
  mounted() {
  },
};
</script>

<style lang="sass" scoped>
.count
  font-family: Noto Sans TC
  font-style: normal
  font-weight: 500
  font-size: 15px
  line-height: 20px
  letter-spacing: 0.1px
  color: #333333
  margin: 25px 0px
</style>

import { Base64 } from 'js-base64';
import store from '@/store';
import { checkAuth, getTokenFromLocalStorage } from '@/utils/auth';

export default {
  data() {
    return {
      officailToken: process.env.VUE_APP_OFFICAIL_TOKEN,
      officailAccountId: process.env.VUE_APP_OFFICAIL_ACCOUNT_ID,
      officailClientId: process.env.VUE_APP_OFFICAIL_CLIENT_ID,
      getOpenidUrl: process.env.VUE_APP_GET_OPENID_URL,
      token: '',
      tokenExpiredAt: 0,
      chatID: 0,
      chatEndTime: 0,
      aliasID: 0,
      openID: '',
    };
  },
  methods: {
    // #region [initJsSdk]: 初始化 jsSDK
    initJsSdk() {
      window.BGO.init({
        token: this.officailToken,
        official_account_id: this.officailAccountId,
      });
    },
    // #endregion
    // #region [getTokenAndOpenidAndAliasid]: 取得『使用者 Access Token』+『使用者 Open Id』+『使用者 Alias Id』
    getTokenAndOpenidAndAliasid(callback) {
      const self = this;
      // 取得 使用者 Access Token
      this.getAccessToken()
        .then(() => {
          // 取得 使用者 Open Id
          self.getOpenId(self.token)
            .then(() => {
              this.getAliasId({
                openID: this.openID,
                onSuccess: () => {
                  this.aliasID = store.state.Auth.aliases.filter(x => x.aliasType === 1)[0].aliasID;

                  // 此時已經取到 token, openID, aliasID
                  if (process.env.NODE_ENV !== 'production') {
                    /* eslint-disable-next-line */
                    console.log(`token: ${store.state.Auth.token}`);
                    /* eslint-disable-next-line */
                    console.log(`openID: ${this.openID}`);
                    /* eslint-disable-next-line */
                    console.log(`aliasID: ${this.aliasID}`);
                  }

                  // callback
                  callback();
                },
                onFailed: (err) => {
                  /* eslint-disable-next-line */
                  console.log(err);
                },
              });
            });
        });
    },
    // #endregion
    // #region [getTokenAndAliasidAndChatid]: 同時取得『使用者 AccessToken > OpenId』+『ChatID』
    getTokenAndAliasidAndChatid(callback) {
      Promise.all([this.getAccessToken(), this.getChatId()])
        .then((res) => {
          // 此時已經取到 token, chatID, aliasID
          if (process.env.NODE_ENV !== 'production') {
            /* eslint-disable-next-line */
            console.log(`token: ${store.state.Auth.token}`);
            /* eslint-disable-next-line */
            console.log(`chatID: ${this.chatID}`);
            /* eslint-disable-next-line */
            console.log(`aliasID: ${this.aliasID}`);
          }

          // callback
          callback(res);
        });
    },
    // #endregion
    // #region [getAccessToken]: 取得『使用者 Access Token』
    getAccessToken() {
      const self = this;
      return new Promise((resolve) => {
        const tokenData = getTokenFromLocalStorage();
        if (tokenData && checkAuth(tokenData)) {
          const tokenJson = JSON.parse(tokenData);
          tokenJson.t = Base64.decode(tokenJson.t);
          tokenJson.e = Base64.decode(tokenJson.e);
          self.token = tokenJson.t;
          self.tokenExpiredAt = tokenJson.e;

          // 取 state 內資料
          const stateTokenData = store.state.Auth;
          if (!stateTokenData.token || stateTokenData.token === '') {
            self.saveToken({ token: self.token, tokenExpiredAt: self.tokenExpiredAt });
          }

          resolve();
        } else {
          window.BGO.get_me_openid_access_token(self.officailClientId, '', (data) => {
            // 設定 token
            self.token = data.access_token;
            self.tokenExpiredAt = data.access_token_expired_at;

            self.saveToken({ token: self.token, tokenExpiredAt: self.tokenExpiredAt });

            resolve();
            /*
            // 取得 使用者 Open Id
            self.getOpenId(self.token)
              .then((success) => {
                resolve(success);
              })
              .catch((fail) => {
                reject(fail);
              });
            */
          });
        }
      });
    },
    // #endregion
    // #region [getOpenId]: 取得『使用者 Open Id』
    getOpenId(token) {
      return new Promise((resolve, reject) => {
        if (!this.openID && this.openID !== '') {
          resolve();
          return;
        }

        this.getOpenidUrl += token;

        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = () => {
          if (xhttp.readyState === 4) {
            if (xhttp.status === 200) {
              const res = xhttp.responseText.split(',')[4].split(':')[1];
              this.openID = res;
              resolve();
            } else {
              reject();
            }
          }
        };

        xhttp.open('GET', this.getOpenidUrl, true);
        xhttp.send();
      });
    },
    // #endregion
    // #region [getAliasId]: 取得『使用者 Alias Id』
    getAliasId({
      openID = '',
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('GET_ALIASES', {
        openID,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    // #endregion
    // #region [getChatId]: 取得『取得 ChatID、AliasID』
    getChatId() {
      return new Promise((resolve) => {
        if (!this.chatID && this.chatID !== 0
            && !this.aliasID && this.aliasID !== 0
            && !this.chatEndTime && this.chatEndTime !== 0) {
          resolve();
          return;
        }

        window.BGO.get_campaign_chat_info((data) => {
          // 設定全域變數 _chatId，提供各頁面使用
          this.chatID = data.chat_id;
          this.aliasID = data.alias_id;
          this.chatEndTime = data.end_time;
          resolve();
        });
      });
    },
    // #endregion
    // #region [saveToken]: 將『使用者 OpenId Access Token』存入至 state 內
    saveToken({
      token,
      tokenExpiredAt,
    }) {
      this.$store.dispatch('USER_TOKEN', {
        token,
        tokenExpiredAt,
      });
    },
    // #endregion
    // #region [getIsAdmin]: 判斷是否有管理者權限
    getIsAdmin({
      chatID = 0,
      aliasID = 0,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('GET_IS_VOTE_ADMIN', {
        chatID,
        aliasID,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
    // #endregion
  },
  mounted() {
    this.initJsSdk();
  },
};

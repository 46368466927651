<template lang="html">
  <div v-if="isShow" class="toast">
    <div class="text">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  data() {
    return {
      message: '',
      isShow: false,
    }
  },
  methods: {
    show(text, time) {
      this.message = text;
      this.isShow = true;
      setTimeout(() => {
        this.isShow = false;
      }, time);
    },
  },
};
</script>

<style lang="sass" scoped>
.toast
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 10
  background: rgba(0, 0, 0, 0.8)
  border-radius: 20px
  min-width: 116px
  max-width: 375px
  white-space: pre-wrap
  padding: 12px 28px

.text
  color: #ffffff
  font-family: PingFangTC-Medium
  font-size: 15px
  line-height: 20px
  letter-spacing: 0.1px
  text-align: center
</style>

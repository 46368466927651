<!-- 一則留言 -->
<template lang="html">
  <div class="flex align-center">
    <!-- 左邊頭像區 -->
    <div class="headshot" :style="{ 'background-image': reply.reply.author.avatar ? 'url(\'' + reply.reply.author.avatar + '\')' : '', 'background-size': 'cover' }"></div>
    <!-- #region 右邊文字區 -->
    <div class="right">
      <div class="name">{{ reply.reply.author.name }}</div>
      <div class="context" v-html="textReplaceToATag(reply.reply.content)"></div>
      <!-- link 顯示 og -->
      <LinkOgBlock :comment="reply" :type="'reply'" />
      <!-- #region 留言功能互動列 -->
      <div class="flex space-between">
        <!-- #region 文字互動 -->
        <div class="flex justify-start">
          <div class="time">{{ toTimeSinceString(reply.reply.createdTime) }}</div>
          <div 
            class="like" 
            :style="myReactionTextStyle"
            @touchstart="(e) => touchStartLogic(e)"
            @touchend="touchEndLogic(saveMyReactionType === 0 ? 1 : 0)"
            @touchmove="(e) => touchMoveLogic(e)"
          >
            {{ displayMyReaction }}
          </div>
          <div class="reply" @click="sendReply">回覆</div>
          <div v-if="reply.canDelete" class="reply" @click="deleteReply">刪除</div>
          <div v-if="reply.canReport" @click="showReportComment(reply.reply.replyId, 2)" class="report">檢舉</div>
        </div>
        <!-- #region 最右邊，顯示 emoji + 數量 ( emoji 最多顯示三個 ) -->
        <div v-if="reactionCount > 0" class="flex litter-emoji-block">
          <div class="litter-emoji-container flex align-center">
            <div class="a-litter-emoji" v-for="(imageName, index) in imageDataLogic" :key="index">
              <img class="emoji-icon" :src="require(`@/assets/images/emoji/${imageName}.png`)">
            </div>
          </div>
          <div class="thumb-counting">
            {{ reactionCount }}
          </div>
        </div>
      </div>
      <!-- #endregion 留言功能互動列 -->
    </div>

    <!-- 長按五種表情後的彈窗 -->
    <CustomDialog
      :isDisplayDialog.sync="isDisplayDialog"
      :text="displayTouchMoveText"
      :imageSrc="require(`@/assets/images/emoji/${displayTouchMoveImage}.png`)"
    />

    <!-- 五種表情符號 -->
    <LongPressEmojiBlock 
      :id="`replyEmojiBLock-${reply.reply.replyId}`" 
      :pageInfoData="pageInfoData"
      :commentId="reply.reply.replyId"
      :saveMyReactionType="saveMyReactionType"
      :zoomInDirection="zoomInDirection"
      :inEmojiTouching="inEmojiTouching"
      @clickReaction="clickReaction"
      @updateDisplayMyReaction="updateDisplayMyReaction"
    />
    <!-- #endregion 右邊文字區 -->
    <iOSAlert ref="alert" :message="message" />
  </div>
</template>

<script>
import { passEvent } from '@/utils/trackingHelper';
import { timeSince } from '@/utils/timeHelper';
import { comment } from '@/mixins';

import iOSAlert from '../../components/iOSAlert.vue';
import LongPressEmojiBlock from '../../components/LongPressEmojiBlock.vue';
import LinkOgBlock from '../../components/LinkOgBlock.vue';
import CustomDialog from '../../components/shared/CustomDialog.vue';

export default {
  name: 'Reply',
  mixins: [comment],
  props: ['newsMainBannerId', 'subjectID', 'aliasID', 'commentId', 'reply', 'fromPage',
    'pageInfoData'],
  components: {
    iOSAlert,
    LongPressEmojiBlock,
    LinkOgBlock,
    CustomDialog
  },
  data() {
    return {
      message: {
        title: '',
        subtitle: [],
        callbackOk: () => {},
        callbackCancel: () => {},
      },
      displayMyReaction: '',
      myReactionTextStyle: '',
      saveMyReactionType: '',
      replyLongPressEmojiBlockName: '',
      replyListReactions: [],
      intervalIds: [],
      timing: 0,

      // 表情符號框彈出後，滑動到表情符號要放大縮小的參數
      saveClientX: 0,
      saveClientY: 0,
      prevTouchEmojiNumber: '', // NOTE: 會判斷是否為空值
      inEmojiTouching: false,
      isDisplayDialog: false,
      displayTouchMoveImage: 'imgImEmojiLike',
      displayTouchMoveText: '',
      zoomInDirection: 'up',
      isSentSdk: false, // 開啟或關閉阻擋一頁的手勢
      composeEmoji: [
        { id: 1, imageName: 'imgImEmojiLike', text: '讚' },
        { id: 3, imageName: 'imgImEmojiHeart', text: '愛心' },
        { id: 4, imageName: 'imgImEmojiLaugh', text: '大笑' },
        { id: 5, imageName: 'imgImEmojiAngry', text: '生氣' },
        { id: 6, imageName: 'imgImEmojiWow', text: '驚訝' },
      ],
    }
  },
  mounted() {
    this.handleGetReactions();
    this.saveMyReactionType = this.reply.myReaction
    this.displayMyReaction = this.myReactionTextLogic(this.reply.myReaction)
    this.replyLongPressEmojiBlockName =
      document.getElementById(`replyEmojiBLock-${this.reply.reply.replyId}`)
    if(this.reply.myReaction !== 0) {
      this.myReactionTextStyle = 'color: #000000'
    }
    document.addEventListener('click', () => this.closeLongPress('isClick'), true);
    document.addEventListener('scroll', () => this.closeLongPress('isScroll') ,true);
    document.addEventListener('touchmove', (e) => {
      this.touchMoveLogic(e)
    });
    document.addEventListener('touchend', async () => {
      this.intervalIds.forEach((item,index)=>{
        clearInterval(item)
      })
      this.intervalIds = []
      this.timing = 0

      if(this.inEmojiTouching && this.prevTouchEmojiNumber !== '') {
        this.clickReaction(this.composeEmoji[this.prevTouchEmojiNumber].id)
        this.updateDisplayMyReaction(this.composeEmoji[this.prevTouchEmojiNumber].id)
        if(this.prevTouchEmojiNumber !== '') {
          this.replyLongPressEmojiBlockName.childNodes[0]
            .childNodes[this.prevTouchEmojiNumber].classList.add('a-emoji-zoom-out')
        }
        this.inEmojiTouching = false
        this.closeLongPress()
        this.prevTouchEmojiNumber = ''
        if(this.isSentSdk) {
          // 關閉阻擋上一頁的手勢
          BGO.set_back_gesture(true)
          this.isSentSdk = false
        }
      }
    })
  },
  computed: {
    imageDataLogic() {
      if(this.replyListReactions && this.replyListReactions.length === 0) {
        return []
      }
      const map = new Map()
      this.replyListReactions.forEach(e => {
        if (map.get(e.type)) {
          let getNum = map.get(e.type);
          map.set(e.type, getNum+=1);
        } else {
          map.set(e.type, 1);
        }
      });
      const mapConvertArr = Array.from(map, ([type, count]) => ({ type, count }));;
      mapConvertArr.sort(function(a, b) {
        return a.count - b.count;
      });
      const composeReaction = {
        1: "imgImEmojiLike",
        3: "imgImEmojiHeart",
        4: "imgImEmojiLaugh",
        5: "imgImEmojiAngry",
        6: "imgImEmojiWow"
      };
      let arr = [];
      mapConvertArr.slice(0, 3).forEach(e => {
        arr.push(composeReaction[e.type]);
      });
      return arr
    },
    stateComment() {
      return this.$store.state.Comment.list
            .filter(x => x.comment.commentId == this.commentId)[0];
    },
    stateReply() {
      return this.stateComment.replies
            .filter(x => x.reply.replyId == this.reply.reply.replyId)[0];
    },
    reactionDetails() {
      return this.stateReply.reactionDetails;
    },
    reactionCount() {
      return this.replyListReactions.length
    }
  },
  methods: {
    closeLongPress(form) {
      const emo = this.replyLongPressEmojiBlockName
      if(form === 'isClick' || form === 'isScroll') {
        this.inEmojiTouching = false
      }
      if(emo && emo.style.display !== 'none') {
        emo.classList.add('fade-out')
        setTimeout(() => {
          emo.classList.remove('fade-out')
          emo.style.display = 'none'
          emo.style.zIndex = 0
        }, 100)
      }
      if(this.prevTouchEmojiNumber) {
        const zoomInClass = this.zoomInDirection === 'up' ?
          'a-emoji-zoom-in-up' : 'a-emoji-zoom-in-down'
        this.replyLongPressEmojiBlockName.childNodes[0]
            .childNodes[this.prevTouchEmojiNumber].classList.remove(zoomInClass)
      }
      if(this.isSentSdk) {
        this.isSentSdk = false
        // 關閉阻擋上一頁的手勢
        BGO.set_back_gesture(true)
      }
    },
    touchStartLogic(e) {
      e.preventDefault()
      this.closeLongPress()
      if(!this.inEmojiTouching) {
        this.elem = e
        const pageX = this.elem.targetTouches[0].pageX
        const pageY = this.elem.targetTouches[0].pageY
        // 小於 100 時，表情符號框往下長
        if(e.touches[0].clientY < 100) {
          this.zoomInDirection = 'down'
        } else {
          this.zoomInDirection = 'up'
        }
        let id = 0
        id = setInterval(() => {
          this.timing += 100
          if(this.timing > 500) {
            const emo = this.replyLongPressEmojiBlockName
            if(!this.isSentSdk) {
              // 開啟阻擋上一頁的手勢
              BGO.set_back_gesture(false)
              this.isSentSdk = true
            }
            if(this.fromPage === 'CommentList') {
              emo.style.top = `${pageY - 70}px`
            } else {
              if(this.zoomInDirection === 'down') {
                emo.style.top = `${pageY - 150}px`
              } else {
                emo.style.top = `${pageY - 235}px`
              }
            }
            emo.style.left = `${pageX - 70}px`
            emo.style.display = 'block'
            this.inEmojiTouching = true
          }
        }, 100);
        this.intervalIds.push(id)
      }
    },
    touchEndLogic(saveMyReactionType) {
      if(this.timing > 100) {
        setTimeout(() => {
          this.intervalIds.forEach((item,index)=>{
            clearInterval(item)
          })
          this.intervalIds = []
          this.timing = 0
          this.inEmojiTouching = false
        }, 100)
      }
      if(this.inEmojiTouching && this.timing <= 100) {
        this.inEmojiTouching = false
        this.closeLongPress()
      } else if (this.timing <= 100 && !this.inEmojiTouching) {
        this.clickReaction(saveMyReactionType)
        this.updateDisplayMyReaction(saveMyReactionType)
      }
    },
    touchMoveLogic(e) {
      const getOffsetLeft = this.replyLongPressEmojiBlockName.offsetLeft // 表情符號框離螢幕左邊的距離
      const leftLimit = getOffsetLeft + 12 // 12 為表情符號框的邊框半圓寬度
      const rightLimit = getOffsetLeft + 264 - 12 // 264 表情符號框的寬度, 12 為表情符號框的邊框半圓寬度
      const topLimit = this.replyLongPressEmojiBlockName.offsetTop
      const bottomLimit = this.replyLongPressEmojiBlockName.offsetTop + 48 // 48 為表情符號框的高度
      const touchX = e.touches[0].clientX
      let touchY = ''
      if(this.fromPage === 'CommentList') {
        touchY = e.touches[0].pageY
      } else {
        // 這個情境在投票頁底下的留言，因畫面有下推 top 150 + margin 20 的關係，故需要扣 170
        touchY = e.touches[0].pageY - 170
      }
      const isXLimited = touchX > leftLimit && touchX < rightLimit
      const isYLimited = touchY > topLimit  && touchY < bottomLimit
      const zoomInClass =
        this.zoomInDirection === 'up' ? 'a-emoji-zoom-in-up' : 'a-emoji-zoom-in-down'
      const showTextClass = this.zoomInDirection === 'up' ? 'showTextUp' : 'showTextDown'
      const id = `.replyEmojiBLock-${this.reply.reply.replyId}_a-emoji-id`
      if(isXLimited && isYLimited &&
        this.replyLongPressEmojiBlockName.style.display === 'block') {
        this.inEmojiTouching = true
        const currentTouchEmojiNumber =
          Math.trunc((touchX-getOffsetLeft-12)/48) // 12 為表情符號框的邊框半圓寬度, 48 為每個表情符號框的寬度
        if(this.prevTouchEmojiNumber === '') {
          const currentEmojiText = document.querySelector(`${id}_${currentTouchEmojiNumber+1}`);
          currentEmojiText.classList.add(showTextClass); // 放大顯示的文字 (偽元素)
          this.replyLongPressEmojiBlockName.childNodes[0]
            .childNodes[currentTouchEmojiNumber].classList.add(zoomInClass)
          this.prevTouchEmojiNumber = currentTouchEmojiNumber
          return
        }
        if(currentTouchEmojiNumber !== this.prevTouchEmojiNumber) {
          // 前一個放大的表情符號
          const prevEmojiText = document.querySelector(`${id}_${this.prevTouchEmojiNumber+1}`);
          prevEmojiText.classList.remove(showTextClass); // 移除放大顯示的文字 (偽元素)
          this.replyLongPressEmojiBlockName.childNodes[0]
            .childNodes[this.prevTouchEmojiNumber].classList.remove(zoomInClass) // 移除放大
          this.replyLongPressEmojiBlockName.childNodes[0]
            .childNodes[this.prevTouchEmojiNumber].classList.add('a-emoji-zoom-out') // 新增縮小

          // 當前要放大的表情符號
          this.replyLongPressEmojiBlockName.childNodes[0]
            .childNodes[currentTouchEmojiNumber].classList.add(zoomInClass)
          this.replyLongPressEmojiBlockName.childNodes[0]
            .childNodes[currentTouchEmojiNumber].classList.remove('a-emoji-zoom-out')
          const currentEmojiText = document.querySelector(`${id}_${currentTouchEmojiNumber+1}`);
          currentEmojiText.classList.add(showTextClass); // 放大顯示的文字 (偽元素)
          this.prevTouchEmojiNumber = currentTouchEmojiNumber
        }
        return
      }

      if(!(isXLimited && isYLimited) && this.prevTouchEmojiNumber !== '') {
        this.inEmojiTouching = false
        const prevEmojiText = document.querySelector(`${id}_${this.prevTouchEmojiNumber+1}`);
        prevEmojiText.classList.remove(showTextClass); // 移除放大顯示的文字 (偽元素)
        this.replyLongPressEmojiBlockName.childNodes[0]
          .childNodes[this.prevTouchEmojiNumber].classList.remove('a-emoji-zoom-out')
        this.replyLongPressEmojiBlockName.childNodes[0]
          .childNodes[this.prevTouchEmojiNumber].classList.remove(zoomInClass)
        this.prevTouchEmojiNumber = ''
      }
    },
    updateDisplayMyReaction(reactionType) {
      const composeReactionType = {
        1: "讚",
        3: "愛心",
        4: "大笑",
        5: "生氣",
        6: "驚訝"
      };
      this.displayMyReaction = composeReactionType[reactionType] || '讚'
      const displayId = this.composeEmoji.findIndex(c => c.id === reactionType)
      if(this.saveMyReactionType === 0 && reactionType > 0) { // 無按過
        this.myReactionTextStyle = 'color: #000000'
        this.openDialog(displayId)
      } else if (this.saveMyReactionType === reactionType) { // 按相同的 === 取消
        this.myReactionTextStyle = ''
        this.displayMyReaction =  '讚'
      } else if (reactionType === 0) { // 按取消
        this.myReactionTextStyle = ''
      } else if(reactionType !== 0) { // 重複按
        this.openDialog(displayId)
      }

      // 點擊相同視為取消，故更新時要送 0
      const updateReactionType = this.saveMyReactionType === reactionType ? 0 : reactionType
      this.saveMyReactionType = updateReactionType

      if(this.prevTouchEmojiNumber !== '') {
        const zoomInClass =
          this.zoomInDirection === 'up' ? 'a-emoji-zoom-in-up' : 'a-emoji-zoom-in-down'
        const id = `.replyEmojiBLock-${this.reply.reply.replyId}_a-emoji-id`
        const showTextClass = this.zoomInDirection === 'up' ? 'showTextUp' : 'showTextDown'
        const prevEmojiText = document.querySelector(`${id}_${this.prevTouchEmojiNumber+1}`);
        prevEmojiText.classList.remove(showTextClass); // 移除放大顯示的文字 (偽元素)
        this.replyLongPressEmojiBlockName.childNodes[0]
          .childNodes[this.prevTouchEmojiNumber].classList.remove(zoomInClass) // 移除放大
      }
    },
    openDialog(displayId) {
      this.isDisplayDialog = true
      this.displayTouchMoveImage = this.composeEmoji[displayId].imageName
      this.displayTouchMoveText = this.composeEmoji[displayId].text
    },
    clickReaction(reactionType, isLongPress) {
      const payload = {
          lotterId: this.reply.reply.replyId,
          lotterType: 2, // 1 留言, 2 回覆
          type: reactionType,
          aliasId: this.aliasID,
        }
      this.requestClickCommentReaction({
        id: 'vote',
        body: payload,
        onSuccess: (res) => {
          this.handleGetReactions()
        }
      })
      if(!isLongPress) {
        const eventItems = [
        {
          type: 'clickEvent',
          eventId: '1049',
          event: 'comment_content_page_item_click',
          pageInfo: this.pageInfoData,
          clickInfo: {
            uuid: this.reply.reply.replyId,
            type: 'reply_function_bar',
            name: 'emoji',
          },
        },
      ];
        passEvent(eventItems);
      }
    },
    myReactionTextLogic(myReaction) {
      const composeReaction = {
        1: "讚",
        3: "愛心",
        4: "大笑",
        5: "生氣",
        6: "驚訝"
      };
      return composeReaction[myReaction] || "讚";
    },
    handleDeleteRelpy() {
      this.requestDeleteRelpy({
        aliasId: this.aliasID,
        replyId: this.reply.reply.replyId,
        onSuccess: (res) => {
          this.deleteReplyInComment({
            commentId: this.commentId,
            replyId: this.reply.reply.replyId,
          });
        },
        onFailed: (err) => {
        },
      });
    },
    handleGetReactions() {
      this.requestGetReactions({
        id: 'vote',
        lotterId: this.reply.reply.replyId,
        lotterType: 2, // 1 留言，2 回覆
        aliasId: this.aliasID,
        prev: '',
        next: '',
        sort: 0,
        show: 30,
        onSuccess: (res) => {
          this.replyListReactions = res.data.listReactions
        },
        onFailed: (err) => {
        },
      });
    },
    toTimeSinceString(timestamp) {
      return timeSince(timestamp);
    },
    deleteReply() {
      this.message = {
        title: '確定刪除留言嗎?',
        subtitle: ['刪除後將無法再找回，確定要刪除嗎?'],
        callbackOk: () => {
          this.handleDeleteRelpy();

          // [event trace] 12-3 留言或回覆的功能列點擊-5:刪除
          const eventItems = [
            {
              type: 'clickEvent',
              eventId: '1049',
              event: 'comment_content_page_item_click',
              pageInfo: {
                page: this.fromPage === 'CommentList' ? 'comment' : 'poll',
                type: 'poll',
                uuid: this.subjectID,
                provider: '',
                cat0: [],
                cat1: [],
                cat2: [],
                tag: [],
              },
              clickInfo: {
                uuid: this.reply.reply.replyId,
                type: 'reply_function_bar',
                name: 'delete',
              },
            },
          ];
          passEvent(eventItems);
        },
        callbackCancel: () => {},
      };
      this.$refs.alert.showPopup();
    },
    sendReply() {
      this.$emit('sendReply', this.reply.reply.replyId);
    },
    showReportComment(lotterId, lotterType) {
      this.$emit('showReportComment', lotterId, lotterType);
    },
  },
};
</script>

<style lang="sass" scoped>
.headshot
  height: 30px
  width: 30px
  border-radius: 50%
  margin-right: 12px
  background: url('~@/assets/images/unnamed.png') center no-repeat

.right
  width: calc(100% - 56px)

.name
  color: rgb(51, 51, 51)
  font-family: PingFangTC-Medium
  font-size: 15px
  line-height: 20px
  letter-spacing: 0.1px
  margin-bottom: 8px
  padding-top: 3px

.context
  color: #333333
  font-family: PingFangTC-Regular
  font-size: 15px
  letter-spacing: 0.25px
  line-height: 24px
  margin-bottom: 8px
  white-space: pre-wrap
  word-break: break-all
  hyphens: auto

.time
  color: #999999
  font-family: PingFangTC-Regular
  font-size: 13px
  line-height: 18px
  letter-spacing: 0.4px
  margin-right: 16px

.reply
  color: #999999
  font-family: PingFangTC-Medium
  font-size: 13px
  line-height: 18px
  letter-spacing: 0.4px
  margin-right: 16px

.report
  color: #999999
  font-family: PingFangTC-Medium
  font-size: 13px
  line-height: 18px
  letter-spacing: 0.4px
  margin-right: 16px

.thumb-counting
  font-family: PingFangTC-Regular
  font-size: 13px
  line-height: 20px
  letter-spacing: 0.4px
  color: #999999
  margin: -2px 0px 0px 6px

.like 
  margin-right: 16px
  color: #999999
  font-size: 13px
  line-height: 18px

.thumbs
  margin-right: 11px

.horizontal-line
  background: #999999
  height: 1px
  width: 24px
  margin: 0px 15px
  margin-left: 0px

.view-pre-comment
  font-family: PingFangTC-Regular
  font-size: 13px
  line-height: 18px
  letter-spacing: 0.4px
  color: #999999

.check-comment
  margin-top: 8px

.litter-emoji-block
  margin-right: -10px

.litter-emoji-container div:nth-child(1)
  z-index: 2

.litter-emoji-container div:nth-child(2)
  z-index: 1

.litter-emoji-container div:nth-last-child
  z-index: 0
  margin-right: 3px

.litter-emoji-container
  color: #999999
  font-family: PingFangTC-Medium
  font-size: 13px
  line-height: 18px
  letter-spacing: 0.4px

.a-litter-emoji 
  margin-right: -3px

.a-litter-emoji > img 
  -webkit-touch-callout: none
  -webkit-user-select: none
  -webkit-user-drag: none

.emoji-icon 
  width: 16px
  height: 16px
  background-color: #fff
  border-radius: 5px
</style>

<!--投票開始 按鈕-->
<template lang="html">
  <div class="roundBtnContainer">
    <div :class="isActive ? 'roundBtn btnActive' : 'roundBtn btnDisabled'">
      <div class="btnText">投票開始</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoundButtonActiveStartVote',
  props: ['isActive'],
  watch: {
    isActive: function(newVal, oldVal) {

    },
  },
  methods: {
    setActive(isActive) {
      if (isActive) {
        this.$el.querySelector('.roundBtn').classList.add('btnActive');
      } else {
        this.$el.querySelector('.roundBtn').classList.remove('btnActive');
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.roundBtnContainer
  height: 60px
  width: 100%
  text-align: center
  position: relative
  margin-top: 40px

.roundBtn
  width: 100%
  max-width: 343px
  height: 40px
  border-radius: 16px
  margin-top: 10px
  text-align: center
  display: inline-block
  position: relative
  color: #fff
  border: none

.btnText
  color: rgb(255, 255, 255)
  font-size: 15px
  margin-top: 12px

.btnActive
  background-color: rgb(38, 208, 124)

.btnDisabled
  background-color: rgb(221,221,221)
</style>

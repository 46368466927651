<template lang="html">
  <!-- 長按出現，五種表情符號 -->
  <div class="emoji-block" :id="id">
    <div class="emoji-container">
      <div 
        v-for="(item, index) in imageArr" 
        :class="`a-emoji ${dynamicsClass(index)}`" 
        :id="`a-emoji-id_${index+1}`" 
        :key="index" 
        @touchstart="(e) => touchStartLogic(e, index)"
        @touchend="(e) => touchEndLogic(e, item.id, item.imageName)"
      >
        <img :src="require(`@/assets/images/emoji/${item.imageName}.png`)">
      </div>
    </div>
  </div>
</template>

<script>
import { passEvent } from '@/utils/trackingHelper';

export default {
  name: 'LongPressEmojiBlock',
  props: ['id', 'aliasId', 'pageInfoData', 'commentId', 'saveMyReactionType',
      'zoomInDirection', 'emojiTouching'],
  data() {
    return {
      zoomInClass: '',
      zoomInText: '',
      prevTouchEmojiNumber: '',
      imageArr: [
        { id: 1, imageName: 'imgImEmojiLike' },
        { id: 3, imageName: 'imgImEmojiHeart' },
        { id: 4, imageName: 'imgImEmojiLaugh' },
        { id: 5, imageName: 'imgImEmojiAngry' },
        { id: 6, imageName: 'imgImEmojiWow' },
      ]
    }
  },
  computed: {
    cat0() {
      let value = '';
      const search = new URLSearchParams(window.location.search);
      switch (parseInt(search.get('newsMainBannerId'), 10)) {
        case 1:
          value = '電玩星';
          break;
        case 2:
          value = '放鬆星';
          break;
        case 3:
          value = '時事星';
          break;
      }
      return value;
    },
  },
  methods: {
    touchStartLogic(e, index) {
      e.preventDefault()
      this.zoomInClass =
        this.zoomInDirection === 'up' ? 'a-emoji-zoom-in-up' : 'a-emoji-zoom-in-down'
      this.zoomInText =
        this.zoomInDirection === 'up' ? 'showTextUp' : 'showTextDown'
      const getClass = document.getElementById(`a-emoji-id_${index + 1}`)
      if(getClass) {
        getClass.classList.add(this.zoomInText)
        getClass.classList.add(this.zoomInClass)
      }
      this.prevTouchEmojiNumber = index+1
    },
    touchEndLogic(e, id, name) {
      e.preventDefault()
      const getClass = document.getElementById(`a-emoji-id_${this.prevTouchEmojiNumber}`)
      if(getClass) {
        getClass.classList.remove(this.zoomInText)
        getClass.classList.remove(this.zoomInClass)
      }
      const getUserAgent = navigator.userAgent.toLowerCase();
      if(getUserAgent.includes('iphone')) {
        this.clickEmoji(id, name)
      }

      const emos = document.getElementsByClassName('emoji-block')
      emos.forEach(e => {
        if(e.style.display !== 'none') {
          e.classList.add('fade-out')
          setTimeout(() => {
            e.classList.remove('fade-out')
            e.style.display = 'none'
            e.style.zIndex = 0
            }, 100)
        }
      })
    },
    dynamicsClass(index) {
      return `${this.id}_a-emoji-id_${index+1}`
    },
    async clickEmoji(id, imageName) {
      let reactionType = 0
      let eventReactionType = 'cancel'
      if(this.saveMyReactionType !== id) {
        const composeReactionType = {
          imgImEmojiLike: () => {
            reactionType = 1,
            eventReactionType = 'like'
          },
          imgImEmojiHeart: () => {
            reactionType = 3,
            eventReactionType = 'love'
          },
          imgImEmojiLaugh: () => {
            reactionType = 4,
            eventReactionType = 'laugh'
          },
          imgImEmojiAngry: () => {
            reactionType = 5,
            eventReactionType = 'angry'
          },
          imgImEmojiWow: () => {
            reactionType = 6,
            eventReactionType = 'wow'
          }
        }
      composeReactionType[imageName]()
    }
    const isLongPress = true
    this.$emit('updateDisplayMyReaction', reactionType)
    this.$emit('clickReaction', reactionType, isLongPress)

    if(this.id === 'voteResultEmojiBLock' || this.id === 'voteEmojiBLock') {
      const eventItems = [
        {
          type: 'clickEvent',
          eventId: '1046',
          event: 'poll_item_click',
          pageInfo: this.pageInfoData,
          clickInfo: {
            type: 'emoji',
            name: eventReactionType
          }
          },
        ];
        passEvent(eventItems);
      }
      if(this.id.startsWith('commentEmojiBlock') || this.id.startsWith('replyEmojiBLock')) {
      this.pageInfoData['cat0'] = this.cat0
      const eventItems = [
        {
          type: 'clickEvent',
          eventId: '1049',
          event: 'comment_content_page_item_click',
          pageInfo: this.pageInfoData,
          clickInfo: {
            type: this.id.startsWith('commentEmojiBlock') ?
              'comment_function_bar' : 'reply_function_bar',
            name: eventReactionType,
            uuid: this.commentId
          }
          },
        ];
        passEvent(eventItems);
      }
    },
  },
};
</script>

<style scoped>
@media (hover: hover) {
  .a-emoji:hover {
    opacity: 0.3;
  }
}

.a-emoji > img {
  width: 24px;
  height: 24px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.emoji-block {
  top: 0;
  position: absolute;
  z-index: 3;
}

.emoji-block {
  display: none;
  top: 0;
  position: absolute;
  z-index: 3;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 48px;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
.emoji-container {    
  background: #fff;
  width: 264px;
  height: 48px;
  display: flex;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px 0 rgb(43 56 67 / 10%);
  animation: fadeIn 300ms linear;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    margin-top: 48px;
  }
}

.fade-out {
  animation: fadeOut 600ms linear;
}

.emoji-container > div {
  margin: 0 10px;
}

.emoji-container > div:not(.showTextUp):not(.showTextDown) {
  margin: 12px 12px;
}

@keyframes zoomInImgUp {
  0% {
    transform: translateY(10px) scale(0.5)
  }
  50% {
    transform: translateY(0px) scale(1)
  }
}

@keyframes zoomInImgDown {
  0% {
    transform: translateY(-10px) scale(0.5)
  }
  50% {
    transform: translateY(0px) scale(1)
  }
}

@keyframes zoomOutImg {
  0% {
    transform: translateY(-15px) scale(2)
  }
  25% {
    transform: translateY(-7px) scale(1.7)
  }
  50% { 
    transform: translateY(-3px) scale(1.3)
  }
  100% {
    transform: translateY(0px) scale(1)
  }
}

.a-emoji-zoom-in-up {
  padding-bottom: 45px;
  width: 34px;
}
.a-emoji-zoom-in-up > img {
  width: 48px;
  height: 48px;
  animation: zoomInImgUp 0.3s linear;
}

.a-emoji-zoom-in-down {
  padding-top: 45px;
  width: 34px;
}

.a-emoji-zoom-in-down > img {
  width: 36px;
  height: 36px;
  animation: zoomInImgDown 0.3s linear;
}


.a-emoji-zoom-out {
  padding-bottom: 0;
  width: 24px;
}

.a-emoji-zoom-out > img {
  width: 24px;
  height: 24px;
  animation: zoomOutImg 0.3s linear;
}

@keyframes bounce {
  0% {
    margin-top: -15px;
  }
  30% {
    margin-top: -30px;
  }
  50% {
    margin-bottom: 40px;
  }
  100% {
    margin-top: 0px;
  }
}


#a-emoji-id_1 , #a-emoji-id_2, #a-emoji-id_3, #a-emoji-id_4, #a-emoji-id_5 {
  position: relative;
}

#a-emoji-id_1::before {
  content: '讚';
  display: none;
  color: white;
  position: absolute;
  width: 18px;
  height: 18px;
  margin-left: 4px;
  top: -23px;
  left: 5px;
  font-size: 10px;
  padding: 1px 6px 1px 6px !important;
  background: #262626;
  text-align: center;
  line-height: 1.5;
  border-radius: 9px;
}

#a-emoji-id_2::before, #a-emoji-id_3::before, #a-emoji-id_4::before, #a-emoji-id_5::before {
  content: '讚';
  display: none;
  color: white;
  position: absolute;
  width: 26px;
  height: 18px;
  top: -23px;
  left: 5px;
  font-size: 10px;
  padding: 1px 6px 1px 7px !important;
  background: #262626;
  text-align: center;
  line-height: 1.5;
  border-radius: 9px;
}

#a-emoji-id_1 {
  animation: bounce .5s linear;
}

#a-emoji-id_1::before {
  content: '讚';
}
#a-emoji-id_1.showTextUp::before, #a-emoji-id_2.showTextUp::before, 
#a-emoji-id_3.showTextUp::before, #a-emoji-id_4.showTextUp::before, 
#a-emoji-id_5.showTextUp::before {
  display: block;
  opacity: 0.8;
}

#a-emoji-id_1.showTextDown::before, #a-emoji-id_2.showTextDown::before, 
#a-emoji-id_3.showTextDown::before, #a-emoji-id_4.showTextDown::before, 
#a-emoji-id_5.showTextDown::before {
  display: block;
  top: 87px;
  left: 0px;
}

#a-emoji-id_2 {
  animation: bounce .5s linear;
  animation-delay: 40ms;
}

#a-emoji-id_2::before {
  content: '愛心';
}

#a-emoji-id_3 {
  animation: bounce .5s linear;
  animation-delay: 80ms;
}

#a-emoji-id_3::before {
  content: '大笑';
}

#a-emoji-id_4 {
  animation: bounce .5s linear;
  animation-delay: 120ms;
}

#a-emoji-id_4::before {
  content: '生氣';
}

#a-emoji-id_5 {
  animation: bounce .5s linear;
  animation-delay: 160ms;
}

#a-emoji-id_5::before {
  content: '驚訝';
}
</style>

export default {
  methods: {
    requestSetPinned({
      chatID = 0,
      subjectID,
      isPinned,
      onSuccess,
      onFailed,
    }) {
      this.$store.dispatch('SET_PINNED', {
        chatID,
        subjectID,
        isPinned,
        onSuccess: (res) => {
          if (onSuccess) {
            onSuccess(res);
          }
        },
        onFailed: (err) => {
          if (onFailed) {
            onFailed(err);
          }
        },
      });
    },
  },
};

<template lang="html">

  <div class="textArea areaHeight flex" @click="addOption">
    <svg class="addIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask31" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.08 12.92V19.4C11.08 19.7314 11.3486 20 11.68 20L12.32 20C12.6514 20 12.92 19.7314 12.92 19.4V12.92H19.4C19.7314 12.92 20 12.6514 20 12.32V11.68C20 11.3486 19.7314 11.08 19.4 11.08H12.92V4.6C12.92 4.26863 12.6514 4 12.32 4H11.68C11.3486 4 11.08 4.26863 11.08 4.6V11.08H4.6C4.26863 11.08 4 11.3486 4 11.68V12.32C4 12.6514 4.26863 12.92 4.6 12.92H11.08Z" fill="#333333"/>
    </mask>
    <g mask="url(#mask31)">
    <rect width="24" height="24" fill="#999999"/>
    </g>
    </svg>
  </div>

</template>

<script>
export default {
  name: 'AddAnswerCell',
  data() {
    return{
      isAddOption: 0,

    }
  },
  methods: {
    addOption(){
      this.isAddOption += 1;
      this.$emit('addOption', this.isAddOption);
    }
  },
};
</script>

<style lang="sass" scoped>
.areaHeight
  height: 50px

.addIcon
  margin: 0 auto
  margin-top: 13px
  align-items: center
  color: rgb(153, 153, 153)
</style>

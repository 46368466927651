<template lang="html">
  <div>
    <UnsavedChangesDialog
      title=""
      :subtitle="['', '']"
      :show="shouldShowDialog"
      @cancel="closePopup"
      @discard="discard"
      @save="save" />
  </div>
</template>

<script>
import UnsavedChangesDialog from 'vue-unsaved-changes-dialog';

export default {
  name: 'iOSContextMenu',
  props: ['menus'],
  data() {
    return {
      shouldShowDialog: false,
    }
  },
  methods: {
    showPopup() {
      this.shouldShowDialog = true;
      // 偵測當 DOM 物件異動時，執行指定事件 [此處為原生 javascript 寫法]
      /*
      window.addEventListener('DOMNodeInserted', function() {
        var h1 = document.getElementsByTagName('h1');
        for (var i = 0; i <= h1.length - 1; i++) {
          h1[i].style['color'] = '#000';
          h1[i].style['font-size'] = '17px';
        }
      }, false);
      */
    },
    closePopup() {
      this.shouldShowDialog = false;
    },
    async save() {
      // 原套件 Code, 將被 updated() 覆蓋
      try {
        await this.saveChangesToServer();
        this.exit();
      } catch(e) {
      }
    },
    async saveChangesToServer() {
      // 原套件 Code, 將被 updated() 覆蓋
    },
    discard() {
      // 原套件 Code, 將被 updated() 覆蓋
      this.exit();
    },
    exit() {
      // 原套件 Code, 將被 updated() 覆蓋
      this.closePopup();
    },
  },
  components: {
    UnsavedChangesDialog,
  },
  mounted: function(){
    // 這邊能寫真正的 javascript
    // alert('mounted');
  },
  updated: function () {
    var self = this;

    // 當 DOM 被 re-rendered 時，會進入此函式
    this.$nextTick(function () {
      // 彈出時才要處理
      if (!this.shouldShowDialog) return;

      //#region 大標題
      var h1 = document.querySelectorAll('.mobile-dialog--wrapper h1');
      for (var i = 0; i <= h1.length - 1; i++) {
        h1[i].style['display'] = 'none';
      }
      //#endregion

      //#region 小標題
      var p = document.querySelectorAll('.mobile-dialog--wrapper p');
      for (var i = 0; i <= p.length - 1; i++) {
        p[i].style['display'] = 'none';
      }
      //#endregion

      //#region 移除 text-section
      var text = document.querySelectorAll('.mobile-dialog--wrapper .text-section');
      for (var i = 0; i <= text.length - 1; i++) {
        text[i].remove();
      }
      //#endregion

      //#region 將套件的 button-row 改成 圓角
      var divButtonRow = document.querySelectorAll('.mobile-dialog--wrapper .button-row');
      for (var i = 0; i <= divButtonRow.length - 1; i++) {
        divButtonRow[i].style['border-radius'] = '14px';
      }
      //#endregion

      //#region 若只有兩個選項，則移除中間項目
      if (this.menus.length <= 2) {
        document.querySelectorAll('.mobile-dialog--wrapper .button-danger').forEach(e => {
          //e.parentNode.removeChild(e);
          e.style['height'] = '0px';
          e.style['font-size'] = '0px';
          e.style['padding'] = '0px';
          e.classList.remove('button-danger');
          e.innerHTML = '';
        });
      }
      //#endregion

      //#region 按鈕-選項集合
      this.menus.forEach(function(menu, index, menus) {
        var title = menu.title;
        var callback = menu.callback;

        if (index == 0) {
          //#region 第 1 個項目 (原本是 Save)
          var btnOK = document.querySelectorAll('.mobile-dialog--wrapper .button-success');
          for (var i = 0; i <= btnOK.length - 1; i++) {
            btnOK[i].style['height'] = '56px';
            btnOK[i].style['border-color'] = 'rgba(60, 60, 67, 0.28)';
            // btnOK[i].style['border-left'] = '1px solid #edeeef';
            btnOK[i].style['border-radius'] = '14px 14px 0 0';
            btnOK[i].style['color'] = 'rgba(0, 122, 255, 1)';
            btnOK[i].style['font-size'] = '20px';
            btnOK[i].style['font-weight'] = 'normal';
            btnOK[i].innerHTML = title;

            if (index != menus.length - 1) {
              btnOK[i].style['border-left'] = '1px solid #edeeef';
            }

            //#region 變更原本的 :active 樣式
            //Tap 壓下
            btnOK[i].addEventListener("touchstart", function(event) {
              event.preventDefault();
              this.style['color'] = 'rgba(0, 122, 255, 0.5)';
              this.style['background-color'] = 'rgba(189, 195, 199, 0.5)';
            }, false);

            //Tap 放開
            btnOK[i].addEventListener("touchend", function(event) {
              event.preventDefault();
              this.style['color'] = 'rgba(0, 122, 255, 1)';
              callback();
              self.exit();
            }, false);
            //#endregion
          }
          //#endregion
        } else if (index == menus.length - 1) {
          //#region 最後一個項目
          // clone
          var btnOK = document.querySelectorAll('.mobile-dialog--wrapper .button-success');
          var btnEnd = btnOK[0].cloneNode(true);

          // attributes
          btnEnd.style['height'] = '56px';
          btnEnd.style['border-color'] = 'rgba(60, 60, 67, 0.28)';
          btnEnd.style['border-radius'] = '0 0 14px 14px';
          btnEnd.style['color'] = 'rgba(0, 122, 255, 1)';
          btnEnd.style['font-size'] = '20px';
          btnEnd.style['letter-spacing'] = '0.38px';
          btnEnd.innerHTML = title;

          //#region 變更原本的 :active 樣式
          //Tap 壓下
          btnEnd.addEventListener("touchstart", function(event) {
            event.preventDefault();
            this.style['color'] = 'rgba(0, 122, 255, 0.5)';
            this.style['background-color'] = 'rgba(189, 195, 199, 0.5)';
          }, false);

          //Tap 放開
          btnEnd.addEventListener("touchend", function(event) {
            event.preventDefault();
            this.style['color'] = 'rgba(0, 122, 255, 1)';
            callback();
            self.exit();
          }, false);
          //#endregion

          // append
          var divButtonRow = document.querySelectorAll('.mobile-dialog--wrapper .button-row');
          divButtonRow[0].appendChild(btnEnd);
          //#endregion
        } else {
          //#region 中間項目 (原本是 Discard)
          var btnDiscard = document.querySelectorAll('.mobile-dialog--wrapper .button-danger');
          for (var i = 0; i <= btnDiscard.length - 1; i++) {
            btnDiscard[i].style['height'] = '56px';
            btnDiscard[i].style['border-color'] = 'rgba(60, 60, 67, 0.28)';
            btnDiscard[i].style['border-radius'] = 'none';
            btnDiscard[i].style['color'] = 'rgba(0, 122, 255, 1)';
            btnDiscard[i].style['font-size'] = '20px';
            btnDiscard[i].style['font-weight'] = 'normal';
            btnDiscard[i].innerHTML = title;

            //#region 變更原本的 :active 樣式
            //Tap 壓下
            btnDiscard[i].addEventListener("touchstart", function(event) {
              event.preventDefault();
              this.style['color'] = 'rgba(0, 122, 255, 0.5)';
              this.style['background-color'] = 'rgba(189, 195, 199, 0.5)';
            }, false);

            //Tap 放開
            btnDiscard[i].addEventListener("touchend", function(event) {
              event.preventDefault();
              this.style['color'] = 'rgba(0, 122, 255, 1)';
              callback();
              self.exit();
            }, false);
            //#endregion
          }
          //#endregion
        }
      });
      //#endregion

      //#region 按鈕-取消
      var divCancel = document.querySelectorAll('.mobile-dialog--cancel-button');
      divCancel[0].style['border-radius'] = '14px';

      var btnCancel = document.querySelectorAll('.mobile-dialog--cancel-button button');
      for (var i = 0; i <= btnCancel.length - 1; i++) {
        btnCancel[i].style['height'] = '56px';
        btnCancel[i].style['color'] = 'rgba(0, 122, 255, 1)';
        btnCancel[i].style['font-size'] = '20px';
        btnCancel[i].style['letter-spacing'] = '0.38px';
        btnCancel[i].style['font-weight'] = 'bold';
        btnCancel[i].style['font-family'] = '-apple-system, BlinkMacSystemFont, sans-serif';
        btnCancel[i].innerHTML = '取消';
      }
      //#endregion

      //#region 移動 mobile-dialog--wrapper 位置，並調整背景色
      var divWrapper = document.querySelectorAll('.mobile-dialog--wrapper');
      //divWrapper[0].style['position'] = 'absolute';
      divWrapper[0].style['top'] = 'auto';
      divWrapper[0].style['bottom'] = (72 - 24*this.menus.length) + 'px';
      //divWrapper[0].style['background-color'] = 'rgba(249, 249, 249, 0.78)';
      divWrapper[0].style['background-color'] = 'rgba(225, 225, 225, 1)';
      divWrapper[0].style['border-radius'] = '14px';
      divWrapper[0].style['font-family'] = '-apple-system, BlinkMacSystemFont, sans-serif';
      //#endregion
    })
  }
};
</script>

<style lang="sass" scoped>
// 這邊設定樣式無效，因為此處 DOM 物件是動態生成的，這邊設定會抓不到
// 要由 javascript 去調整樣式
</style>
<template lang="html">
  <div>
    <div class="picBackground" @click="selectImage(2,1)">
      <div class="bannerMask"></div>
      <div class="banner" v-bind:style="{ 'background-image': 'url(\'' + imageUri + '\')', 'background-size': '100% auto' }" v-bind:class="{ picBackgroundWithImage: imageUri!= '' }">
        <div class="cameraIcon" :style="imageUri== '' ? '' : 'top:69px'">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="black" fill-opacity="0.28"/>
            <mask id="mask21" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="8" width="16" height="14">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3201 11.1494H11.3888H9.8555C9.41367 11.1494 9.0555 11.5076 9.0555 11.9494V19.4161C9.0555 20.0052 9.53306 20.4827 10.1222 20.4827H19.9888C20.5779 20.4827 21.0555 20.0052 21.0555 19.4161V11.9494C21.0555 11.5076 20.6973 11.1494 20.2555 11.1494H18.7222H17.8468L17.4987 10.3462L17.1246 9.48275H12.9312L12.6407 10.2751L12.3201 11.1494ZM17.4748 8.14941C17.7936 8.14941 18.082 8.33877 18.2088 8.63135L18.7222 9.8161H20.2555C21.4337 9.8161 22.3888 10.7712 22.3888 11.9494V19.4161C22.3888 20.7416 21.3143 21.8161 19.9888 21.8161H10.1222C8.79668 21.8161 7.72217 20.7416 7.72217 19.4161V11.9494C7.72217 10.7712 8.67729 9.8161 9.8555 9.8161H11.3888L11.8076 8.67399C11.9232 8.35889 12.2231 8.14941 12.5587 8.14941H17.4748ZM15 13.4827C13.8954 13.4827 13 14.3782 13 15.4827C13 16.5873 13.8954 17.4827 15 17.4827C16.1046 17.4827 17 16.5873 17 15.4827C17 14.3782 16.1046 13.4827 15 13.4827ZM11.6667 15.4827C11.6667 13.6418 13.1591 12.1494 15 12.1494C16.841 12.1494 18.3333 13.6418 18.3333 15.4827C18.3333 17.3237 16.841 18.8161 15 18.8161C13.1591 18.8161 11.6667 17.3237 11.6667 15.4827ZM19.3333 13.4827C19.7015 13.4827 20 13.1843 20 12.8161C20 12.4479 19.7015 12.1494 19.3333 12.1494C18.9651 12.1494 18.6667 12.4479 18.6667 12.8161C18.6667 13.1843 18.9651 13.4827 19.3333 13.4827Z" fill="#333333"/>
            </mask>
            <g mask="url(#mask21)">
              <rect x="5" y="5.1355" width="19.8651" height="19.8651" transform="rotate(-0.390403 5 5.1355)" fill="white"/>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div class="pageContainer" v-bind:class="{pageContainerWithImage: imageUri!= ''}">
      <PageContainer>
        <SubjectCell :title="title" :description="description" :subjectCheck="subjectCheck" 
        @getSubjectContent="title = $event" @getSubjectDescription="description = $event" @getSubjectError="subjectError = $event"/>
        <div class="picTextSwitchArea">
          <PictureOrTextSwitchCell @parentSwitch="pictureOrTextSwitch" :questionType="questionType"/>
        </div>
        <div v-for="option in optionList" :key="option.optionID">
          <EditedOptionsCell ref="editedOptionsCell" :subjectID="subjectID" :questionMode="questionMode" :questionType="questionType" :option="option"
          :optionCount="optionCount" :optionCheck="optionCheck"  @updateOption="updateOption" @deleteOption="deleteOption"/>
        </div>
        <AddAnswerCell v-show="questionMode==2 && optionList.length < 7" @addOption="addOption"/>
        <div style="height:24px"></div>
        <EndTimeCell @click.native="setEndTime" :dateTimeString="endTimeString" :endTimeError="endTimeError"/>
        <MutipleVoteSwitchCell v-show="questionMode==2" :isMultipleVote="isMultipleVote" @isMultipleVoteSwitch="isMultipleVoteSwitch"/>
        <TopNoticeSwitchCell :isPinned="isPinned" @isPinnedSwitch="isPinnedSwitch"/>
        <div v-show="isShowErrMsg && !isAvailable" class="errMsg">以上欄位不可留空</div>
        <RoundButtonActiveStartVote class="btnStart" :isActive="isActive" ref="roundButtonActiveStartVote" @click.native="checkData" />
        <!-- <RoundButtonActiveStartVote :isActive="isAvailable" ref="roundButtonActiveStartVote" @click.native="isAvailable ? saveVote() : null" /> -->
        <iOSAlert ref="alert" :message="message" />
      </PageContainer>
    </div>
    <iOSDateTimePicker ref="picker" :initdata="Date.now()" @callback="setEndTimeCallback" />
  </div>
</template>

<script>
import { getBase64ImageSize } from '@/utils/imageHelper';
import { CreateVote, Question, Option } from '@/store/entity/request';
import { auth, getNewSubjectId, createVote, getIsPinned, file } from '@/mixins';
import { v4 as uuidv4 } from 'uuid';

import iOSAlert from '../components/iOSAlert.vue';
import iOSDateTimePicker from '../components/iOSDateTimePicker.vue';
//10.2 建立投票
import PageContainer from '../components/PageContainer.vue';
//10.2 建立投票的投票主題/投票說明
import SubjectCell from './components/SubjectCell.vue';
//10.2.1 建立投票的圖文模式切換開關
import PictureOrTextSwitchCell from './components/PictureOrTextSwitchCell.vue';
//10.2.1 建立投票的輸入選項集合-大圖模式/ 純文字
import EditedOptionsCell from './components/EditedOptionsCell.vue';
//10.2 建立投票的新增選項按鈕（是個+）
import AddAnswerCell from './components/AddAnswerCell.vue';
//10.2.1 建立投票的結束時間輸入框
import EndTimeCell from './components/EndTimeCell.vue';
//10.2.1 建立投票的一人多票開關
import MutipleVoteSwitchCell from './components/MutipleVoteSwitchCell.vue';
//10.2.1 建立投票的置頂公告開關
import TopNoticeSwitchCell from './components/TopNoticeSwitchCell.vue';
//開始投票按鈕
import RoundButtonActiveStartVote from '../components/RoundButtonActiveStartVote.vue';

export default {
  name: 'CreateVote',
  mixins: [auth, getNewSubjectId, createVote, getIsPinned, file],
  components: {
    iOSAlert,
    iOSDateTimePicker,
    PageContainer,
    SubjectCell,
    PictureOrTextSwitchCell,
    EditedOptionsCell,
    AddAnswerCell,
    EndTimeCell,
    MutipleVoteSwitchCell,
    TopNoticeSwitchCell,
    RoundButtonActiveStartVote,
  },
  data() {
    return {
      subjectID: 0,
      title: '',
      imageUri: '',
      description: '',
      type: 1, // 1:聊天室 2:評論家
      isActive: true,
      isPinned: false,
      startTime: null,
      endTime: null, //timestamp
      endTimeString: null,
      //chatID: _chatId, // 由 mixins-auth 取得
      version: 1,
      questionMode: null, // 投票模式 (1:PK  / 2:一般) 從 query 傳入
      questionType: 1,    // 選項類型 (1:文字 / 2:梗圖)
      questionSortId: 1,
      optionList: [],
      isMultipleVote: false,
      editedOptionsCellKey: 0,
      optionCount: 0,
      subjectCheck: false,
      subjectError: false,
      endTimeError: false,
      optionCheck: false,
      isShowErrMsg: false,
      //option : null,
      option: new Option({
        sortID: 1,
        title: '',
        imageUri: '',
      }),
      message: {
        title: "",
        subtitle: [],
        callbackOk: () => {},
        callbackCancel: () => {},
      },
      messageHasPinnedInChat: {
        title: '確定將投票設為公告嗎？',
        subtitle: ['此投票將覆蓋先前的投票公告。'],
        callbackOk: () => {
          this.saveVote();
        },
        callbackCancel: () => {
        },
      },
      leavePageMessage: {
        title: '您確定要離開當前畫面嗎？',
        subtitle: ['您尚未完成此次投票活動建立，', '離開的話將會遺失目前所輸入的資料。'],
        callbackOk: () => {
          // 拿 Vue 的 Next() 來用
          this.nextEvent();
        },
        callbackCancel: () => {
        },
      },
      endTimeAlert1Msg: {
        title: '時間錯誤',
        subtitle: ['投票活動的結束時間不能小於創建投票的時間'],
        callbackOk: () => {
          this.$refs.picker.rollback();
        },
      },
      endTimeAlert2Msg: {
        title: '時間錯誤',
        subtitle: ['結束時間超過限時聊天室時間', '此聊天室將於000結束'],
        callbackOk: () => {
          this.$refs.picker.rollback();
        },
      },
      endTimeAlert3Msg: {
        title: '無法建立',
        subtitle: ['限時聊天室已結束'],
        callbackOk: () => {
        },
      },
      uploadErrorMsg: {
        title: '圖片上傳失敗',
        subtitle: ['', ''],
        callbackOk: () => {
        },
      },
      nextEvent: null, // 存 Vue 的 Next() 用
    }
  },
  computed: {
    isOptionAvailable() {
      // 判斷有沒有選項
      if (this.optionList.length < 1) {
        return false;
      }
      var result = this.optionList.filter(option => {
        var hasProblem = false;

        // 判斷是否沒有輸入文字
        hasProblem = hasProblem || (typeof(option.title) == 'undefined' || option.title == '');

        // 判斷是否為大圖模式
        if (this.questionType == 2) {
          // 判斷是否沒有圖片
          hasProblem = hasProblem || option.imageUri == '';
        }

        // 當此 option 得到的 hasProblem 為 true 時，則此 option 會被加入至 result 內
        return hasProblem;
      });

      // 當有問題的筆數 > 0，則回傳有問題
      if (result.length > 0) {
        return false;
      }

      return true;
    },
    isAvailable() {
      return this.title != '' &&
             this.endTime != null &&
             this.isOptionAvailable;
    },
  },
  methods: {
    // 選取圖片
    selectImage(scaleW, scaleH) {
      BGO.get_multiple_cropped_image_type(
        {
          "type": "rectangle",
          "scale": {
            "width": scaleW,
            "height": scaleH
          },
          "grid": true,
        },
        (data) => {
          //this.imageUri= "data:image/png;base64," + data.imgs[0].image_content + "";

          // 移除 data.imgs[0].image_content 最後方的換行符號
          //this.imageUri= this.imageUri.replace(/\r?\n|\r/g, '');

          // 取得 base64 影像的寬度與高度 (debug用)
          //getBase64ImageSize(this.imageUri).then( size => {
          //});

          // 將 base64 轉為 bytes
          const blob = this.base64ToBytes(data.imgs[0].image_content.replace(/\r?\n|\r/g, ''));

          // call mixins
          this.upload({
            subjectID: this.subjectID,
            file: blob,
            onSuccess: (res) => {
              this.imageUri = this.$store.state.File.file_uri;
            },
            onFailed: (err) => {
              this.message = this.uploadErrorMsg;
              this.$refs.alert.showPopup();
            },
          });
        },
        1,
        0
      );
    },
    // 切換『文字模式』/『大圖模式』
    // 1. 提供給『PictureOrTextSwitchCell』的 Callback
    // 2. Call『EditedOptionsCell』的內部函式
    pictureOrTextSwitch(isPicture) {
      this.questionType = isPicture ? 2 : 1;
    },
    checkData() {
      this.isShowErrMsg = true;
      this.subjectCheck = true;
      this.optionCheck = true;
      if (this.endTime == null) {
        this.endTimeError = true;
      } else {
        this.endTimeError = false;
      }
      if (this.isAvailable) {
        this.isHasPinnedInChat();
      }
      // 還原 subjectCheck/optionCheck，讓下次 checkData() 時，
      // 設定 subjectCheck/optionCheck 由 false 變為 true 後，才能觸發子層 watch(異動) 事件
      setTimeout(() => {
        this.subjectCheck = false;
        this.optionCheck = false;
      }, 500);
    },
    isHasPinnedInChat() {
      if (this.isPinned) {
        this.handleGetIsPinned(() => {
          if (this.$store.state.GetIsPinned.isHasPinned) {
            this.message = this.messageHasPinnedInChat;
            this.$refs.alert.showPopup();
          } else {
            this.saveVote();
          }
        });
      } else {
        this.saveVote();
      }
    },
    handleGetIsPinned(success) {
      this.requestGetIsPinned({
        chatID: this.chatID,
        onSuccess: (res) => {
          success(res);
        },
        onFailed: (err) => {
        },
      });
    },
    saveVote() {
      var createVote = new CreateVote({
        subjectID: this.subjectID,
        title: this.title,
        imageUri: this.imageUri || process.env.VUE_APP_DEFAULT_BANNER,
        description: this.description,
        type: this.type,
        isActive: this.isActive,
        isPinned: this.isPinned,
        startTime: Date.now().toString(),
        endTime: this.endTime.toString(),
        chatID: this.chatID.toString(),
        version: this.version,
        createdUser: this.aliasID.toString(),
        questionList: [],
      });
      var question = new Question({
        questionID: 1,
        title: this.title,
        mode: this.questionMode,
        type: this.questionType,
        isMultipleVote: this.isMultipleVote,
        sortID: this.questionSortId,
        optionList: [],
      });
      for (var i = 0; i < this.optionList.length; i +=1 ) {
        var option = new Option({
          optionID: this.optionList[i].sortID,
          title: this.optionList[i].title,
          imageUri: this.optionList[i].imageUri,
          sortID: this.optionList[i].sortID,
          votingRate: 0,
          isSelected: false,
        });

        question.optionList.push(option);
      }
      createVote.questionList.push(question);

      // call mixins
      this.requestCreateVote({
        createVote: createVote,
        onSuccess: (res) => {
          this.$router.push({ name: 'voteList' })
        },
        onFailed: (err) => {
          if (this.endTime > this.chatEndTime) {
            this.message = this.endTimeAlert3Msg;
            this.$refs.alert.showPopup();
          }
        },
      });
    },
    addOption() {
      //const deleteOption = this.optionList.filter(option => option.sortID == sortID);
      var maxSort = Math.max.apply(null, this.optionList.map(function (option) {
         return option.sortID;
      }));
      if(maxSort <1){
        maxSort = 0;
      }
      if(this.optionList.length < 7){
        this.optionList.push(new Option({
          optionID: maxSort + 1,
          title: '',
          imageUri: '',
          sortID: maxSort + 1,
          votingRate: 0,
          isSelected: false,
        }));
        this.optionCount = this.optionList.length;
      }
    },
    updateOption(updatedOption) {
      const index = this.optionList.findIndex(option => option.sortID == updatedOption.sortID);
      this.optionList[index].title = updatedOption.title;
      this.optionList[index].imageUri = updatedOption.imageUri;
      this.optionList[index].sortID = updatedOption.sortID;
    },
    deleteOption(sortID) {
      this.optionList.splice(this.optionList.findIndex(option => option.sortID == sortID), 1);
      this.optionCount = this.optionList.length;
    },
    setEndTime() {
      this.$refs.picker.show();
    },
    setEndTimeCallback(dateTimeString, timestamp) {
      this.endTimeString = dateTimeString;
      this.endTime = timestamp;
      if (this.endTime < Date.now()) {
        this.message = this.endTimeAlert1Msg;
        this.$refs.alert.showPopup();
      }
      if (this.endTime > this.chatEndTime) {
        var date = new Date(parseInt(this.chatEndTime));
        var y = date.getFullYear();
        var M = date.getMonth() + 1;
        var d = date.getDate();
        var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        var dateString = `${y}/${M}/${d} ${h}:${m}`;
        this.endTimeAlert2Msg.subtitle = ['結束時間超過限時聊天室時間', `此聊天室將於 ${dateString} 結束`];
        this.message = this.endTimeAlert2Msg;
        this.$refs.alert.showPopup();
      }
      this.endTimeError = false;
    },
    isPinnedSwitch(isPinned) {
      this.isPinned = isPinned;
    },
    isMultipleVoteSwitch(isMultipleVote) {
      this.isMultipleVote = isMultipleVote;
    },
    leavePageAlert() {
      this.message = this.leavePageMessage;
      this.$refs.alert.showPopup();
    },
    endTimeAlert1() {
      this.message = this.endTimeAlert1Msg;
      this.$refs.alert.showPopup();
    },
    endTimeAlert2() {
      this.message = this.endTimeAlert2Msg;
      this.$refs.alert.showPopup();
    },
  },
  beforeRouteLeave (to, from, next) {
    // 判斷資料是否填寫完成
    if (this.isAvailable) {
      next();
    }

    // 這邊必須用到 next(false) 的技巧來暫停頁面轉換事件，使用者才能看到 div 自訂訊息
    this.$refs.picker.cancel();
    this.leavePageAlert();

    // 存 Vue 的 Next()
    this.nextEvent = next;

    // 強迫停止 Vue 接下來要做的事情
    next(false);
  },
  mounted() {
    // 同時取得『使用者 AccessToken > OpenId』+『ChatID』
    this.getTokenAndAliasidAndChatid((res) => {
      // 取得subjectId
      this.requestGetNewSubjectId({
        onSuccess: (res) => {
          this.subjectID = this.$store.state.GetNewSubjectId.subjectID;
        },
        onFailed: (err) => {
        },
      });
    });

    // 取得 questionMode
    this.questionMode = parseInt(this.$route.query.questionmode);

    if (this.questionMode == 1) {
      // PK模式-固定兩個選項
      this.addOption();
      this.addOption();
    } else {
      // 一般模式-預設2個選項
      this.addOption();
      this.addOption();
      this.optionCount = 2;
    }
  },
};
</script>

<style lang="sass" scoped>
  .picTextSwitchArea
    margin-top: 24px
    margin-bottom: 12px

  .picBackground
    width: 100%
    height: 100px
    //background-color: rgba(0,0,0,0.28)
    position: relative
    z-index: 10

  .picBackgroundWithImage
    height: 187.5px

  .bannerMask
    position: absolute
    width: 100%
    height: 187.5px
    background-color: rgba(0,0,0,0.28)

  .cameraIcon
    //top: 20px
    position: absolute
    top: 20px
    left: calc(50% - 10px)

  .pageContainer
    top: 50px
    z-index: 20
    width: 100%
    position: absolute
    font-family: '微軟正黑體'

  .pageContainerWithImage
    transition: transform 0.3s linear
    transform: translateY(93.5px)

  .errMsg
    line-height: 14px
    font-size: 12px
    letter-spacing: 0.4px
    text-align: center
    color: rgba(231,91,76,1)

  .btnStart
    margin-top: 10px
</style>

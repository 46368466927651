<template lang="html">
  <div>
    <!-- #region 10.1.1 投票列表空白頁 start -->
    <div v-if="!isLoading && subjectListCount <= 0" class="blank">
      <div class="flex justify_content_center align-self-center mt_116">
        <svg class="center_container" xmlns="http://www.w3.org/2000/svg" width="160" height="160" fill="none" viewBox="0 0 160 160">
          <path fill="#94E4B3" d="M66.545 83.137c-3.184 0-4.36 3.868-4.526 5.91-.184 2.111.241 3.971 1.133 4.984.55.631 1.3.943 2.226.943 3.584 0 4.192-5.019 4.276-6.031.183-2.146-.225-3.998-1.075-4.958-.509-.562-1.176-.848-2.034-.848z"/>
          <path fill="#94E4B3" d="M122.667 34.855c-11.736-5.814-19.93-2.016-27.148.675v-.008s-2.076.813-4.618 1.168c0 0-4.826 1.081-11.47-2.397-.483-.294-1.458-.658-1.95-.926-.008-.009-2.276-1.09-2.276-1.09C24.226 8.88 20.258 58.425 20.258 58.425s-6.21 47.097 42.244 56.753v.009c1.876.476 7.444 2.751 7.66 8.609.317 8.116 1.176 19.339 13.462-2.527 1.009-1.791 4.335-4.594 10.361-5.113l-.042-.009c42.761-5.667 45.853-45.02 45.853-45.02.009-.13 3.326-26.13-17.129-36.272zm-46.662 54.2c-.591 6.966-4.167 11.119-9.577 11.119-1.358 0-3.676-.303-5.36-2.12l-.141 1.653h-5.985l2.576-30.483h6.135l-.926 10.989c1.4-1.497 3.234-2.259 5.485-2.259 2.426 0 4.343.779 5.701 2.31 1.692 1.921 2.418 4.959 2.093 8.792zM67.063 49.47c-.784-.692-1.284-1.592-1.509-2.562-.342-1.444.017-3.132.984-4.265 1.308-1.532 4.025-2.388 6.56-.545 12.57 9.11 25.93 2.206 26.073 2.128 2.258-1.272 5.092-.407 6.318 1.938 1.233 2.345.392 5.287-1.859 6.568-.792.441-19.388 11.897-36.567-3.262zm20.271 33.91l-1.375 16.327h-6.143l1.375-16.328h-3.734l.417-4.958h3.734l.192-2.31c.383-4.542 3.184-7.363 7.301-7.363 1.442 0 2.901.233 3.785.45l.508.12-1.025 5.677-.667-.433c-.467-.302-.958-.623-1.834-.623-1.433 0-1.85 1.402-1.95 2.579l-.159 1.895h4.41l-.409 4.958h-4.426v.008zm15.629 13.956l-.3 1.116c-.508 1.869-2.167 3.176-4.043 3.176-.383 0-.766-.052-1.141-.164l-2.493-.736c-2.225-.658-3.526-3.072-2.9-5.382l.308-1.116c.508-1.87 2.167-3.176 4.043-3.176.383 0 .766.052 1.15.165l2.475.735c2.226.65 3.526 3.063 2.901 5.382zm-.667-7.683l-5.751-1.497 2.117-15.653h9.269l-5.635 17.15z"/>
        </svg>
      </div>
      <div class="text_center">
        <p>現在沒有其他投票活動喔</p>
      </div>
    </div>
    <!-- #endregion 10.1.1 投票列表空白頁 end -->

    <!-- #region 10.1 投票列表頁 start -->
    <div v-else-if="!isLoading" class="tableContainer">
      <!-- #region StatusTab-->
      <div class="direc_row">
        <div @click.prevent="tabClick(0)" @touchstart.prevent="tabTouchstart" @touchend.prevent="tabTouchend(0)" class="statusTab">
          <div class="tabTitle" v-bind:class="{titleActive: tabIndex == 0, titleTouch: tabIndex == 0 && isTouching}">全部</div>
          <div class="titleActiveLine" v-if="tabIndex == 0"></div>
        </div>
        <div @click.prevent="tabClick(1)" @touchstart.prevent="tabTouchstart" @touchend.prevent="tabTouchend(1)" class="statusTab">
          <div class="tabTitle" v-bind:class="{titleActive: tabIndex == 1, titleTouch: tabIndex == 1 && isTouching}">進行中</div>
          <div class="titleActiveLine" v-if="tabIndex == 1"></div>
        </div>
        <div @click.prevent="tabClick(2)" @touchstart.prevent="tabTouchstart" @touchend.prevent="tabTouchend(2)" class="statusTab">
          <div class="tabTitle" v-bind:class="{titleActive: tabIndex == 2, titleTouch: tabIndex == 2 && isTouching}">已結束</div>
          <div class="titleActiveLine" v-if="tabIndex == 2"></div>
        </div>
      </div>
      <!-- #endregion StatusTab-->

      <VoteListTableViewCell v-bind:subject="subject" v-for="subject in subjectList" :key="subject.SubjectId" @goVote="goVote" @goResult="goResult"/>
    </div>
    <!-- #endregion 10.1 投票列表頁 end -->
  </div>
</template>

<script>
import { passEvent } from '@/utils/trackingHelper';
import { loading, auth, news } from '@/mixins';
import PageContainer from '../../components/PageContainer.vue';
// 10.1 投票列表頁的『一列』
import VoteListTableViewCell from '../components/VoteListTableViewCell.vue';

// Loading
var loader;

export default {
  name: 'VoteList',
  mixins: [loading, auth, news],
  components: {
    PageContainer,
    VoteListTableViewCell,
  },
  data() {
    return {
      isLoading: true,
      tabIndex: 0,
      preTabString: '',
      isTouching: false,
      newsMainBannerId: 0,
    }
  },
  computed: {
    subjectList() {
      if (this.tabIndex == 0) {
        // deep copy『$store.state』物件，才能複製物件並修改排序
        const originalList = this.$store.state.News.subjectList;
        const list = JSON.parse(JSON.stringify(originalList));

        // 尚未投票>已投票>已結束，再依結束時間由近到舊，上到下排序
        let listForNotVotedAndNotEnded
            = list.filter(subject => !subject.isVoted && !subject.isEnded)
                  .sort((a, b) => (b.endTime - a.endTime));
        let listForVotedAndNotEnded
            = list.filter(subject => subject.isVoted && !subject.isEnded)
                  .sort((a, b) => (b.endTime - a.endTime));
        let listForEnded
            = list.filter(subject => subject.isEnded)
                  .sort((a, b) => (b.endTime - a.endTime));
        return listForNotVotedAndNotEnded.concat(listForVotedAndNotEnded, listForEnded);
      } else if (this.tabIndex == 1) {
        // deep copy『$store.state』物件，才能複製物件並修改排序
        const originalList = this.$store.state.News.subjectList
                             .filter(subject => !subject.isEnded);
        const list = JSON.parse(JSON.stringify(originalList));

        // 依結束時間由近到舊，上到下排序
        return list.sort((a, b) => (b.endTime - a.endTime));
      } else if (this.tabIndex == 2) {
        // deep copy『$store.state』物件，才能複製物件並修改排序
        const originalList = this.$store.state.News.subjectList
                             .filter(subject => subject.isEnded);
        const list = JSON.parse(JSON.stringify(originalList));

        // 依結束時間由近到舊，上到下排序
        return list.sort((a, b) => (b.endTime - a.endTime));
      }
    },
    subjectListCount() {
      if (this.$store.state.News
       && this.$store.state.News.subjectList) {
        return this.$store.state.News.subjectList.length;
      } else {
        return 0;
      }
    },
    tabString() {
      let tab = '';
      switch (this.tabIndex) {
        case 0:
          tab = 'all';
          break;
        case 1:
          tab = 'activity_ongoing';
          break;
        case 2:
          tab = 'activity_end';
          break;
      }
      return tab;
    },
    cat0() {
      let value = '';
      switch (parseInt(this.newsMainBannerId, 10)) {
        case 1:
          value = '電玩星';
          break;
        case 2:
          value = '放鬆星';
          break;
        case 3:
          value = '時事星';
          break;
      }
      return value;
    },
  },
  methods: {
    // 取得投票列表
    getVoteListByMainBannerId() {
      this.requestGetVoteListByMainBannerId({
        newsMainBannerId: this.newsMainBannerId,
        aliasID: this.aliasID,
        onSuccess: (res) => {
          this.hideLoading(loader);
          this.isLoading = false;
        },
        onFailed: (err) => {
          this.hideLoading(loader);
          this.isLoading = false;
        },
      });
    },
    tabClick(index) {
      this.preTabString = this.tabString;
      this.tabIndex = index;
      this.passEventForTabClick();
    },
    tabTouchstart() {
      this.isTouching = true;
    },
    tabTouchend(index) {
      this.isTouching = false;
      this.tabClick(index);
    },
    passEventForTabClick() {
      const eventItems = [
        {
          type: 'clickEvent',
          eventId: '1047',
          event: 'poll_activites_item_click',
          pageInfo: {
            page: 'poll_activities',
            tab: this.preTabString,
          },
          clickInfo: {
            tab: this.tabString,
          },
        },
      ];
      passEvent(eventItems);
    },
    passEventForRowClick(subject) {
      let status = '';
      if (subject.isEnded) {
        status = 'activity_end';
      } else {
        if (subject.isVoted) {
          status = 'polled';
        } else {
          status = 'open';
        }
      }

      const eventItems = [
        {
          type: 'clickEvent',
          eventId: '1048',
          event: 'poll_activites_content_click',
          pageInfo: {
            page: 'poll_activities',
            tab: this.tabString,
            source: 'news',
            cat0: [this.cat0],
            cat1: [],
          },
          clickInfo: {
            uuid: subject.subjectID,
            name: subject.subjectTitle,
            amount: subject.votePeopleCount,
            status: status,
          },
        },
      ];
      passEvent(eventItems);
    },
    goVote(subject) {
      this.passEventForRowClick(subject);
      setTimeout(() => {
        const link = `${location.origin}/news/vote?subjectid=${subject.subjectID}`
                   + `&newsMainBannerId=${this.newsMainBannerId}`;
        BGO.open_full_h5_webview(link, '投票', process.env.VUE_APP_OFFICAIL_ACCOUNT_ID);
      }, 500);
    },
    goResult(subject) {
      this.passEventForRowClick(subject);
      setTimeout(() => {
        const link = `${location.origin}/news/voteresult?subjectid=${subject.subjectID}`
                   + `&newsMainBannerId=${this.newsMainBannerId}`;
        BGO.open_full_h5_webview(link, '投票', process.env.VUE_APP_OFFICAIL_ACCOUNT_ID);
      }, 500);
    },
  },
  mounted() {
    // 顯示 Loading
    loader = this.showLoading();

    // 取得 newsMainBannerId
    this.newsMainBannerId = this.$route.query.newsMainBannerId;

    // 取得『使用者 Access Token』+『使用者 Open Id』+『使用者 Alias Id』
    this.getTokenAndOpenidAndAliasid((res) => {
      // 取得投票列表
      this.getVoteListByMainBannerId();

      const eventItems = [
        {
          type: 'pageViewEvent',
          eventId: '1045',
          event: 'poll_page_view',
          pageInfo: {
            page: 'poll_activities',
          },
          isAdditiveView: false,
        },
      ];
      passEvent(eventItems);
    });
  },
  beforeDestroy() {
    this.hideLoading(loader);
    this.isLoading = false;
  },
};
</script>

<style lang="sass" scoped>
.VoteNowButton
  width: 125px
  height: 40px
  border-radius: 16px
  background: #26d07c
  color: #ffffff
  line-height: 40px
  text-align: center
  margin: 32px auto 0px
  
.tableContainer 
  padding: 0px 20px 34px 20px
  width: calc(100%-40px)
  height: auto
  margin-top: 8px

.addBtn
  position: fixed
  right: 24px
  bottom: 58px

.statusTab
  width: 33%
  height: 50px
  display: inline-flex
  justify-content: center
  align-items: center
  flex-direction: column
  transition: 0.3s

.tabTitle
  font-size: 15px
  line-height: 20px
  letter-spacing: 0.1px
  margin-bottom: 6px
  color: rgb(153,153,153)

.titleActive
  color: rgb(38,208,124)
  
.titleActiveLine
  height: 0px
  width: 47px
  border-top: solid 2px rgb(38,208,124)

.titleTouch
  transform: scale(1.1)
  font-weight: bold
  + .titleActiveLine
    width: 50px
</style>
